import * as React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

export default function BusLiveList({ busLiveList, updateBusStatusPage }) {

  const openBusStatus = (busLive) => {
    updateBusStatusPage(true, busLive.busId, `${busLive.route.name}`)
  }
  return (
    <Stack spacing={1} alignItems="center">
      { busLiveList.map((busLive, index) => {
        return (
          <Stack direction="row" spacing={1} key={index} >
            <Chip label={`${busLive.route.name} (${busLive.numberOfUsersLive} live)`} color="secondary" variant="outlined" 
                clickable={true} onClick={() => openBusStatus(busLive)}/>
          </Stack>
        )
      })}
    </Stack>
  );
}
