import { useEffect,useState } from "react";
import React from "react";
import { OsTypes,osName,isAndroid,isIOS} from "react-device-detect";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';



export default function DetectPlatform(props){
    const [isandroid , setIsandroid] = useState(false);
    const [isios , setIsios] = useState(false);
    
    useEffect(()=>{
        console.log('hello')
        if(isAndroid){
        setIsandroid(true)
        }else if(isIOS){
            setIsios(true)
        }

    },[])
    // const backtoHomepage = () => {
    //     // setIsPAD(false)
    //     props.updateBottomNavigationTab(BOTTOM_NAVIGATION.HOME)
    //   }
    
    return (
        <>
        <div className="is_platform_bg">
         {/* <div onClick={()=>backtoHomepage()} style={{cursor:"pointer"}}> <ArrowBackOutlinedIcon/> </div> */}
        {
            isandroid &&
            (<div className="isandroidbg">
            <div style={{fontFamily:"cursive",color:"black",paddingBottom:"10px"}}>1. Search in Google :</div>    
            <img src="searchgoogle.jpg" className="is_android_images"></img>
            <div className="is_android_divs">2. Click on 3 dots in top right:</div>    
            <img src="clickon3buttnEDIT.jpg" className="is_android_images"></img>
            <div className="is_android_divs">3. Click on add to Home Screen or install app:</div>  
            <img src="installappandroid1.jpg" className="is_android_images"></img>
            <div className="is_android_divs"></div>  
            </div>) 
        }
        {
            isios &&
            (<div className="isandroidbg">
            <div style={{fontFamily:"cursive",color:"black",paddingBottom:"10px"}}>1. Search in safari :</div>    
            <img src="search ios.jpg" className="is_ios_images"></img>
            <div className="is_ios_divs">2. Click on share icon in bottom screen:</div>    
            <img src="clickonshareiosarrow.jpg" className="is_ios_images"></img>
            <div className="is_ios_divs">3. Click on add to Home Screen :</div>  
            <img src="add to homescreeniosarrow.jpg" className="is_ios_images"></img>
            <div className="is_ios_divs">4. Now add to Home Screen :</div>  
            <img src="now addiosarrow.jpg" className="is_ios_images"></img>
            </div>)
        }
        </div>
        </>
    )

}



