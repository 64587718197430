import axios from 'axios'
import Cookies from 'universal-cookie';
import { logout, refreshToken } from '../account';

axios.interceptors.request.use(
    request => { 
        const cookie = new Cookies();
        request.headers['authorization'] = cookie.get('auth_access_token')
        return request
    },
    error => {
        return Promise.reject(error)
    }
);

axios.interceptors.response.use(
    response => response, 
    error => {
    const originalConfig = error.config;
    const status = error.response ? error.response.status : null
    if (status === 401) {
        if(!originalConfig._retry) {
            originalConfig._retry = true;
            return refreshToken().then(_ => {
                const cookie = new Cookies();
                error.config.headers['authorization'] = cookie.get('auth_access_token')
                return axios.request(error.config);
            });
        } else {
            logout()
        }
    }

    return Promise.reject(error);
});
