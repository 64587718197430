import React, { useEffect } from "react";
import ScratchCard from "react-scratchcard-v3";
import { Dialog, DialogContent } from "@mui/material";
import { useReward } from "react-rewards";
import { redeemReward } from "../../api/user";
import { PlayCircleFilledWhite } from "@mui/icons-material";

const ScratchProfile = (props) => {
  const [open, setOpen] = React.useState(false);
  const [scratchedCard, setScratchedCard] = React.useState(props.scratched);

  useEffect(() => {
    const invokeRedeemReward = async () => {
      console.log("redeemed reward - called ")
      await redeemReward(props.id);
      props.reRender(!props.render);
    }

    if (scratchedCard && !props.scratched) {
      invokeRedeemReward();
    }
  }, [scratchedCard]);

  const { reward, isAnimating } = useReward("rewardId", "confetti", {
    zIndex: 2000,
  });

  const handleClickOpen = () => {
    reward();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      {props.scratched ? (
        <div
          style={{
            width: "160px",
            height: "160px",
            boxShadow: "2px 2px 7px #00000054",
            borderRadius: "8px",
            cursor: "pointer",
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "8px",
            }}
          >

          <img
            style={{
              width: "160px",
              height: "160px",
              borderRadius: "8px",
              cursor: "pointer",
              position: "absolute",
            }}
            alt=""
            src="scratch_card_open.jpg"
          />
            <h6 style={{position: "relative"}}>{props.text}</h6>
          </div>
        </div>
      ) : (
        <>
          <img
            style={{
              width: "160px",
              height: "160px",
              boxShadow: "2px 2px 7px #00000054",
              borderRadius: "8px",
              cursor: "pointer",
            }}
            alt=""
            src={props.img}
            onClick={handleClickOpen}
          />
          <span id="rewardId" />
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
              "& .MuiPaper-root": {
                minWidth: "250px",
                borderRadius: "1rem",
                background: "transparent"
              },
            }}
          >
            <DialogContent
              sx={{
                padding: "0px",
                touchAction: "none",
                borderRadius: "1rem"
              }}
            >
              <ScratchCard
                width={250}
                height={250}
                image={props.img}
                finishPercent={70}
                brushSize={26}
                onComplete={() => setScratchedCard(true)}
              >
                <div
                  style={{
                    display: "flex",
                    width: "98%",
                    height: "98%",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "white",
                  }}
                >
                  <h3>{props.text}</h3>
                </div>
              </ScratchCard>
            </DialogContent>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default ScratchProfile;
