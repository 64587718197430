
import axios from 'axios'
import { place, SERVICE_API_URL } from '../config/constant'

export const addContent = async (params) => {
    const response = await axios.post(SERVICE_API_URL+'/content', { 
        ...params,
        place
    });
    return response.data
}

export const getStores = async () => {
    const response = await axios.get(SERVICE_API_URL+'/stores', { params: {
        place
    }});
    return response.data?.stores
}

export const addStore = async (params) => {
    const response = await axios.post(SERVICE_API_URL+'/stores/store', { 
        store: params,
        place
    });
    return response.data
}

export const getSocialContents = async () => {
    const response = await axios.get(SERVICE_API_URL+'/socialContents', { params: {
        place
    }});
    return response.data?.contents
}

export const addSocialContent = async (params) => {
    const response = await axios.post(SERVICE_API_URL+'/socialContents/socialContent', { 
        store: params,
        place
    });
    return response.data
}