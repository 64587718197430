
import React from 'react';
import { BOTTOM_NAVIGATION } from '../config/constant';

function CardShowBus (props) {
    const displayBusContent = () => {
      props.updateBottomNavigationTab(BOTTOM_NAVIGATION.BUS)
    }

    return (
      <div className='card' onClick={displayBusContent} >
      <div className='card__body'>
        <img className='card__image' alt='' src={props.img} />
        <div className='card__title'>{props.title}</div>
        <div className='card__description'>{props.description}</div>
      </div>
      <button className='card__btn'>View Bus Time</button>
      </div>
    )
}

export default CardShowBus;
