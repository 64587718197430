import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom'
import Card from '../components/card';
import PageFamily from './PageFamily';
import PageSocial from './PageSocial';
import PageDelivery from '../pages/PageDelivery';
import PageAddStore from './PageAddStore';
import PageAddSocialContent from './PageAddSocialContent';
import PageTC from './PageTC';
import PageProfile from './PageProfile';
import { login } from '../api/account'
import { Alert, Box, LinearProgress } from '@mui/material';
import { BOTTOM_NAVIGATION } from '../config/constant';
import PageAdminUser from './PageAdminUser';
import PageWizard from './PageWizard'
import PageBlog from './PageBlog';
import LoginHome from '../pages/LoginHome';
import WhatsappLoginPage from '../components/homepageuicomponents/whatsappLoginPage';
import { isLoggedIn } from '../api/account';
import WizardAdmin from '../components/wizard/WizardAdmin';
import PageBoat from './PageBoat';
import PageOfferZone from './PageOfferZone';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DetectPlatform from '../components/detectDevice/DetectPlatform'
import DeliveryInfoCard from '../components/card/DeliveryInfoCard';
import DeliveryWizardJobCard from '../components/card/DeliveryWizardJobCard';

const Home = ({ setRefresh, bottomNavigationTab, updateBottomNavigationTab, displayFullScreen, isFullScreen, refreshPage  }) => {

    const query = new URLSearchParams(useLocation().search)
    const [searchParams, setSearchParams] = useSearchParams();

    const page = query.get('page')
    const content = query.get('content')
    const code = query.get('code')
    const activity = query.get('activity')

    const [loginFlow, setLoginFlow] = useState(!!code)

    // const hash = new URLSearchParams(useLocation().hash)
    // const loginFlow = hash.get('token_type')

    useEffect(() => {
        async function loginUser() {
            await login(code, page)
            setLoginFlow(false)
            if (searchParams.has('code')) {
                searchParams.delete('code')
                setSearchParams(searchParams);
            }
            refreshPage()
        }
        if(loginFlow) {
            loginUser()
        }
    }, [loginFlow, page, code, refreshPage])

    if(loginFlow) {
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
          );
    }

    const user = isLoggedIn()
    if(!(['blog', 'tc'].includes(page) && !bottomNavigationTab)) {
        if(!user) {
            displayFullScreen(true)
            return (<LoginHome /> )
        } else if ( user['custom:isMobileVerified'] !== '1') {
            displayFullScreen(true)
            return ( <WhatsappLoginPage  refreshPage={refreshPage} />)
        }
    }

    if(activity?.startsWith("wizardOrder") && !bottomNavigationTab) {
        if (searchParams.has('activity')) {
            searchParams.delete('activity')
            setSearchParams(searchParams);
        }
        const userOrderId = activity.split("__")[1]
        return (<WizardAdmin userOrderId={userOrderId} oneTimeOrder={true} />)
    }

    switch(bottomNavigationTab) {
        case BOTTOM_NAVIGATION.BUS:
            return (
                <div className={!isFullScreen ? "wrapper" : ''}>
                <Card 
                    img="bus_small.jpg"
                    title="Bus Time Table"
                    description="Find Bus timings for each bus stops in Mattool."
                    type="bus"
                    displayBus={true}
                    displayFullScreen={displayFullScreen}
                        />
                </div>)
        case BOTTOM_NAVIGATION.BOAT:
            return (<PageBoat updateBottomNavigationTab={updateBottomNavigationTab} />)
        case BOTTOM_NAVIGATION.OFFER_ZONE:
            return (<PageOfferZone updateBottomNavigationTab={updateBottomNavigationTab} />)
        case BOTTOM_NAVIGATION.DELIVERY:
            return (<PageDelivery updateBottomNavigationTab={updateBottomNavigationTab}/>)
        case BOTTOM_NAVIGATION.USER_PROFILE:
            return (<PageProfile />)
        case BOTTOM_NAVIGATION.WIZARD:
            return (<PageWizard />)
        case BOTTOM_NAVIGATION.ADMIN:
            return (<PageAdminUser />)
        default:
            console.log('no bottom navigation selected')
    }

    const showBoatTimingsPage = () => {
        updateBottomNavigationTab(BOTTOM_NAVIGATION.BOAT)
    }

    const showOfferZonePage = () => {
        updateBottomNavigationTab(BOTTOM_NAVIGATION.OFFER_ZONE)
    }

    switch(page) {
        // case 'profile': 
        // return (<PageProfile setRefresh={setRefresh}/>)
        // case 'family': 
        // return (<PageFamily />)
        // case 'social': 
        // return (<PageSocial setRefresh={setRefresh} doAppBarRefresh={loginFlow}/>)
        // case 'delivery': 
        // return (<listOfStores setRefresh={setRefresh} doAppBarRefresh={loginFlow}/>)
        // case 'addStore': 
        // return (<PageAddStore setRefresh={setRefresh} doAppBarRefresh={loginFlow} />)
        // case 'addSocialContent': 
        // return (<PageAddSocialContent setRefresh={setRefresh} doAppBarRefresh={loginFlow} />)
        case 'blog': 
        return (<PageBlog content={content} />)
        case 'tc': 
        return (<PageTC />)
        default: {
        return (
            <div className={!isFullScreen ? "wrapper" : ''}>
                {/* <Alert severity="info">You are now using a Beta version of the Mattool App. The official launch will be announced soon. Please report any issues in the "Ask Mattool" section down below.</Alert> */}
                    {/* <Card 
                img="groceries_small.jpg"
                title="Organic Groceries"
                description="Get home grown groceries."
                type="grocery"
                    /> */}
                {/* <Card 
                title="മാട്ടൂൽ നിവാസികൾക് സ്വാഗതം."
                description="നമ്മുടെ സ്വന്തം മാട്ടൂൽ ആപ്പ് 😊. ബസ് സമയം, ഡെലിവറി എന്നിവ മാട്ടൂൽ ആപ്പിൽ ലഭ്യമാണ്."
                type="wallPost"
                    /> */}
                {/* <div className='card' >
                    <DeliveryInfoCard />
                </div> */}
                <Card 
                img="bus_small.jpg"
                title="Bus Time Table"
                description="Find Bus timings for each bus stops in Mattool."
                type="showBus"
                updateBottomNavigationTab={updateBottomNavigationTab}
                displayFullScreen={displayFullScreen}
                    />
                <div className='card' onClick={showBoatTimingsPage}>
                     <div style={{backgroundColor:"white", display:"flex", margin: "0.5rem", borderRadius: "0.5rem", columnGap: "1rem"}}> 
                        <div style={{ backgroundColor:"white", display:"flex", justifyContent:"center", alignItems:"center", marginLeft: "1rem"}}> 
                         <img src="./ship.png" style={{width:"6rem", height:"6rem"}} alt="" />
                        </div>   
                        <div style={{ backgroundColor:"white", display:"flex", justifyContent:"center", alignItems:"center", margin: "0.5rem", borderRadius: "0.5rem", 
                            fontSize: "large", fontWeight: "bold"}} >
                         Boat Timings
                        </div> 
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <ArrowForwardIosIcon />
                        </div>
                    </div> 
                </div>
                {/* <div className='card' onClick={showOfferZonePage}>
                     <div style={{backgroundColor:"white", display:"flex", margin: "0.5rem", borderRadius: "0.5rem", columnGap: "1rem"}}> 
                        <div style={{ backgroundColor:"white", display:"flex", justifyContent:"center", alignItems:"center", marginLeft: "1rem"}}> 
                         <img src="./discount.png" style={{width:"4rem", height:"4rem", margin:"1rem"}} alt="" />
                        </div>   
                        <div style={{ backgroundColor:"white", display:"flex", justifyContent:"center", alignItems:"center", margin: "0.5rem", borderRadius: "0.5rem", 
                            fontSize: "large", fontWeight: "bold", marginRight: "2rem"}} >
                         Offer Zone
                        </div> 
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <ArrowForwardIosIcon />
                        </div>
                    </div> 
                </div> */}
                <Card 
                img="food_delivery_small.jpg"
                title="Pickup & Delivery "
                description="On demand pickup and delivery by Mattool Wizards"
                type="delivery"
                updateBottomNavigationTab={updateBottomNavigationTab}
                    />
                {/* <div className='card' >
                    <DeliveryWizardJobCard />
                </div> */}
                <Card 
                title="ADD TO HOMESCREEN"
                type="wallPost"
                    />    
                {/* <Card 
                img="loudspeaker.jpg"
                title="Mattool Loud Speaker"
                description="Public Announcements!"
                updateBottomNavigationTab={updateBottomNavigationTab}
                    /> */}
                <Card 
                img="feedback.jpg"
                title="Ask mattool.in"
                type="post"
                    />
                { 
                // TODO : Electricians / plumbers / car rent 
                } 
            </div>
            )
        }
    }
}

export default Home
