
import React from 'react';
import { BOTTOM_NAVIGATION } from '../config/constant';

function CardSocial (props) {
    const displaySocialContent = () => {
      props.updateBottomNavigationTab(BOTTOM_NAVIGATION.MEDIA)
    }

    return (
      <div className='card' onClick={displaySocialContent} >
        <div className='card__body'>
          <img className='card__image' alt='' src={props.img} />
          <h2 className='card__title'>{props.title}</h2>
          <p className='card__description'>{props.description}</p>
        </div>
        <button className='card__btn' >View</button>
      </div>
    )
}

export default CardSocial;
