import React from 'react';
import ReactDOM from 'react-dom';



export function pushGA4CustomEvent(pageTitle,pageUrl,eventDetails) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: "virtualPageview",
        pageUrl: `https://www.mattool.in/${pageUrl}`,
        pageTitle:  pageTitle,
        eventDetails: eventDetails
    })
}





