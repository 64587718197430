
import axios from 'axios'
import { place, SERVICE_API_URL } from '../config/constant'

export const getPlaces = async () => {
    const response = await axios.get(SERVICE_API_URL+'/delivery/places', { params: {
        place
    }});
    return response.data
}

export const createOrder = async (data) => {
    try {
        const response = await axios.post(SERVICE_API_URL+'/delivery/order', data)
        return response.data
    } catch (e) {
        console.error(e)
        return null
    }
}

export const getOrderStatus = async (orderId) => {
    const response = await axios.get(SERVICE_API_URL+`/delivery/order/${orderId}`, { params: {
        place
    }});
    return response.data
}

export const getLatestOrder = async (orderId) => {
    const response = await axios.get(SERVICE_API_URL+`/delivery/latestOrder`, { params: {
        place
    }});
    return response.data
}


export const getPrice = async (pickUpAreaId , dropAreaId) => {
    const response = await axios.get(SERVICE_API_URL+'/delivery/orderPrice' ,{ params: {
        pickUpAreaId , dropAreaId
    }});
    return response.data
}

export const getOrderVehicleAvailability = async () => {
    const response = await axios.get(SERVICE_API_URL+'/delivery/orderVehicleAvailability' ,{ params: {}});
    return response.data
}

export const updateOrderVehicleAvailability = async (operations) => {
    const response = await axios.post(SERVICE_API_URL+'/adm/orderVehicleAvailability' , { operations });
    return response.data
}

export const createRazorPayOrder = async (data) => {
    try {
        const response = await axios.post(SERVICE_API_URL+'/delivery/payment', data)
        return response.data
    } catch (e) {
        console.error(e)
        return null
    }
}

export const cancelOrder = async (orderId) => {
    try {
        const response = await axios.post(SERVICE_API_URL+'/delivery/order/cancel', {
            orderId
        })
        return response.data
    } catch (e) {
        console.error(e)
        return null
    }
}

export const convertToCOD = async (orderId) => {
    try {
        const response = await axios.post(SERVICE_API_URL+'/delivery/order/convertToCod', {
            orderId
        })
        return response.data
    } catch (e) {
        console.error(e)
        return null
    }
}
