
import React, { useState } from 'react';
import Select from 'react-select';
import { useForm, Controller } from "react-hook-form";
import TextField from '@mui/material/TextField';
import EmailIcon from '@mui/icons-material/Email';
import { addContent } from '../api/content'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { isLoggedIn } from '../api/account'

function CardPost (props) {
  const user = isLoggedIn();
  const { control, handleSubmit } = useForm();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  
  const onSubmit = data => {
    addContent({...data, type: data.type.value})
    setIsFormSubmitted(true)
  }

  const goBack = () => {
    setIsFormSubmitted(false)
  }

  if(isFormSubmitted) {
      return (
        <div className='card'>
        <div className='card__body'>
          <img className='card__image' alt='' src={props.img} />
          <div className='card__title'> </div>
          <div className='card__description'>Thank you! We have received your request. We will get back to you soon.</div>
        </div>
        <button className='card__btn' onClick={goBack} >Go back</button>
        </div>
      )
  } else {
      return (
        <div className='card'>
        <div className='card__body'>
          <div className='card__title'>{props.title}</div>
          {/* <form onSubmit={handleSubmit(onSubmit)}>
          <div className='card__post_content' >
            <div className='card__post_content__input'>
              <Controller
                name="request"
                control={control}
                defaultValue=""
                rules={{
                  required: true
                }}
                render={({ field }) => <TextField 
                  required
                  fullWidth size="medium"
                  label="Write your request here..." {...field} 
                />
                }
              />
            </div>
            <div className='card__post_content__input'>
            <Controller className="card__post_content__input_text"
                name="phoneNumber"
                control={control}
                defaultValue=""
                rules={{
                  required: true
                }}
                render={({ field }) => <TextField 
                  required
                  fullWidth size="small"
                  label="Phone Number" {...field} 
                  />
                }
              />
            </div>
            <div className='card__post_content__input'>
              <Controller
                name="type"
                control={control}
                rules={{
                  required: 'select an option'
                }}
                render={({ field }) => <Select required maxMenuHeight={140} placeholder="What is it about ?"
                  {...field} 
                  options={[
                    { value: "bus", label: "Changes in Bus Time" },
                    { value: "event", label: "Upcoming event" },
                    { value: "marketplace", label: "Buy or Sell" },
                    { value: "grocery", label: "Groceries" },
                    { value: "househelper", label: "House Helper" },
                    { value: "other", label: "Anything else" }
                  ]} 
                />}
              />
            </div>
            <input className='card__post_content__btn' type="submit" /> */}
            <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
             <a  style={{ display: "flex", textAlign: "center", justifyContent: "center", textDecoration: "none",
                color: "green",  borderStyle: "double", padding: "0.5rem", borderRadius: "0.5rem", marginBottom: "0.5rem" }} 
              href= {"https://wa.me/918089783756?text=" + " SEND MESSAGE TO START CONVERSATION "+" "+"[ " + user.email + " ]" }> <WhatsAppIcon style={{ marginRight:"0.5rem" }}/>Click to chat with us </a>
            </div>
            {/* <div style={{    display: "flex", textAlign: "center", justifyContent: "left", paddingTop:"1rem" , marginTop:'1rem', background: 'lightsteelblue' , paddingLeft: '1rem'}}>
              <EmailIcon style={{ marginLeft: '-0.2rem', height: '1rem' }}/> contact.mattool.live@gmail.com
            </div> */}
             
              <div className='card__post_content__description' style={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr 1fr",
                              flexDirection: "row",
                              columnGap: "0.5px",
                              rowGap:"0rem",
                              justifyContent: "center",
                              paddingTop: "1rem",
                              paddingLeft:"2rem",
                              paddingRight:"2rem",
                              marginTop:"1.5rem",
                 background: 'lightsteelblue', fontSize: '0.8rem', textDecoration: "none"}}>
                <div style={{}}>
                  <a href="mailto:contact.mattool.live@gmail.com" target="_blank" rel="noreferrer" ><img src="gmail-new.png" style={{ width:"25px", height:"25px"}}/></a>
                </div>
                <div style={{}}>
                <a  href="https://www.youtube.com/channel/UCP7kTMHg1CiQY8_w_0B0TNQ" target="_blank" rel="noreferrer"  style={{color:"red",textDecoration: "none", marginBottom: "0.5rem"}}><img src="icons8-youtube-144.png" style={{width:"25px", height:"25px"}} alt=""/></a>
                </div>
                <div style={{}}>
                <a  href="https://www.instagram.com/mattool.in/?hl=en"  target="_blank" rel="noreferrer" style={{textDecoration: "none"}}><img src="icons8-instagram-144.png" style={{width:"25px", height:"25px"}} alt=""/></a>
                </div>
            </div>
            <div className='card__post_content__description' style={{ padding:"1rem" , background: 'lightsteelblue', fontSize: '0.8rem', display: 'flex'}}>
              <a href="?page=tc" target="_blank"  style={{ textDecoration: "none", color: "dimgrey" }} >
              Terms and conditions
              </a>
              <a href="https://www.youtube.com/watch?v=t3v0-aYc0EQ&list=PLtASNhZjSPhDEMvQ1xbpI1ZW30Qzr8zK9" target="_blank" rel="noreferrer"   style={{textDecoration: "none", marginLeft:"1rem", color: "navy"}} >
                Watch Help Videos
              </a>
              <a href="?page=blog" target="_blank" style={{color:'lightsteelblue'}}>
              Blog
              </a>
            </div>
            <div className='card__post_content__description' style={{ padding:"0.5rem 0" , background: 'lightsteelblue', fontSize: '0.8rem', display: 'flex', justifyContent:'left' }}>
              <a href="https://www.youtube.com/shorts/J93MHSRM_kg" target="_blank" rel="noreferrer"   style={{textDecoration: "none", marginLeft:"1rem", color: "navy"}} >
                How to install mattool.in App ?
              </a>
            </div>
          {/* </div>
          </form> */}
        </div>
        </div>
      )
  }
}

export default CardPost;
