import { CircularProgress, Popper ,Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { pushGA4CustomEvent } from '../../Analytics'
import { getVerificationCode, logout ,isLoggedIn, refreshToken} from '../../api/account'
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import SmsOTPPage from './SmsOTPPage';

const WhatsappLoginPage = ({refreshPage}) => {
 
  const [verificationCode, setVerificationCode] = useState(null)
  const [verifyCodeClicked, setVerifyCodeClicked] = useState(null)
  const [smsOTPFlow, setSmsOTPFlow] = useState(false)

  useEffect(() => {   
    let pageTitle = "Opened Whatsaap verify page"
    let pageUrl = "verifywithwhatsaap"
    pushGA4CustomEvent(pageTitle,pageUrl)
    
    const fetchData = async () => {
      let code = await getVerificationCode();
      setVerificationCode(code) 
    }
    fetchData();
  }, []) 

  useEffect(() => {
    const invokeRefreshToken = async () => {
      await refreshToken();
    }
    if(verifyCodeClicked) {
      setLoader(true)
      invokeRefreshToken();
      setTimeout(() => {
        setLoader(false)
        setPopper(true);
      }, 2000);
      setTimeout(() => {
        setPopper(false);
        if(verifyCodeClicked) refreshPage()
        setVerifyCodeClicked(false)
      }, 6000);
    }
  }, [verifyCodeClicked]) 

  const [popper, setPopper] = React.useState(null);
  const [loader, setLoader] = React.useState(null);
  const user = isLoggedIn()
  console.log('user',user.email)

  const logoutAction = () => {
    logout()
    window.open(window.location.origin, '_self')
   }

  const open = Boolean(popper);
  const id = open ? 'simple-popper' : undefined;

  const showSmsOTPFlow = () => { setSmsOTPFlow(true) }
  const hideSmsOTPFlow = () => { 
    setSmsOTPFlow(false) 
    setVerifyCodeClicked(true) 
  }

  if(smsOTPFlow) {
    return <SmsOTPPage hideSmsOTPFlow={hideSmsOTPFlow} refreshPage={refreshPage}/>
  }

  return (
  <>
    <div className="loginPageBody" style={{height:"100vh", margin:"  auto",   overflow:"hidden",backgroundColor:"white", position:"relative" }}>
      <div style={{display:"flex", position:"absolute", top:"1rem",width:"90%", left:"20px", justifyContent:"space-between"}}>
        <div style={{display:"flex", alignContent:"center", alignItems:"center", columnGap:"5px"}}>
          <div style={{height:"6px", width:"6px", backgroundColor:"#e6e6e6",  borderRadius:"100%",  }}></div>
          <div style={{height:"8px", width:"8px", backgroundColor:"#700070",  borderRadius:"100%",  }}></div>
        </div>
        <Button onClick={logoutAction} style={{ width: '1rem'}} ><div style={{ color: '#992aa5', textTransform: "lowercase" }}>logout</div></Button> 
      </div>
      <div style={{height:"60vh", position:"",zIndex:"5", backgroundColor:"#b3b3b3",borderRadius:"100%/25px  25px 25px  25px ", borderTopLeftRadius:"50px", borderTopRightRadius:"50px"}} >
        <div style={{height:"60vh",display:"flex", justifyContent:"center", alignItems:"center", position:"",zIndex:"10", backgroundColor:"blue",borderRadius:"50%/25px  25px 25px  25px ", borderTopLeftRadius:"0px", borderTopRightRadius:"0px",backgroundImage: "linear-gradient( to left top,#b348b3, purple 50%)"}}>
          <div style={{display:"flex", flexDirection:"column", rowGap:"8rem", marginTop:"10vh"}}>
            <img src="./logo_purple_512.jpg" alt="" style={{ width: 50, height: 50 , marginLeft:"45px" ,borderRadius:50 , marginTop:"100px"}}/>
            <div style={{cursor:"pointer",color:"#f0f0f0",fontSize:"20px", fontWeight:"bolder"}}><img src="./logo_text_white_transparent.png" alt="" style={{ width: 130, height: 10 ,marginBottom:"10px"}}/>
            </div>
          </div>
        </div>
      </div>
                  
      <div style={{height:"32vh",flexDirection:"column",   backgroundColor:"white", display:"flex", justifyContent:"center", alignItems:"center"}}> 
        {/* <a href={"https://wa.me/918089783756?text=SEND VERIFICATION CODE: " + verificationCode + " " } style={{ textDecoration: "none" }}>
          <div style={{width:"18rem", height:"40px",cursor:"pointer", display:   "flex",alignItems:"center", fontSize:"15px",padding:"5px 20px", color:"black",fontWeight:"bolder", borderRadius:"50px",textAlign:"center",verticalAlign:"middle",lineHeight:"30px",border:"1px solid black",margin:"1rem"}}>
            <img src="./whatsappIcon.svg" style={{width:"25px", height:"25px"}} alt="" />
            <div style={{marginLeft:"40px", userSelect:"none", color:"#424242"}}>
              {"Verify with Whatsapp"}
            </div>
            <div style={{marginLeft:"2rem", marginTop:"10px"}}>
            <img src="./tickmarkIcon.svg" style={{width:"25px", height:"50px"}} alt="" />
            </div>
          </div>
        </a>
        <div style={{color: "darkslategrey"}}>OR</div> */}
        <div onClick={showSmsOTPFlow} style={{ width:"18rem", height:"40px",cursor:"pointer", display:   "flex",alignItems:"center", fontSize:"15px",padding:"5px 20px", color:"black",fontWeight:"bolder", borderRadius:"50px",textAlign:"center",verticalAlign:"middle",lineHeight:"30px",border:"1px solid black",margin:"1rem"}}>
          <TextsmsOutlinedIcon style={{width:"25px", height:"25px"}} />
          <div style={{marginLeft:"40px", userSelect:"none", color:"#424242"}}>
            {"Verify with mobile OTP"}
          </div>
          <div style={{marginLeft:"2rem", marginTop:"10px"}}>
          <img src="./tickmarkIcon.svg" style={{width:"25px", height:"50px"}} alt="" />
          </div>
        </div>
        {/* <Button variant="text" onClick={() => setVerifyCodeClicked(true)} style={{marginTop:"10px", color:"purple",padding:"5px" }} >Check Verification Status</Button>  */}
        <div style={{marginTop:"10px", fontWeight:"bolder", cursor:"pointer"}}> {loader && <CircularProgress fontSize="15px" />}</div>
         {/* <div style={{paddingLeft:"1rem"}}>
          {popper && 
              <> If you have send verification code in whatsapp, your account will be verified soon.</> }
        </div> */}
      </div> 
      <div style={{height:"8vh",  backgroundColor:"white", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <div style={{cursor:"pointer",height:"40px",fontSize:"0.8rem", width:"90%", color:"grey", textAlign:"right",verticalAlign:"middle",lineHeight:"30px", borderRadius:"50px", paddingTop:'5px'}}>
              <a href="https://www.youtube.com/watch?v=t3v0-aYc0EQ&list=PLtASNhZjSPhDEMvQ1xbpI1ZW30Qzr8zK9" target="_blank" rel="noreferrer" style={{textDecoration: "none", marginLeft:"1rem", color: "dimgrey" }}>{"Videos"}</a>
              <a href="https://merchant.razorpay.com/policy/KU2Gb0PLZvEerc" target="_blank" rel="noreferrer" style={{textDecoration: "none", marginLeft:"1rem", color: "dimgrey" }}>{"Policies"}</a>
              <a href="https://mattool.in?page=tc" target="_blank" rel="noreferrer" style={{textDecoration: "none", marginLeft:"1rem", color: "dimgrey" }} >{"Terms"}</a>
              <a href="https://wa.me/918089783756?text=Hi Mattool" target="_blank" rel="noreferrer" style={{textDecoration: "none", marginLeft:"1rem", color: "dimgrey" }}>{"Help"}</a>
          </div>
      </div>         
    </div>
  </>
  )
}

export default WhatsappLoginPage
