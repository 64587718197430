import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '22rem',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius: '0.5rem',
};

export default function OfferImageModal(props) {

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.closeInfoModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
        <Box sx={style}>
          <div className="busInfoModalClose">
          <IconButton onClick={props.closeInfoModal} >
            <CloseIcon />
          </IconButton>
          </div>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {props.offer.title}
          </Typography>
          <Typography id="modal-modal-description" >
            {props.offer.message}
          </Typography>
          <div className="busInfoModalBusStatus" >
            <img
              src={`${props.offer.img}`}
              srcSet={`${props.offer.img}`}
              alt={props.offer.title}
              loading="lazy" 
              style={{ width: "350px", borderRadius: "0.5rem" }}
            />
          </div>
          <div style={{ marginTop: "0.5rem"}}>
            <a href={`${props.offer.img}`}  target="_blank" rel="noreferrer" style={{
                textDecoration: "none",
                color: "black",
                borderStyle: "solid",
                padding: "0.2rem",
                borderRadius: "0.3rem"
              }} download>
                  Download Image
            </a>
          </div>
        </Box>
        </div>
      </Modal>
    </div>
  );
}
