
import React from 'react';
import { BOTTOM_NAVIGATION } from '../config/constant';

function CardDelivery (props) {
    const displayDeliveryContent = () => {
      props.updateBottomNavigationTab(BOTTOM_NAVIGATION.DELIVERY)
    }

    return (
      <div className='card' onClick={displayDeliveryContent} >
        <div className='card__body'>
          <img className='card__image' alt='' src={props.img} />
          <div className='card__title'>{props.title}</div>
          <div className='card__description'>{props.description}</div>
        </div>
        <button className='card__btn' >Start your order</button>
      </div>
    )
}

export default CardDelivery;
