import React, { useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

import { useTable } from 'react-table'
 
 function BusTimePosts(props) {
   const columns = React.useMemo(
     () => [
      {
        Header: 'Time',
        accessor: 'bus.time',
      },
       {
         Header: 'Bus',
         accessor: 'bus.name',
       },
       {
         Header: '',
         accessor: 'likes',
         Cell: (row) => {
          return (
            <div className="grid-parent" onClick={() => props.likeBusUserPost(row.row.original.id, row.row.original.postIndex)} >
            <div className="bus_post_icon"><ThumbUpIcon fontSize="small" color="primary" style={{ color: "silver" }}/>
            </div>
            <div className="bus_post_icon_number">{row.row.original.likes}</div>
            </div>
          );
          }
        },
        {
          Header: '',
          accessor: 'dislikes',
           Cell: (row) => {
            return (
                <div className="grid-parent" onClick={() => props.disLikeBusUserPost(row.row.original.id, row.row.original.postIndex)} > 
                <div className="bus_post_icon"><ThumbDownIcon fontSize="small" style={{ color: "silver" }}/> 
                </div>
                <div className="bus_post_icon_number">{row.row.original.dislikes}</div>
                </div>
            );
          },
         },
     ],
     []
   )
 
   const {
     getTableBodyProps,
     headerGroups,
     rows,
     prepareRow,
   } = useTable({ columns, data: props.rows })
 
   return (
    <TableContainer style={{ maxHeight: '22rem' }} component={Paper}>
        <Table aria-label="simple table">
       {/* <TableHead >
         {headerGroups.map(headerGroup => (
           <TableRow {...headerGroup.getHeaderGroupProps()}>
             {headerGroup.headers.map(column => (
               <TableCell {...column.getHeaderProps()} variant='head' >
                 {column.render('Header')}
               </TableCell>
             ))}
            <TableCell />
           </TableRow>
         ))}
       </TableHead> */}
       <TableBody {...getTableBodyProps()}>
         { rows?.length ? rows.map((row, i) => {
           prepareRow(row)
           return (
             <TableRow {...row.getRowProps({ style: { background: props.selectedRow === i ? "#aed6fe" : "white" }})}>
               {row.cells.map(cell => {
                 return (
                   <TableCell {...cell.getCellProps({ style: { padding: '0.5rem' }}) } size='small' padding='none' variant='body' >
                     {cell.render('Cell')}
                   </TableCell>
                 )
               })
               }
             </TableRow>
           )
         }) : 
            (
            <TableRow >
              <TableCell  >
              No Posts from user.
              </TableCell>
            </TableRow>
          )
        }
       </TableBody>
    </Table>
    </TableContainer>
   )
 }

 export default BusTimePosts
