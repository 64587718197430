import React from 'react'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import CouponCard from './CouponCard';

const CouponPage = ({ setShowCouponPage, applyCoupon }) => {

  return (
  <>
   <div style={{margin:" 10px auto",width:"350px", backgroundColor:"white", padding:"13px 15px", borderRadius:"10px"}}>
    <div style={{fontSize:"18px", fontWeight:"bold",display:"flex", margin:"0px 0px 20px 0px"}}>
      <div onClick={() => { setShowCouponPage(false) }} style={{cursor:"pointer"}}> <ArrowBackOutlinedIcon/> </div>
      <div style={{marginLeft:"15px"}}>Apply Coupon</div>
    </div>
    <div>
      <div style={{margin:"20px 0px 10px 0px" ,fontWeight:"bolder",}}>Offers for you</div>
      <CouponCard applyCoupon={applyCoupon} />
    </div>
   </div>
  </>
  )
}

export default CouponPage
