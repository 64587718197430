import * as React from 'react';
import { useState } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import {Menu, MenuItem } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red, teal, yellow, orange, indigo, green, cyan, blue } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function MessageUi({ post, deleteMessage, userId }) {

   const timestamp = new Date(post.timestamp)
   const timestampString = `${timestamp.getDate()}/${timestamp.getMonth()}/${timestamp.getFullYear()} ${timestamp.getHours()}:${timestamp.getMinutes()}`

   const colors = [red, teal, yellow, orange, indigo, green, cyan, blue];
   const randomColor = colors[Math.floor(Math.random() * colors.length)]
   const [anchorEl, setAnchorEl] = useState(null);

   const handleClick = (event) => {
     setAnchorEl(event.currentTarget);
  
   };
 
   const handleClose = () => {
     setAnchorEl(null);
   };
 
   const handleDelete = () => {
     deleteMessage(post.messageId)
     handleClose();
   }

  return (
    <Card sx={{ marginBottom: "1rem" }}>
      <CardHeader
        sx={{padding: "0.5rem"}}
        avatar={
          <Avatar sx={{ bgcolor: randomColor[500] }}>
            {post.name?.substring(0,1)}
          </Avatar>
        }
        action={
          post.userId === userId &&
          <>
          <IconButton aria-label="settings" onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                style: {
                    paddingLeft: "0.5rem",
                    paddingRight: "0.5rem",
                    borderRadius: "0.5rem" 
                },
            }}
            >
              <MenuItem onClick={handleDelete}  >
                <DeleteIcon sx={{mr:0.1,pd:1}} />
                Delete
              </MenuItem>
            </Menu>
          </>
          
        }
        title={post.name}
        subheader={timestampString}
      />
      <CardContent sx={{padding: "0.5rem"}} >
        <Typography variant="body2" color="text.secondary">
          { post.message }
        </Typography>
      </CardContent>
    </Card>
  );
}
