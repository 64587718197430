import React, { useEffect, useState} from 'react'
import './Cards.css'
import {Refresh, DirectionsBus} from '@mui/icons-material'

export function ArrivedCard({ name2, time, delay, name, arrivedTime, RefreshData, loadingRefresh, isDelay, background }) {
  const [width, setWidth] = useState(window.innerWidth)
  const [backgroundArrivedColor, setBackgroundArrivedColor] = useState("#e5d4ff");
  const [colorCityArrivedName, setColorArrivedCityName] = useState("#585858");
  const [timeColorArrived, setTimeColorArrived] = useState("#67b532");
  const [refreshBackground, setRefreshBackground] = useState('#ffd9d9')
  const [isDelayState, setIsDelayState] = useState(isDelay)
  window.addEventListener('resize', () => {
    setWidth(window.innerWidth)
  })

  useEffect(() => {
    if (isDelayState === true) {
      setTimeColorArrived("#E03434");
    } else {
      setTimeColorArrived("#e5d4ff");
    }
  },[isDelayState])
  const [counter, setCounter] = useState(30)
  useEffect(() => {
    let timer 
    if(loadingRefresh){
      setCounter(30)
      if(counter === 30){
        timer = setInterval(() => {
          setCounter(counter - 1)

        }, 1000)
      }
    }else if(loadingRefresh === false || counter === 30){
      timer = setInterval(() => {
        setCounter(counter - 1)
      }, 1000)
      if(counter == 0){
        RefreshData()

      }
    }

    return () => {
      clearInterval(timer)
    }
  },[counter])
  
   return(
    <ul className="active-ul ul-override-arrived">
        <div style={{background: refreshBackground}} className={'refresh-div'}>
        <div className="refresh-c">
        <p>{counter} Sec</p>
        <div onClick={RefreshData}>
        
        {loadingRefresh ? (<Refresh className="icon roll" width={"15"} />) : (<Refresh className="icon" width={"15"} />)}
        </div>
        </div>
        </div>
      <li>
        <a className='active-li bus-enabled'>
        <DirectionsBus className='icon-b' htmlColor='#FFFFFF'/>
          <div style={{ background: backgroundArrivedColor }} className="name-bus2 name-bus2-shadow">
          <div className="name-bus-h2">
          <span style={{ color: colorCityArrivedName, paddingRight:'8px' }} className="name on-time2">
                {time.split(" ")[0]}
              </span>
              <span style={{ color: colorCityArrivedName }} className="name on-time2">
                {time.split(" ")[1]}
          </span>
          </div>
          </div>
          <div className="name-bus">
          <div className="name-bus-h" >
          <h4 className="name" style={{ color: colorCityArrivedName }}>{name}</h4>
          
          </div>
          <div className="name-bus-h">
          <span style={{color: timeColorArrived}} className="name">
                {isDelay ? `${delay}` : "On Time"}
          </span>
          </div>
          </div>
          </a>
          <div >
          <div className="arrived-at">
            <div className="arrived-at-c">
            <a href="https://wa.me/918089783756">
            <span style={{fontSize:"14px" }}>Report inaccuracy</span>
            </a>
            </div>
          </div>
        </div>
          </li>
      </ul>
   )
}