
import React, { useEffect, useState } from 'react';
import { isAndroid, isChrome,isIOS ,OsTypes ,osName} from 'react-device-detect';
import ScrollDialog from './detectDevice/ScrollDialog';

function CardWallPost (props) {




  
    return (
      <div className='card' style={{margin:"20px" , borderRadius:"1rem" ,}}>
        <div className='card__body'>
          <div style={{ display: "flex", textAlign: "center", justifyContent: "center", textDecoration: "none",
          color: "blue",  borderStyle: "double", padding: "0.5rem", borderRadius: "1rem"}}><ScrollDialog/> </div>
          
        </div>
      </div>
    )
}

export default CardWallPost;

{/* <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
<a  style={{ display: "flex", textAlign: "center", justifyContent: "center", textDecoration: "none",
   color: "green",  borderStyle: "double", padding: "0.5rem", borderRadius: "0.5rem", marginBottom: "0.5rem" }}  */}



  