import React, { useEffect } from 'react';
import StatusProgress from './StatusProgress';
import { useState } from 'react';
import { cancelOrder, convertToCOD, getLatestOrder } from '../../api/delivery';
import { Button, LinearProgress } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { isLoggedIn } from '../../api/account';
import CancelOrderAlertDialog from './CancelOrderAlertDialog';
import { pushGA4CustomEvent } from '../../Analytics';

const Status = (props) => {
  const [order, setOrder] = useState(props.order)
  const [paymentPending, setPaymentPending] = useState(false)
  const [cancelOrderClicked, setCancelOrderClicked] = useState(false)
  const [covertToCodClicked, setCovertToCodClicked] = useState(false)
  const [progressBarOpen, setProgressBarOpen ] = useState(false)
  const [wizardOrderConfirmed, setWizardOrderConfirmed ] = useState(false)
  const [cancelOrderConfirmationAlert, setCancelOrderConfirmationAlert ] = useState(false)

  const user = isLoggedIn()

  const openCancelConfirmationAlert = async () => {
    setCancelOrderConfirmationAlert(true)
  }
  const closeCancelConfirmationAlert = () => {
    setCancelOrderConfirmationAlert(false)
  }
  const cancelConfirmed = () => {
    setCancelOrderConfirmationAlert(false)
    setCancelOrderClicked(true)
  }

  useEffect(() => {
    const fetchData=async ()=> {
      if(!order) {
        let latestOrder =  await getLatestOrder();
        setOrder(latestOrder) 
      }
    }
    const invokeCancelOrder = async ()=>{
      let pageTitle = "COD order cancelled"
      let pageUrl = "ordercancelled"
      pushGA4CustomEvent(pageTitle,pageUrl)
      setProgressBarOpen(true)
      await cancelOrder(order.orderId)
      setProgressBarOpen(false)
      props.showPAD()
    }
    const invokeConvertToCod = async ()=>{
      setProgressBarOpen(true)
      await convertToCOD(order.orderId)
      setCovertToCodClicked(false)
      let latestOrder =  await getLatestOrder();
      setOrder(latestOrder)
      setProgressBarOpen(false)
    }
    if(order && order.orderData?.paymentType === 'ONLINE') {
      if(!order.orderData.paymentStatus.find((paymentStatus) => paymentStatus.status === 'AUTHORIZED')) {
        setPaymentPending(true)
      }
    } else {
      setPaymentPending(false)
    }
    if(order && order.orderData?.status) {
      if(order.orderData.status.find((status) => status.status === 'ORDER_CONFIRMED')) {
        setWizardOrderConfirmed(true)
      }
    } else {
      setWizardOrderConfirmed(false)
    }
    fetchData();
    if(cancelOrderClicked) invokeCancelOrder()
    if(covertToCodClicked) invokeConvertToCod()
  }, [order, cancelOrderClicked, covertToCodClicked]) 

  return (<>
  {progressBarOpen ? <LinearProgress /> :
   order &&  <> 
    <div style={{height:"20px",backgroundColor:"white", borderRadius:"10px",margin:"10px" , padding:"10px" ,color:"gray"}} >{`Order ID - ${order.orderId?.substring(0,13)}`}</div>
    { paymentPending && <>
      <div style={{backgroundColor:"white", borderRadius:"10px",margin:"10px" , padding:"10px" }} >
          <div style={{justifyContent: "center", alignItems: "center", display: "flex", margin: "0.8rem"}}>
            Waiting for Payment confirmation...
          </div>
          <div style={{ display: "flex", columnGap: "1rem" }}>
            <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
              <Button variant="outlined" startIcon={<HighlightOffIcon />}  color="secondary" size="small" onClick={() => {openCancelConfirmationAlert()}}>
                Cancel order
              </Button>
            </div>
            <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
              <Button variant="outlined"  color="primary" size="small" onClick={() => { setCovertToCodClicked(true)}}>
                Convert to COD
              </Button>
            </div>
          </div>
      </div>      
    </>}
    { order?.orderData?.paymentType === 'COD' && <>
      <div style={{backgroundColor:"white", borderRadius:"10px",margin:"10px" , padding:"10px" }} >
          <div style={{ display: "flex", columnGap: "1rem" }}>
            <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
              <span style={{ color: "grey", marginRight: "0.5rem" }}>Cash on Delivery</span>  ₹ {order.orderData.amount}
            </div>
          </div>
      </div>      
    </>}
    <div>  
    <StatusProgress order={order} />
    </div>
    { order?.orderData?.paymentType === 'COD' && !wizardOrderConfirmed &&
      <div style={{ margin: "1rem 0.5rem", alignItems: "center", display: "flex" }}>
        <Button variant="outlined" startIcon={<HighlightOffIcon />}  color="error" size="small" onClick={() => { setCancelOrderClicked(true)}}>
          Cancel order
        </Button>
      </div>
    }
    <div style = {{backgroundColor:"white", borderRadius:"10px",margin:"5px", marginBottom: "5rem" }}>
        <a  style={{ display: "flex", textAlign: "center", justifyContent: "center", textDecoration: "none",
          color: "green", padding: "0.3rem", borderRadius: "0.5rem", marginBottom: "0.5rem" }} 
        href={"https://wa.me/918089783756?text=" + " "+ "[ " + user?.email + " ]"+ " " + " [ " + user?.sub + " ] "+ " " + "[ " + order.orderId + " ]" +  " SEND MESSAGE TO START CONVERSATION : "}> <WhatsAppIcon style={{ marginRight:"0.5rem" }}/>click to chat with us </a>
    </div>
    <CancelOrderAlertDialog 
      open={ cancelOrderConfirmationAlert } 
      closeCancelConfirmationAlert={ closeCancelConfirmationAlert } 
      cancelConfirmed={ cancelConfirmed } />
    </>}
    
  </>
  )
}

export default Status 
