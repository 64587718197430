import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import format from "date-fns/format";

const StatusProgress = ({order}) => {
  const [pending, setPending] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [pickedUp, setPickedUp] = useState(false)
  const [onTheWay, setOnTheWay] = useState(false)
  const [delivered, setDelivered] = useState(false)
  const [wizardSwitch, setWizardSwitch] = useState(false)
  const [latestStatus, setLatestStatus] = useState(null)
  const [delayMessage, setDelayMessage ] = useState(false)

  useEffect(() => { 
    order.orderData?.status.map((o)=>{
      if(o.status==="ORDER_PENDING" || o.status==="ORDER_WIZARD_SWITCH" ){
        const timee = format(o.timestamp, "dd MMM yyyy' 'hh:mm aa")  
        setTimeout(() => {
          setPending({status:true, time:timee, dot:true})
        }, 500);   
      }
      if(o.status==="ORDER_CONFIRMED"){
        const timee = format(o.timestamp, "dd MMM yyyy' 'hh:mm aa")
        setConfirmed({status:false, time:timee})
        setTimeout(() => { 
          setConfirmed({status:true, time:timee})
        }, 1000);
        setTimeout(() => { 
          setConfirmed({status:true, time:timee, dot:true})
        }, 2000);
      }
      if(o.status==="ORDER_PICKED_UP"){
        const timee = format(o.timestamp, "dd MMM yyyy' 'hh:mm aa")
        setPickedUp({status:false, time:timee})
        setTimeout(() => { 
          setPickedUp({status:true, time:timee})
        }, 2000);
        setTimeout(() => { 
          setPickedUp({status:true, time:timee,dot:true})
        }, 3000);
      }
      if(o.status==="ORDER_ON_THE_WAY"){
        const timee = format(o.timestamp, "dd MMM yyyy' 'hh:mm aa")
        setOnTheWay({status:false, time:timee})
        setTimeout(() => { 
          setOnTheWay({status:true, time:timee})
        }, 3000);
        setTimeout(() => { 
          setOnTheWay({status:true, time:timee, dot:true})
        }, 4000);
      }
      if(o.status==="ORDER_DELIVERED"){
        const timee = format(o.timestamp, "dd MMM yyyy' 'hh:mm aa")
        setDelivered({status:false, time:timee})
        setTimeout(() => { 
          setDelivered({status:true, time:timee})
        }, 4000);
        setTimeout(() => { 
          setDelivered({status:true, time:timee,dot:true})
        }, 5000);
      }
    })  

    if(order.orderData?.status) {
      const status = order.orderData?.status[order.orderData?.status.length-1]?.status
      if(status) setLatestStatus(status)
      if(status === "ORDER_PENDING" && order?.orderTimeDiff) {
        const timeDiffInMin = order.orderTimeDiff / 60000
        if(timeDiffInMin > 15) setDelayMessage("All our Delivery Wizards are currently busy. Your order is delayed. We will work hard to improve this experience next time.")
      }
    }
    
  }, [])
  return (
    <>
    <div style={{backgroundColor:"white" , borderRadius:"2%" , margin:"10px" }}>
        <div style={{marginLeft:"1rem" ,paddingTop:"2vh"}}>
          { <div  style={{display:"flex", columnGap:"20px", position:"relative"}}>
            <span style={{height:"15px", width:"15px", backgroundColor:pending.dot?"green":"gray", borderRadius:"50%", display:"inline-block", position:"absolute", top:"0", left:"-5px", zIndex:"20", }}> </span>
            <span className={'gray-line'}> </span>
            <span className={confirmed.status?'line2':'line'}> </span>
            <div style={{ minHeight:"100px"}}></div>
              <div>
              <div style={{color:pending.dot?"black":"gray",fontWeight:"bold", display:"inline"}}>Created </div>
              <div style={{display:"inline", fontWeight:"bold", fontSize:"13px", color:"gray"}}>{pending.time} </div>
              {pending.dot && <div className={latestStatus === "ORDER_PENDING" ? "blink_me" : ""} style={{color:"black", fontSize:"14px", fontWeight:"bold",}}>
              { delayMessage ? delayMessage : 'Waiting for the perfect delivery wizard to take your order.' }
              </div>}
              </div>
            </div> 
            }
          { <div  style={{display:"flex", columnGap:"20px", position:"relative"}}>
            <span style={{height:"15px", width:"15px",backgroundColor:confirmed.dot?"green":"gray", borderRadius:"50%", display:"inline-block", position:"absolute", top:"0", left:"-5px", zIndex:"20", }}> </span>
            <span className={'gray-line'}> </span>
            <span className={pickedUp.status?'line2':'line'}> </span>
            <div style={{ minHeight:"100px"}}></div>
              <div>
              <div style={{color:confirmed.dot?"black":"gray",fontWeight:"bold", display:"inline"}}>Confirmed </div>
              <div style={{display:"inline", fontWeight:"bold", fontSize:"13px", color:"gray"}}>{confirmed.time} </div>
              {confirmed.dot && <div style={{color:"black", fontSize:"14px", fontWeight:"bold",}}>
              order is confirmed 
              </div>
              }
              {confirmed.dot && <div className={latestStatus === "ORDER_CONFIRMED" ? "blink_me" : ""} style={{color:"black", fontSize:"14px", fontWeight:"bold",}}>
              wizard is on the way to pick up the order
              </div> 
              }</div>
            </div> 
            } 
          { <div  style={{display:"flex", columnGap:"20px", position:"relative"}}>
            <span style={{height:"15px", width:"15px",backgroundColor:pickedUp.dot?"green":"gray", borderRadius:"50%", display:"inline-block", position:"absolute", top:"0", left:"-5px", zIndex:"20", }}> </span>
            <span className={'gray-line'}> </span>
            <span className={onTheWay.status?'line2':'line'}> </span>
            <div style={{ minHeight:"100px"}}></div>
              <div>
              <div style={{color:pickedUp.dot?"black":"gray",fontWeight:"bold", display:"inline"}}>Picked Up </div>
              <div style={{display:"inline", fontWeight:"bold", fontSize:"13px", color:"gray"}}>{pickedUp.time} </div>
              {pickedUp.dot && <div style={{color:"black", fontSize:"14px", fontWeight:"bold"}}>
              wizard has picked up the order 
              </div>
              }</div>
            </div> 
            } 
          { <div  style={{display:"flex", columnGap:"20px", position:"relative"}}>
            <span style={{height:"15px", width:"15px",backgroundColor:onTheWay.dot?"green":"gray", borderRadius:"50%", display:"inline-block", position:"absolute", top:"0", left:"-5px" }}> </span>
            <span className={'gray-line'}> </span>
            <span className={delivered.status?'line2':'line'}> </span>
            <div style={{ minHeight:"100px"}}></div>
              <div>
              <div style={{color:onTheWay.dot?"black":"gray",fontWeight:"bold", display:"inline"}}>On The Way </div>
              <div style={{display:"inline", fontWeight:"bold", fontSize:"13px", color:"gray"}}>{onTheWay.time} </div>
              {onTheWay.dot && <div className={latestStatus === "ORDER_ON_THE_WAY" ? "blink_me" : ""} style={{color:"black", fontSize:"14px", fontWeight:"bold",}}>
              your order will be there soon   
              </div> 
              }
              </div>
            </div> 
            } 
          { <div  style={{display:"flex", columnGap:"20px", position:"relative"}}>
            <span style={{height:"15px", width:"15px",backgroundColor:delivered.dot?"green":"gray", borderRadius:"50%", display:"inline-block", position:"absolute", top:"0", left:"-5px" }}> </span>
            <div style={{ minHeight:"100px"}}></div>       
              <div>
              <div style={{color:delivered.dot?"black":"gray",fontWeight:"bold", display:"inline"}}>Delivered </div>
              <div style={{display:"inline", fontWeight:"bold", fontSize:"13px", color:"gray"}}>{delivered.time} </div>
              {delivered && <div style={{color:"black", fontSize:"14px", fontWeight:"bold",}}>
              Your order is Delivered  
              </div> }
              </div>
            </div> 
            }   
        </div>
     </div>
    </>
  )
}

export default StatusProgress


  
  
  

