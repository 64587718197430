import { TextField,CircularProgress, Box } from "@material-ui/core";
import React, { useEffect, useState } from "react"; 
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent"; 
import Status from "./Status";
import { createOrder, getPlaces, getPrice,getOrderStatus, getOrderVehicleAvailability } from "../../api/delivery";
import TaskDetails from './TaskDetails/taskDetails';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'; 
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ReactGA from 'react-ga';
import { getUserProfile } from "../../api/user";
import RazorPay from "../payment/RazorPay";
import { ORDER_VEHICLE_TYPE, PAYMENT_TYPE } from "../../config/constant";
import PaymentRadioButton from "./PaymentRadioButton";
import { Alert, Button, InputAdornment, LinearProgress } from "@mui/material";
import ListOfStores from "../listOfStores";
import VehicleRadioButton from "./VehicleRadioButton";
import MerchantAccordian from "./merchant/MerchantAccordian";
import { pushGA4CustomEvent } from "../../Analytics";
import DiscountSection from "./discount/DiscountSection";
import CouponPage from "./discount/CouponPage";

function PAD(props) {
  const [pickUpEnabled, setPickUpEnabled] = useState(false);
  const [dropEnabled, setDropEnabled] = useState(false);
  const [place, setPlace] = useState({ pickUpPlace: "", dropPlace: "" });
  const [pickUpOpen, setPickUpOpen] = React.useState(false);
  const [dropOpen, setDropOpen] = React.useState(false);
  const [btnDisabled, setBtnDisabled] = React.useState(true);
  const [statusComponent, setStatusComponent] = React.useState(false);
  const [taskDetailsComponent, setTaskDetailsComponent] = React.useState(false);
  const [gridData, setGridData] = React.useState(false);
  const [orderId, setOrderId] = React.useState(null);
  const [loader,setLoader]=useState(false);
  const [confirmed, setConfirmed ] = useState(false);
  const [newOrder, setNewOrder] = useState(null);
  const [showRazorPay, setShowRazorPay] = useState(false);
  const [paymentType, setPaymentType] = useState(PAYMENT_TYPE.ONLINE);
  const [betaTestingMessage, setBetaTestingMessage] = useState(true);
  const [showListOfBusiness, setShowListOfBusiness] = useState(false);
  const [showPopularLocationButton, setShowPopularLocationButton] = useState(true);
  const [orderVehicleType, setOrderVehicleType] = useState(ORDER_VEHICLE_TYPE.THREE_WHEELER);
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [vehicleAvailability, setVehicleAvailability] = useState({});
  const [vehicleAvailabilityOperations, setVehicleAvailabilityOperations] = useState({}); 
  const [taskDetailedAdded, setTaskDetailedAdded] = useState(false);
  const [showLinearProgressBar, setShowLinearProgressBar] = useState(false);
  const [discountApplied, setDiscountApplied] = useState(false);
  const [showCouponPage, setShowCouponPage] = useState(false);
  const [coupon, setCoupon] = useState(null);

  const [taskName, setTaskName] = React.useState("");
  const [taskDetails, setTaskDetails] = React.useState("");
  const [form, setForm] = useState({
    item: "Other",
    itemDetails: "",
    pickUpAddress: "",
    pickUpPhone: "",
    dropAddress: "",
    dropPhone: "",
    orderVehicleType: ""
  });
  const [formDisplay, setFormDisplay] = useState({pickUpDisplay:false,dropDisplay:false}); 
  const [savedPlaces, setSavedPlaces] = useState(false); 
  const [prices, setPrices] = useState(false); 
  const [saveAddressesPickupBox, setSaveAddressesPickupBox] = useState(false); 
  const [saveAddressesDropBox, setSaveAddressesDropBox] = useState(false); 
  const [orderStatus, setOrderStatus] = useState(false); 

  const [isAddressGridOpen, setIsAddressGridOpen] = useState(false)

  useEffect(() => {
    setIsAddressGridOpen((pickUpEnabled || dropEnabled) && !btnDisabled)
  }, [pickUpEnabled, dropEnabled, btnDisabled])

  useEffect(() => {
    const fetchData=async ()=>{
      let res = await getPlaces();
      let userProfileResponse =await getUserProfile();
      setGridData(res)  ;
      setSavedPlaces(userProfileResponse.userProfile.address);
    }
    fetchData();
  },[]) 

  useEffect(() => {
    if(taskDetailedAdded && place.pickUpAreaId && place.dropAreaId) {
      const fetchPriceAndVehicleDetails = async () => {
        setShowLinearProgressBar(true);
        let res = await getPrice(  place.pickUpAreaId , place.dropAreaId);
        setPrices(res) 
        setTotal(res.threeWheeler)
        let availabilityData = await getOrderVehicleAvailability();
        setVehicleAvailability(availabilityData?.vehicleAvailability)
        setVehicleAvailabilityOperations(availabilityData?.operations)
        setShowLinearProgressBar(false);  
      }
      fetchPriceAndVehicleDetails();
    } else {
      return
    }
  },[taskDetailedAdded, place]) 
  
  
  const handleDialogClose = () => {
    setPickUpOpen(false);
    setDropOpen(false);
  };

const onclickPickup = () => {
  setBetaTestingMessage(false)
  setShowListOfBusiness(false)
  setShowPopularLocationButton(false)
  if(savedPlaces && savedPlaces.length){
    setSaveAddressesPickupBox(true) 
    setPickUpEnabled(false)
  }else{
    setPickUpEnabled(!pickUpEnabled);
    setDropEnabled(false);
    setBtnDisabled(false);
  }
  let pageTitle = "onclick Pickup"
  let pageUrl = "onclickPickup"
  pushGA4CustomEvent(pageTitle,pageUrl)
 
}

const onclickDrop = () => {
  if(savedPlaces && savedPlaces.length){
    setSaveAddressesDropBox(true) 
    setDropEnabled(false)
  }else{
    setDropEnabled((!dropEnabled));
    setPickUpEnabled(false);
    setBtnDisabled(false);
  }
}

  const gridButtonClicked = (id , name) => {
    if (pickUpEnabled) {
      setPlace({  ...place, pickUpPlace: name ,pickUpAreaId:id});
      setPickUpOpen(true);
      console.log(id);
      console.log(name);
      
    } else {
      setPlace({ ...place, dropPlace: name ,dropAreaId:id});
      setDropOpen(true);
      setDropEnabled(false);
      console.log(name);
    }
  };

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value }); 
  };
 
  const addTaskDetails=async(e)=>{
    e.preventDefault();
    console.log(place,form) 
    setTaskDetailsComponent(true); 
  }

  const backTo = () => {
    console.log("back clicked")
    props.showPageDelivery()
  }
  
  const saveSavedPickupAddress=(pickUpAddress,pickUpPhone,pickUpAreaId,pickUpName,pickUpId) => {
    console.log(pickUpAreaId)
    console.log(pickUpAddress)
    setShowPopularLocationButton(false)
    setForm({...form, pickUpAddress:pickUpAddress,pickUpPhone:pickUpPhone,pickUpName:pickUpName,pickUpId:pickUpId});
    setPlace({...place,pickUpAreaId})
    handleDialogClose();
    setBtnDisabled(true)
    formDisplay.dropDisplay?setFormDisplay({pickUpDisplay:true,dropDisplay:true}):setFormDisplay({pickUpDisplay:true,dropDisplay:false})
    setPickUpEnabled(false)
    setSaveAddressesPickupBox(false)
  }
  const saveSavedDropAddress=(dropAddress,dropPhone,dropAreaId,dropName,dropId) => {
    console.log(dropAreaId)
    setForm({...form, dropAddress:dropAddress,dropPhone:dropPhone,dropName:dropName,dropId:dropId});
    setPlace({...place, dropAreaId})
    handleDialogClose(); 
    setBtnDisabled(true);
    setFormDisplay({pickUpDisplay:true,dropDisplay:true})
    setDropEnabled(false)
    setSaveAddressesDropBox(false)
  }

  const selectPickUpLocationFromStoreList = (store) => {
    setShowPopularLocationButton(false)
    setForm({...form, 
      pickUpAddress : store?.address?.address,
      pickUpPhone: store?.address?.phone,
      pickUpName: store?.address?.name,
      pickUpId: store?.address?.areaId});
    setPlace({...place, 
      pickUpAreaId: store?.address?.areaId })
    handleDialogClose();
    setBtnDisabled(true)
    formDisplay.dropDisplay?setFormDisplay({pickUpDisplay:true,dropDisplay:true}):setFormDisplay({pickUpDisplay:true,dropDisplay:false})
    setPickUpEnabled(false)
    setSaveAddressesPickupBox(false)
    setBetaTestingMessage(false)
    setShowListOfBusiness(false)
  }

  const onConfirm = () => {
    setConfirmed(true)
  }

  useEffect(() => {
    async function confirmedOrder() {
      setTaskDetailsComponent(false);
      setLoader(true)
      const orderCreationData = {
          "pickUpAddress":{
            "name": form.pickUpName,
            "areaId": place.pickUpAreaId,
            "address": form.pickUpAddress,
            "phone": form.pickUpPhone?.startsWith("+91") || form.pickUpPhone?.startsWith("0497") ? form.pickUpPhone : ("+91" + form.pickUpPhone),
            "id":form.pickUpId,
          },
          "dropAddress": {
            "name": form.dropName,
            "areaId": place.dropAreaId,
            "address": form.dropAddress,
            "phone": form.dropPhone?.startsWith("+91") ? form.dropPhone : ("+91" + form.dropPhone),
            "id":form.dropId
          },
          "item":taskName,
          "itemDetails":taskDetails,
          "paymentType": paymentType,
          "vehicleType": orderVehicleType,
          "total": total,
          "amount": amount,
          "discount": discount,
          "coupon": coupon
      }
      const order =  await createOrder(orderCreationData)
      setNewOrder(order)
      setOrderId(order.orderId)

      if (paymentType === PAYMENT_TYPE.ONLINE) {
        setShowRazorPay(true)
      } else {
        const orderStatus= await getOrderStatus(order.orderId);
        setOrderStatus(orderStatus)
        setStatusComponent(true)
      }
      setLoader(false)
    }
    if(confirmed){
      confirmedOrder()
    }
  }, [confirmed])

  const orderPaymentSuccess = async () => {
    const orderStatus = await getOrderStatus(orderId);
    setOrderStatus(orderStatus)
    setStatusComponent(true)
    setShowRazorPay(false)
  }

  const orderPaymentFailed = async () => {
    setShowRazorPay(false)
  }

  const applyCoupon = (coupon) => {
    let discountValue = Math.round(total * (coupon.discountPercentage / 100))
    if(discountValue > coupon.maxDiscount){
      discountValue = coupon.maxDiscount
    }
    setDiscount(discountValue)
    setAmount(total - discountValue)
    setCoupon(coupon)
    setDiscountApplied(true)
    setShowCouponPage(false)
  }

  const cancelAppliedCoupon = () => {
    setAmount(total)
    if(discountApplied) {
      setDiscountApplied(false)
    }
  }

  // When total is updated, remove all discounts and update the "To pay" amount value
  useEffect(() => {
    cancelAppliedCoupon()
  },[total]) 

  if(taskDetailsComponent){
    return(
      <TaskDetails taskName={taskName} setTaskName={setTaskName} 
      taskDetails={taskDetails} setTaskDetails={setTaskDetails} 
      setTaskDetailsComponent={setTaskDetailsComponent} setTaskDetailedAdded={setTaskDetailedAdded} />
    )
  }
  else if(loader){
    return(
      <div style={{width:"fit-content", margin:"60px auto"}}><CircularProgress fontSize="15px" /></div>
      )
  } else if(statusComponent){
    return(
      <>
      {statusComponent && <Status orderId={orderId} showPAD={props.showPageDelivery} /> }
      </>
      )
  } else if(showCouponPage) {
    return (<CouponPage setShowCouponPage={setShowCouponPage} applyCoupon={applyCoupon} />)
  } else if(showRazorPay){
    return(
      <>
      {showRazorPay && <RazorPay order={newOrder} orderPaymentSuccess={orderPaymentSuccess} orderPaymentFailed={orderPaymentFailed} /> }
      </>
      )
  }
  else {
    return(
    <>
        { (
          <div style={{margin:"1px"}}> 
            <div
              style={{
                backgroundColor: "white",
                margin: "10px auto 15px auto",
                width: "350px",
                padding: "13px 13px 26px 13px ",
                borderRadius: "10px",
                display:statusComponent?"none":"block" 
              }}
            >
             { taskName === "" ?
              <>
                <div onClick={backTo} style={{cursor:"pointer"}}> <ArrowBackOutlinedIcon/> </div>
                <div style={{ margin:"10px auto", borderRadius: "15px ", display: "flex", justifyContent: "space-between", }}>
                  {/* */}
                  <div style={{ width: "60px", height:"60px" }}> <img style={{width:"100%", height:"100%"}} src="./icons-location-80.png" alt=""  /> </div>
                  <div style={{ width: "140px", height:"auto", display:"flex", flexDirection:"column", alignItems: "center" }}> <img style={{width:"40%", height:"auto"}} src="./motorbike.png" alt="" srcSet="" />  <span style={{ height:"0px" , borderBottom:"2px dotted black", width:"140px", marginTop:"0px",  }}></span> </div>
                  <div style={{ width: "60px", height:"60px" }}> <img style={{width:"100%", height:"100%"}} src="./icons-location-80.png" alt=""  /> </div>
                </div>
                <div
                  style={{
                    borderRadius: "15px ",
                    display: "flex", 
                    justifyContent: "space-around",
                  }}
                  >
                  <div style={{ width: "150px" }}>
                    <Button 
                      onClick={() => {
                        onclickPickup()
                      }}  
                      disabled={!formDisplay.pickUpDisplay?false:(formDisplay.pickUpDisplay&&formDisplay.dropDisplay)?false:true}
                      variant="contained"
                      color="primary"
                      style={{ width: "150px", fontSize:"12px" }}
                      >       
                      Select Pickup
                    </Button> 
                  </div>
                  <div style={{ width: "150px"}}>
                    <Button 
                      onClick={() => {
                      onclickDrop() 
                    }} 
                    disabled={formDisplay.pickUpDisplay?false:(formDisplay.pickUpDisplay&&formDisplay.dropDisplay)?false:true}
                      variant="contained"
                      color="primary"
                      style={{ width: "150px", fontSize:"12px" }}
                    >
                      Select Drop
                    </Button> 
                  </div>
                </div>
              </>
              : ""
              } 

              <div style={{display:"flex",justifyContent:(formDisplay.pickUpDisplay && formDisplay.dropDisplay)?"space-around":"space-between" , fontSize:"13px"}}>
              {formDisplay.pickUpDisplay && <div style={{ wordWrap: "break-word", marginTop: "5px",   width:"150px",display:"flex",flexDirection:"column" , padding:" 5px 10px",}}>
                  <div style={{border:"2px solid #d1d1d1", padding:"7px", borderRadius:"5px"}}>
                    <div style={{display:"flex", alignItems:"center", columnGap:"10px",width:"100%", wordBreak:"break-word",}}>
                      
                      <div onClick={() => setPickUpOpen(true)}  style={{ borderBottom:"2px solid #dbdbdb", paddingBottom:"10px",cursor:"pointer",width:"100%",}}> 
                        <div style={{fontWeight:"bold",  fontSize:"12px",overflow:"hidden",textOverflow:"ellipsis", whiteSpace:"nowrap",}}>{ place.pickUpPlace}</div>
                        <div style={{fontSize:"10px", fontWeight:"bold" ,color:"#a8a8a8",overflow:"hidden",textOverflow:"ellipsis", whiteSpace:"nowrap",}}>{ form.pickUpAddress}</div>
                      </div>
                    </div>
                    <div style={{display:"flex", alignItems:"center", columnGap:"10px", margin:"10px 0px",width:"100%", wordBreak:"break-word"}}>
                      
                      <div  style={{fontSize:"12px", fontWeight:"bold", color:"#949494",overflow:"hidden",textOverflow:"ellipsis", whiteSpace:"nowrap",}}>{ form.pickUpPhone} ({form.pickUpName})</div>
                      
                      </div>
                  </div>

                   
                </div>
              }
              
              
              {formDisplay.dropDisplay  &&  <div style={{ wordWrap: "break-word", marginTop: "5px", width:"150px", padding:"5px 10px",   }}>
                 
              <div style={{border:"2px solid #d1d1d1", padding:"7px", borderRadius:"5px"}}>
                    <div style={{display:"flex", alignItems:"center", columnGap:"10px",width:"100%", wordBreak:"break-word"}}>
                      
                      <div  onClick={() => setDropOpen(true)} style={{ borderBottom:"2px solid #dbdbdb", paddingBottom:"10px", cursor:"pointer",width:"100%",}}> 
                      <div style={{fontWeight:"bold",  fontSize:"12px",overflow:"hidden",textOverflow:"ellipsis", whiteSpace:"nowrap",}}>{ place.dropPlace}</div>
                        <div style={{fontSize:"10px", fontWeight:"bold" ,color:"#a8a8a8",overflow:"hidden",textOverflow:"ellipsis", whiteSpace:"nowrap",}}>{ form.dropAddress}</div>
                      </div>
                    </div>
                    <div style={{display:"flex", alignItems:"center", columnGap:"10px", margin:"10px 0px",width:"100%", wordBreak:"break-word"}}>
                      
                      <div  style={{fontSize:"12px", fontWeight:"bold", color:"#949494", overflow:"hidden",textOverflow:"ellipsis", whiteSpace:"nowrap",}}>{ form.dropPhone} ({form.dropName})</div>
                      
                      </div>
                  </div>
 
                </div>
              }
              </div>
              {
                ( formDisplay.pickUpDisplay && formDisplay.dropDisplay ) && <>

             {taskName===""?<div onClick={addTaskDetails} style={{border:"3px solid #800080 ", display:"flex",alignItems:"center", padding:"10px", fontWeight:"bolder", fontSize:"13px", borderRadius:"5px", cursor:"pointer", margin:"13px 13px 0px 13px"}}>
             <AssignmentOutlinedIcon/>
              <div style={{marginLeft:"10px", fontWeight:"bold"}}>Add Task Details</div>
             <AddCircleOutlinedIcon style={{marginLeft:"auto", color:"#800080"}}/>
              </div>:<><div onClick={addTaskDetails} style={{border:"2px solid #dbdbdb ",display:"flex", alignItems:"center", columnGap:"5px", padding:"10px", fontWeight:"bolder", fontSize:"13px", borderRadius:"5px", cursor:"pointer", margin:"13px 13px 0px 13px"}}>
              <CheckCircleRoundedIcon style={{color:"green"}}/>  
                <div>{taskName}</div>
                
              </div></>}
              </>
    
                }
            </div>
            { isAddressGridOpen && <div style={{
                 backgroundColor: "white",
                 height: formDisplay.pickUpDisplay||formDisplay.dropDisplay?"50vh":"60vh",
                 margin: "auto",
                 marginBottom: "4rem",
                 padding: "15px ",
                 width: "350px", 
                 display:statusComponent?"none":"block" ,
                 borderRadius: "10px",
                 overflow:"hidden"
            }}>
           { pickUpEnabled?<div style={{textAlign:"center", fontWeight:"bolder", fontSize:"20px", margin:"5px auto 10px", }}>Select Pickup Area</div>:<div style={{textAlign:"center", fontWeight:"bolder", fontSize:"20px", margin:"5px auto 10px", }}>Select Drop Area</div>}
              <div
              style={{ 
                display:"flex" , 
                gap: "15px", 
                height:"inherit",
                flexWrap: "wrap",
                alignContent: "flex-start", 
                justifyItems: "center",
                justifyContent: "center",
                overflowY: "auto"
              }}
            >
              {gridData && gridData.map((row)=>{ 
                return row.map((data)=>{
                  return  <Button 
                  key={data.id+Math.random()}
                  //id cannot be used as keysmany because many ids have same value, so I have used Math.random so that console look clear. You may  change it if the actual data have diffrent ids.
                  onClick={() => gridButtonClicked(data.id , data.name)}
                  value={data.id}
                  disabled={btnDisabled}
                  style={{ width: "90px", height: "80px", fontSize:"10px" }}
                  variant="contained"
                  color="primary"
                >
                  {data.name}
                </Button> 
                })

              })}
              
              {/* Duummy divs to prevent tiles of last row of flexbox to spread out */}
              <div style={{ width: "90px", height: "0px" }}></div>
              <div style={{ width: "90px", height: "0px" }}></div>
              <div style={{ width: "90px", height: "0px" }}></div>
              
            </div>
            </div>}
            
          </div>
        )}

        { betaTestingMessage ? 
          <div style={{ margin: "1rem", display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", bottom: "4rem" }}>
            <Alert severity="info">Mattool Wizards are currently active from 10 am to 10 pm; for further assistance, please chat with us on WhatsApp at 8089783756</Alert>
          </div> : ''
        }

        <div style={{display:"none"}}>
          <Dialog
            open={pickUpOpen}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent  style={{ paddingBottom:"1px", display: "flex", justifyContent:" center"}}>
             Pick up address details
            </DialogContent>
            <DialogContent  style={{ paddingBottom:"1px"}}>
            <TextField
                fullWidth
                variant="outlined"
                label="Name"
                name="pickUpName"
                id="pickUpName"
                value={form.pickUpName}
                onChange={onChange}
              />
            </DialogContent>
            <DialogContent style={{ paddingBottom:"1px"}}>
              <TextField
                fullWidth
                label="Address"
                multiline
                minRows={4}
                variant="outlined"
                name="pickUpAddress"
                id="pickUpAddress"
                value={form.pickUpAddress}
                onChange={onChange}
              />
            </DialogContent>
            <DialogContent>
              <TextField
                fullWidth
                variant="outlined"
                label="Contact number"
                name="pickUpPhone"
                id="pickUpPhone"
                value={form.pickUpPhone}
                InputProps={{
                  startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                }}
                type="number"
                onChange={onChange}
              />
            </DialogContent>
            <DialogActions>
              <Button
                disabled={
                  form.pickUpAddress === "" || form.pickUpPhone === ""
                    ? true
                    : false
                }
                style={{ marginRight: "15px" }}
                variant="contained"
                color="primary"
                onClick={() => {
                  handleDialogClose();
                  setBtnDisabled(true)
                  formDisplay.dropDisplay?setFormDisplay({pickUpDisplay:true,dropDisplay:true}):setFormDisplay({pickUpDisplay:true,dropDisplay:false})
                  setSaveAddressesPickupBox(false)
                }}
                autoFocus
              >
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <div style={{display:"none"}}>
          <Dialog
            open={dropOpen}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent  style={{ paddingBottom:"1px", display: "flex", justifyContent:" center"}}>
             Delivery address details
            </DialogContent>
            <DialogContent>
            <TextField
                fullWidth
                variant="outlined"
                label="Name"
                name="dropName"
                id="dropName"
                value={form.dropName}
                onChange={onChange}
              />
                </DialogContent>
            <DialogContent>
              <TextField
                fullWidth
                label="Address"
                multiline
                minRows={4}
                variant="outlined"
                name="dropAddress"
                id="dropAddress"
                value={form.dropAddress}
                onChange={onChange}
              />
            </DialogContent>
            <DialogContent>
              <TextField
                fullWidth
                variant="outlined"
                label="Contact number"
                name="dropPhone"
                id="dropUpPhone"
                value={form.dropPhone}
                InputProps={{
                  startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                }}
                type="number"
                onChange={onChange}
              />
            </DialogContent>
            <DialogActions>
              <Button
                disabled={
                  form.dropAddress === "" || form.dropPhone === "" ? true : false
                }
                style={{ marginRight: "15px" }}
                variant="contained"
                color="primary"
                onClick={() => {
                  handleDialogClose();
                  setBtnDisabled(true);
                  setFormDisplay({pickUpDisplay:true,dropDisplay:true})
                  setSaveAddressesDropBox(false)
                }}
                autoFocus
              >
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        </div> 
        <div style = {{backgroundColor:"white" ,margin:"18px",borderRadius:"10px"  }}> 
        {saveAddressesPickupBox   &&   <div style={{borderRadius:"10px", overflow:"hidden", width:"350px", margin:"auto",marginBottom:"4rem",  border:"2px solid #e6e6e6",backgroundColor:"white"}}>

      
        <div style={{fontWeight:"bold", backgroundColor:"#e4e4e7", padding:"20px"}}>
          Choose pickup address
        </div>

        <div>
            <div style={{fontWeight:"bold",  display:"flex", alignItems:"center", padding:"15px", columnGap:"10px", borderBottom:"3px solid #e6e6e6", cursor:"pointer" }} onClick={()=>{
                setSaveAddressesPickupBox(false)
                setPickUpEnabled(!pickUpEnabled);
                setDropEnabled(false);
                setBtnDisabled(false);
              }
              }>
            <AddCircleOutlineOutlinedIcon style={{color:"#800080", fontSize:"30px"}} />
              <div style={{fontWeight:"bolder", color:"#800080"  }}>
                Add New Address
              </div>
            </div>
        </div>
        <div style={{maxHeight:"215px", overflow:"auto"}}>
          { savedPlaces && savedPlaces.map((s) => {
            return <>
              <div style={{padding:"15px", display:"flex", alignItems:"center", columnGap:"10px",borderBottom:"3px solid #e6e6e6", cursor:"pointer", }} 
                onClick={()=>saveSavedPickupAddress(s.address,s.phone,s.areaId,s.name,s.id)}
                >
                <LocationOnOutlinedIcon style={{ fontSize:"30px", color:"#616161"}} />
                <div> 
                  <div style={{fontWeight:"bolder"}}>
                    {s.areaId}
                  </div>
                  <div style={{fontSize:"14px", fontWeight:"bold", color:"#616161"}}>
                    {s.address}
                  </div>
                </div>
              </div>
            </>
            })
          }
        </div>
        </div>}
        </div>
     
        <div style = {{backgroundColor:"white" ,margin:"18px",borderRadius:"10px"  }}>
        {saveAddressesDropBox   &&   
        <div style={{borderRadius:"10px", overflow:"hidden", width:"350px", margin:"auto",marginBottom:"4rem", border:"2px solid #e6e6e6"}}>
          <div style={{fontWeight:"bold", backgroundColor:"#e4e4e7", padding:"20px"}}>
            Choose drop address
          </div>

          <div>
              <div style={{fontWeight:"bold",  display:"flex", alignItems:"center", padding:"15px", columnGap:"10px", borderBottom:"3px solid #e6e6e6", cursor:"pointer" }} onClick={()=>{
                setSaveAddressesDropBox(false)
                setDropEnabled((!dropEnabled));
                setPickUpEnabled(false);
                setBtnDisabled(false);
                }} >
              <AddCircleOutlineOutlinedIcon style={{color:"#800080", fontSize:"30px"}} />
                <div style={{fontWeight:"bolder", color:"#800080"  }} >
                  Add New Address
                </div>
              </div>
          </div>
          <div style={{maxHeight:"215px", overflow:"auto"}}>
            { savedPlaces && savedPlaces.map((s)=>{return <>
                <div style={{padding:"15px", display:"flex", alignItems:"center", columnGap:"10px",borderBottom:"3px solid #e6e6e6", cursor:"pointer", }} onClick={()=>saveSavedDropAddress(s.address,s.phone,s.areaId,s.name,s.id)}>
                    <LocationOnOutlinedIcon style={{ fontSize:"30px", color:"#616161"}} />
                <div> 

                <div style={{fontWeight:"bolder"}}>
                {s.areaId}
                </div>

                <div style={{fontSize:"14px", fontWeight:"bold", color:"#616161"}}>
                {s.address}
                </div>
              </div>
              </div>
              </>
            })}
          </div>
        </div>
        }
        </div>

        { (
          <div style={{margin:"1px"}}> 
            { showLinearProgressBar ? <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box> : '' }
            { taskName!=="" && prices ?
              <div
                style={{
                  backgroundColor: "white",
                  margin: "auto",
                  width: "350px",
                  padding: "13px 13px 26px 13px ",
                  borderRadius: "10px",
                  display:statusComponent?"none":"block" 
                }} >

                <div style={{borderBottom:"5px solid rgb(238 241 245)",display:"flex", alignItems:"center", columnGap:"5px", padding:"0", fontWeight:"bolder", fontSize:"13px", borderRadius:"5px", cursor:"pointer"}}>
                  <VehicleRadioButton orderVehicleType={orderVehicleType} setOrderVehicleType={setOrderVehicleType} prices={prices} setAmount={setAmount} 
                    setTotal={setTotal} vehicleAvailability={vehicleAvailability} cancelAppliedCoupon={cancelAppliedCoupon} />
                </div>

                <div style={{borderBottom:"5px solid rgb(238 241 245)",display:"flex", alignItems:"center", columnGap:"5px", padding:"0", fontWeight:"bolder", fontSize:"13px", borderRadius:"5px", cursor:"pointer"}}>
                  <PaymentRadioButton paymentType={paymentType} setPaymentType={setPaymentType} />
                </div>

                <div style={{borderBottom:"5px solid rgb(238 241 245)",display:"flex", alignItems:"center", columnGap:"5px", padding:"0", fontWeight:"bolder", fontSize:"13px", borderRadius:"5px", cursor:"pointer"}}>
                  <DiscountSection setShowCouponPage={setShowCouponPage} discountApplied={discountApplied} cancelAppliedCoupon={cancelAppliedCoupon} 
                    total={total} discount={discount} amount={amount} />
                </div>

                {vehicleAvailabilityOperations && vehicleAvailabilityOperations.maintenanceMode ? <div style={{color:"orangered", fontWeight:"bolder", fontSize:"13px", margin:"13px 13px 0px 13px"}}>
                  Pick up and delivery service is currently in maintenance. Please check back later. Sorry for the inconvenience.
                </div> 
                : vehicleAvailabilityOperations && vehicleAvailabilityOperations.paused && <div style={{color:"orangered", fontWeight:"bolder", fontSize:"13px", margin:"13px 13px 0px 13px"}}>
                  All our delivery wizards are currently busy. Your order will be delayed for upto { vehicleAvailabilityOperations.pausedTimeString || "2 hours" }. Sorry for the inconvenience.
                </div>}

                {
                !isAddressGridOpen && place.pickUpAreaId && place.dropAreaId && taskName!=="" && prices  && 
                <div style= {{ backgroundColor: "white", marginTop: "2rem", width: "22rem",
                  borderRadius: "10px", display: "block", marginBottom: "4rem"
                }}>
                <div onClick={onConfirm} style={{cursor:"pointer",backgroundColor:"#12AD2B",fontWeight:"bold",width:"320px", padding:"4%", textAlign:"center", color:"white", fontSize:"15px", borderRadius:"10px" , margin: "0.5rem 0", bottom:"90px"}}>
                  { paymentType === PAYMENT_TYPE.ONLINE ? `₹ ${amount}  |  Proceed to Pay ` : `₹ ${amount} | Place order`  }
                </div>
                </div>
                }
            </div>
            : "" }
          </div>
        )}

        {
          showListOfBusiness ? <div className= "deliveryPageWrapper"> 
            <MerchantAccordian selectPickUpLocationFromStoreList={selectPickUpLocationFromStoreList} /> 
          </div> : 
          showPopularLocationButton ? <div style={{ display:"flex", justifyContent:"center" }}>
            <Button onClick={() => setShowListOfBusiness(true)} style={{ color: "rgb(9 10 10)", backgroundColor: "rgb(208 214 217)", textTransform: "none" }} 
            className="button-38" >Show popular pickup locations</Button> 
          </div> : ''
        }
    </>
  )}
}     

export default PAD;