import Cookies from 'universal-cookie';
import { decode } from 'jsonwebtoken'
import axios from 'axios'
import { place, SERVICE_API_URL } from '../config/constant'
import { updatePushNotificationToken } from './user';

export const isLoggedIn = (query) => {
    const cookie = new Cookies();
    const token = cookie.get('auth_id_token')
    const accessToken = cookie.get('auth_access_token')
    const expires = cookie.get('auth_expires')
    const user = token && decode(token)
    return user;
}

export const getLocalNotificationToken = () => {
    const cookie = new Cookies();
    const user = isLoggedIn()
    const token = cookie.get(`notification_token_${user.sub}`)
    return token;
}

export const saveAndSendNotificationToBackend = async (notificationToken) => {
    const cookie = new Cookies();
    try {
        await updatePushNotificationToken(notificationToken)
        const user = isLoggedIn()
        cookie.set(`notification_token_${user.sub}`, notificationToken)
    } catch (e) {
        console.error(e)
        return null
    }
}

// @deprecated
export const idTokenlogin = (hash) => {
    const cookie = new Cookies();
    
    const authToken = hash.get("id_token") || hash.get("#id_token")
    const authAccessToken = hash.get("access_token") || hash.get("#access_token")
    const authExpires = hash.get("expires_in") || hash.get("#expires_in")

    if(authToken) cookie.set('auth_id_token', authToken,  { path: '/'})
    if(authAccessToken) cookie.set('auth_access_token', authAccessToken,  { path: '/'})
    if(authExpires) cookie.set('auth_expires', authExpires,  { path: '/'})
}

export const login = async (grantCode, page) => {
    const cookie = new Cookies();
    
    let tokens = {}

    try {
        const tokensResponse = await axios.post(SERVICE_API_URL+'/auth/login', { 
            app: "web",
            grantCode: grantCode,
            redirectPage: page
        });
        tokens = tokensResponse.data
    } catch (e) {
        console.error(e)
        return null
    }

    const after30Day = new Date();
    after30Day.setDate(after30Day.getDate()+30);

    if(tokens?.id_token) cookie.set('auth_id_token', tokens.id_token,  { path: '/', expires: after30Day})
    if(tokens?.access_token) cookie.set('auth_access_token', tokens.access_token,  { path: '/', expires: after30Day})
}

export const refreshToken = async () => {
    const cookie = new Cookies();

    const token = cookie.get('auth_id_token')
    const user = token && decode(token)

    let tokens = {}
    try {
        const tokensResponse = await axios.post(SERVICE_API_URL+'/auth/refresh', { 
            sub: user.sub
        });
        tokens = tokensResponse.data
    } catch (e) {
        console.error(e)
        return null
    }

    if(tokens?.id_token) cookie.set('auth_id_token', tokens?.id_token,  { path: '/'})
    if(tokens?.access_token) cookie.set('auth_access_token', tokens?.access_token,  { path: '/'})
}

export const logout = (query) => {
    const cookie = new Cookies();
    const user = isLoggedIn()
    cookie.remove('auth_id_token')
    cookie.remove('auth_access_token')
    if(user) cookie.remove(`notification_token_${user.sub}`)
}

export const getVerificationCode = async (grantCode, page) => {
    try {
        const response = await axios.get(SERVICE_API_URL+'/auth/verificationCode');
        return response?.data?.code
    } catch (e) {
        console.error(e)
        return null
    }
}

export const verifyUser = async (payload) => {
    try {
        const response = await axios.post(SERVICE_API_URL+'/adm/verifyCode', payload );
        return response?.status === 200
    } catch (e) {
        console.error(e)
        return null
    }
}

export const sendOTPForMobile = async (mobile) => {
    try {
        const response = await axios.post(SERVICE_API_URL+'/otp', { mobile } );
        return response?.status === 200
    } catch (e) {
        console.error(e)
        return null
    }
}

export const verifyMobileOTP = async (mobile, otp) => {
    try {
        const response = await axios.post(SERVICE_API_URL+'/otp/verify', { mobile, otp } );
        return response?.status === 200
    } catch (e) {
        console.error(e)
        return null
    }
}
