import React, {useState, useEffect} from 'react'
import './Cards.css'
export function FutureCard({name2, time, delay, name, isDelay, background }) {
  const [width, setWidth] = useState(window.innerWidth)
  const [backgroundFuture, setBackgroundFuture] = useState(background);
  const [colorCityName, setColorCityName] = useState("#585858");
  const [onTime, setOnTime] = useState(background);
  const [timeColor, setTimeColor] = useState("#68b532");
  window.addEventListener('resize', () => {
    setWidth(window.innerWidth)
  })
  const [isDelayState, setIsDelayState] = useState(isDelay);
  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });
  useEffect(() => {
    if (isDelayState === true) {
      setTimeColor("#E03434");
    } else {
      setTimeColor("#585858");
    }
  }, [isDelayState]);

   return(
    <ul className='active-ul'>
      <li>
        <a className='active-li'>
        <div style={{ background: backgroundFuture }} className="name-bus2">
        <div className="name-bus-h2">
        <span style={{ color: colorCityName, paddingRight:'8px' }} className="name on-time2">
          {time.split(" ")[0]}
        </span>
        <span style={{ color: colorCityName }} className="name on-time2">
          {time.split(" ")[1]}
        </span>
        </div>
        </div>
        <div className="name-bus">
        <div className="name-bus-h">
        <h4 style={{ color: colorCityName }} className="name">{name}</h4>
        </div>
        <div className="name-bus-h">
        {isDelay ? (
          <span
            style={{ color: timeColor }}
            className="name on-time future-time"
          >
            {delay}
          </span>
        ) : (
          <span
            style={{ color: onTime }}
            className="name on-time future-time"
          >
            On Time
          </span>
        )}
        </div>
        </div>
        </a></li>
      
    </ul>
   )
}