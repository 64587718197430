import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDQoR3sqJOc1y0rk5fg74UA8ZlXCVr5Bdc",
  authDomain: "mattool-app-0.firebaseapp.com",
  projectId: "mattool-app-0",
  storageBucket: "mattool-app-0.appspot.com",
  messagingSenderId: "893286414338",
  appId: "1:893286414338:web:e91675d820629601aacf1b",
  measurementId: "G-YS2X21BVLT"
};

let messaging = null;
const checkSupportAndGetMessaging = async () => {
  if(messaging) return messaging

  const supported = await isSupported();
  if (supported) {
    console.log("firebase.js isSupported" , isSupported())
    const firebaseApp = initializeApp(firebaseConfig);
    console.log("firebase.js firebaseApp" , firebaseApp)
    messaging = getMessaging(firebaseApp);
    console.log("firebase.js firebase messaging" , messaging)
    return messaging
  }
}

export const getNotificationToken = async (saveAndSendNotificationToBackend, setNotificationToken) => {
  const vapidKey = "BLGz8ncmZMFHZV8gNjBPuN9lNfGkZ5dTbOagwb3n_eadMj1fEHfH7QaT2s2pOA6gO1LX4HWDX0qwAvM_NxB2eEU"
  
  const firebaseMessaging = await checkSupportAndGetMessaging()
  if(!firebaseMessaging) return

  return getToken(firebaseMessaging, { vapidKey })
  .then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      saveAndSendNotificationToBackend(currentToken)
      setNotificationToken(currentToken);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setNotificationToken(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = async () => {
  const firebaseMessaging = await checkSupportAndGetMessaging()
  if(!firebaseMessaging) return

  return new Promise((resolve) => {
    onMessage(firebaseMessaging, (payload) => {
      resolve(payload);
    });
  });
}
