import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function CancelOrderAlertDialog(props) {

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => { props.closeCancelConfirmationAlertBox() }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to cancel the order?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ textTransform: "none"}} onClick={() => { props.closeCancelConfirmationAlert() }} >Don't Cancel</Button>
          <Button style={{ textTransform: "none"}} onClick={() => { props.cancelConfirmed() }} autoFocus>
            Yes Cancel 
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}