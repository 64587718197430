import React, { useState, useEffect } from 'react';
import { getSocialContents } from '../api/content'
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Alert, Snackbar } from '@mui/material';
import { isLoggedIn } from '../api/account';
import { navigateToLoginScreen } from './PageProfile';
import PageAddSocialContent from './PageAddSocialContent';

const PageSocial = (props) => {
  const [contents, setContents] = useState([])
  const [open, setOpen] = React.useState(false);
  const [addPage, setAddPage] = React.useState(false)
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    async function fetchMyAPI() {
      let contents = await getSocialContents()
      setContents(contents || [])
    }

    fetchMyAPI()
  }, [])

  const fabStyle = {
    position: 'fixed',
    bottom: 70,
    right: 15,
  };

  const addSocial = () => {
    console.log('hello from fab click ')
    const user = isLoggedIn()
    if(!user) {
      setOpen(true)
      setTimeout(() => { navigateToLoginScreen('addSocialContent') }, 1800);
    } else {
      setAddPage(true)
    }
  }

  return ( addPage ?
    <PageAddSocialContent setRefresh={props.setRefresh} doAppBarRefresh={props.loginFlow} /> :
    <div>
      <div className="socialWrapper">
        {contents.map((store, i) => 
          <SocialCard 
            key={i}
            {...store}
          />
        )}
      </div>
      <Fab sx={fabStyle} color="primary" aria-label="add" onClick={addSocial}>
        <AddIcon />
      </Fab>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
        You are not logged in. Please log in to add your content.
      </Alert>
      </Snackbar>
    </div>
    )
}
  
function SocialCard(props) {
  const url = props.url
  let mediaIcon = ''
  switch(props.website) {
      case 'INSTAGRAM': mediaIcon = "icons8-instagram-144.png"; break;
      case 'YOUTUBE': mediaIcon = "icons8-youtube-144.png"; break;
      case 'FACEBOOK': mediaIcon = "icons8-facebook-144.png"; break;
      case 'TWITTER': mediaIcon = "icons8-twitter-144.png"; break;
      case 'TIKTOK': mediaIcon = "icons8-tiktok-144.png"; break;
      default: mediaIcon = "icons8-instagram-144.png";
  }
  return (
      <div className="socialCard">
      <img src={props.image} alt="" className="socialCard__img" />
      <div className="socialCard__body">
          <h4 className="socialCard__title">{props.accountName}</h4>
          <p className="socialCard__description">{props.description}</p>
          <a href={url} target="_blank" rel="noreferrer">
            <div className="socialCard__action_grid_container">
              <div className="socialCard__url">
                <img src={mediaIcon} alt="" className="socialCard__websiteIcon" />
              </div>
              <div>
                  <button className="socialCard__url" >View</button>
              </div>
            </div>
          </a>
      </div>
      </div>
  );
}

export default PageSocial
