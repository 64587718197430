import React, { useEffect, useRef, useState } from "react";
import { ArrivedCard } from "./busStatus/ArrivedCard";
import { FutureCard } from "./busStatus/FutureCard";
import { PastCard } from "./busStatus/PastCard";
import { SkeltonLoading } from './busStatus/Skelton'
import Switch from "react-switch";
import CircleIcon from "@mui/icons-material/Circle";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getBusStatus } from "../api/bus";
import { useGeoLocation } from "./busStatus/useGeoLocation";
import { isLoggedIn } from "../api/account";
import InvalidGPSAlert from "./busStatus/InvalidGPSAlert";
import { pushGA4CustomEvent } from "../Analytics";
import Fab from '@mui/material/Fab';
import ChatBubbleOutlineTwoToneIcon from '@mui/icons-material/ChatBubbleOutlineTwoTone';

export function BusStatus({ busId, setShowBusStatusPage, busInfo, openLoginAlertBox, gpsToggleChecked, setGpsToggleChecked ,updateBusForumPage}) {
  const [navCityColor, setNavCityColor] = useState("#800080");
  const [backgroundStatus, setBackgroundStatus] = useState("#3B3B3B")
  const [textStatusColor, setTextStatusColor] = useState("#FFFFFF")
  const [denied, setDenied] = useState(true)
  const [loading, setLoading] = useState(true)
  const [loadingRefresh, setLoadingRefresh] = useState(false)
  const [backgroundFuture, setBackgroundFuture] = useState("#FFFFFF");
  const [backgroundPast, setBackgroundPast] = useState("#eeeeee");
  const [backgroundArrivedColor, setBackgroundArrivedColor] = useState("#d5eefd");
  const [showGpsToggle, setShowGpsToggle] = useState(false);
  const [invalidGpsAlert, setInvalidGpsAlert] = useState(false);

  const invalidGpsAlertOpen = () => { setInvalidGpsAlert(true) };
  const invalidGpsAlertClose = () => { setInvalidGpsAlert(false) };
  useGeoLocation(busId, gpsToggleChecked, invalidGpsAlertOpen)
  
  setTimeout(() => {
    const user = isLoggedIn()
    if (!user) {
      openLoginAlertBox();
    }
  }, 10000)
  
  const [data, setData] = useState({
    isLive: null,
    pastData: [],
    futureData: [],
    currentData: [],
    busRunningStatus: null
  });

  function scrollView() {
    if(!data.pastData || data.pastData === [] ) return
    const mainRoot = document.getElementById("focusI");
    mainRoot?.scrollIntoView({ behavior: "smooth" });
  }
  
  useEffect(() => {
    async function fetchData() {
      await getBusStatus(busId)
        .then((data) => {
          setData({
            ...data,
            isLive: data.isLive,
            futureData: data.future,
            currentData: data.current,
            pastData: data.past,
            busRunningStatus: data.busRunningStatus
          });
          setLoading(false)
        })
        .catch((err) => console.log(err));
      navigator.geolocation.getCurrentPosition(
        position => {
          setDenied(false)
            // this function is called only if the user grant permission so here you can handle the granted state
        }, 
        error => {
          setDenied(true)
            // this function is called if the user denied permission or some other errors occurs
        } 
      );
      setTimeout(() => {
        setLoading(false)
        scrollView();
      }, 3000)
    }
    fetchData()
  }, []);

  useEffect(()=>{
    navigator.geolocation.getCurrentPosition(
      position => {
        setDenied(false)
          // this function is called only if the user grant permission so here you can handle the granted state
      }, 
      error => {
        setDenied(true)
          // this function is called if the user denied permission or some other errors occurs
      } 
    );
  })
  async function RefreshData(){
    setLoadingRefresh(true)
    await getBusStatus(busId)
    .then((data) => {
      setData({
        ...data,
        futureData: data.future,
        currentData: data.current,
        isLive: data.isLive,
        pastData: data.past,
        busRunningStatus: data.busRunningStatus
      });
    })
    .catch((err) => console.log(err));
    setTimeout(() => {
      setLoadingRefresh(false);
    },1000)
  }
  
  const isChangeToggle = (nextChecked) => {
    setGpsToggleChecked(nextChecked);
    let pageTitle = "GPS toggle checked"
    let pageUrl = "gpsToggleChecked"
    pushGA4CustomEvent(pageTitle,pageUrl)
  };
  const openBusForum = () => {
    updateBusForumPage(true,busId,busInfo)
    setShowBusStatusPage(false)
  }
  useEffect(() => {
    if(denied == true || (data.busRunningStatus === "NOT_STARTED" || data.busRunningStatus === "ENDED")) {
      setShowGpsToggle(false)
    } else {
      setShowGpsToggle(true)
    }
  }, [denied, data])


  return (
    <>
    <div>
      {console.log("Render")}
      <div className="div-fixed">
        <div style={{backgroundColor:navCityColor}} className="navbar-bus">
          <div className="icon-back">
            <div onClick={() => setShowBusStatusPage(false)}>
              <ArrowBackIcon width={50} htmlColor="#FFFFFF" />
            </div>
          </div>
          <div className="words">
            <span>{busInfo}</span>
          </div>
        </div>
        <div style={{background: backgroundStatus}} className="navbar-status">
          <div className="navbar-status2">
            <div className="icon-status">
              <CircleIcon htmlColor={data.isLive === true ? "#00FF00" : "#aaaaaa"} />
            </div>
            <div className="text-status">
              {data.isLive ? 
              (<div className="text-status-estimated">
              <h5 style={{color: textStatusColor}}>Live</h5>
              <h6 style={{color: textStatusColor}}>( Beta )</h6>
              </div>)
              : 
              (<div className="text-status-estimated">
                <h5 style={{color: textStatusColor}}>Estimated time</h5>
                <h6 style={{color: textStatusColor}}>( waiting for live data ... )</h6>
              </div>)
              }
            </div>
          </div>
        </div>
        <div className="nav-top-bus-co">
          <div className="nav-top-bus">
            <div className="nav-top-bus2">
              <div className="arrival">
                <span>Time</span>
              </div>
              <div className="station">
                <span>Bus Stop</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={!data.pastData ? 'nav-menu nav-menu-firstly' : 'nav-menu'}>
        {loading ? (<SkeltonLoading/>) : (
          <div>
          {data.pastData ? data.pastData.map((el, index) => (
            <PastCard
              name={el.name}
              name2={el.name.substring(0, 3).toUpperCase()}
              delay={el.delay}
              isDelay={el.isDelay}
              time={el.time}
              key={'past_'+index}
              backgroundColor={backgroundPast}
            />
          )): null }
          <div id="focusI"></div>
          {data.currentData ? data.currentData.map((el, index) => (
              <div key={index}>
              <ArrivedCard
                name={el.name}
                name2={el.name.substring(0, 3).toUpperCase()}
                delay={el.delay}
                isDelay={el.isDelay}
                time={el.time}
                arrivedTime={el.arrivedTime}
                RefreshData={RefreshData}
                loadingRefresh={loadingRefresh}
                key={'current_'+index}
                backgroundColor={backgroundArrivedColor}
              />
            </div>
          )) : null}
          {data.futureData ? data.futureData.map((el, index) => (
            <FutureCard
            name={el.name}
            name2={el.name.substring(0, 3).toUpperCase()}
            delay={el.delay}
            isDelay={el.isDelay}
            time={el.time}
            key={'future_'+index}
            background={backgroundFuture}
            />
          )):  null}
          </div>
        )}
        <div className="last-height"></div>
      </div>
      <Fab variant="extended" sx={{ mr: 1 }} style={{position:"fixed" , bottom:"12%" , right:"2%" , zIndex:"1" , textTransform:"capitalize" ,backgroundColor:"purple",color:"white" ,borderRadius:"10px 10px 10px 0px"}}  onClick={openBusForum} >
      <ChatBubbleOutlineTwoToneIcon style={{marginRight:"5px"}}/> 
      public discussion room
      </Fab>
      <div style={loading ? {pointerEvents:"none"} : null}  className="open-gps">
        <div style={!showGpsToggle ? {padding:"0px"} : null} className="text-gps">
          {
            gpsToggleChecked ? 
            <>
              <p>If you are not in bus, turn off GPS </p>
              <span>Reward countdown started. </span>
            </> 
            : 
            <div>
            {showGpsToggle ? <p>If you are on this Bus</p> : "" }
            {
              (data.busRunningStatus === "NOT_STARTED") 
              ? <span> <span>The bus is yet to depart from its origin.</span></span> : 
              (data.busRunningStatus === "ENDED") 
              ? <span> <span>The bus has reached its destination.</span></span> :
              (denied == true ? <span style={{fontSize:"16px"}}>Please allow GPS permission to get live status.</span> 
                : <span> <span>Turn on GPS to get live status.</span></span>)
            }
            </div>
          }
        </div>
        <div style={!showGpsToggle ? {display:"none"} : null} className="toggle-gps">
          <Switch
            id={document.dir == "ltr" ? "react-switch" : "react-switch-rtl"}
            activeBoxShadow={"0px 0px 1px 10px rgba(0, 0, 0, 0.2)"}
            width={52}
            uncheckedIcon={false}
            className="react-switch"
            handleDiameter={24}
            checkedIcon={false}
            onChange={isChangeToggle}
            checked={gpsToggleChecked}
          />
          <span id="rewardId" />
        </div>
      </div>
    </div>
    <InvalidGPSAlert open={invalidGpsAlert} invalidGpsAlertClose={invalidGpsAlertClose} toggleGPS={isChangeToggle}/>
    </>
  );
}

export default BusStatus
