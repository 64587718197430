
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import TextField from '@mui/material/TextField';
import { addStore } from '../api/content'
import { Checkbox } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Select from 'react-select';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PageAddStore = ({setRefresh, doAppBarRefresh}) => {
  useEffect(() => {
    if(doAppBarRefresh) setRefresh(true)
  })

  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const { control, handleSubmit } = useForm();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  
  const onSubmit = data => {
    setIsFormSubmitted(true)
    addStore({...data, businessType: data.businessType.value}).then((resp) => {
      setIsFormSubmitted(false)
      setOpen(true)
    })
    console.log(data);
  }

  return (
    <div className='addStoreWrapper'>
      <h2 className='card__title'>Add new business</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
      <div className='card__post_content' >
        <div className='card__post_content__input'>
        <Controller
            name="accountName" control={control} defaultValue="" 
            rules={{ required: true }}
            render={ ({ field }) => <TextField 
              required fullWidth size="small" label="Business Name" {...field} 
            /> }
        />
        </div>
        <div className='card__post_content__input'>
        <Controller
            name="description" control={control} defaultValue="" 
            rules={{ required: true }}
            render={ ({ field }) => <TextField 
              required fullWidth size="small" label="Description" {...field} 
            /> }
        />
        </div>
        <div className='card__post_content__input'>
        <Controller
            name="image" control={control} defaultValue="" 
            render={ ({ field }) => <TextField 
             fullWidth size="small" label="Image URL" {...field} 
            /> }
        />
        </div>
        <div className='card__post_content__input'>
        <Controller
            name="contact" control={control} defaultValue="" 
            render={ ({ field }) => <TextField 
              fullWidth size="small" label="Phone number" {...field} 
            /> }
        />
        </div>
        <div className='card__post_content__input'>
        <Controller
            name="location" control={control} defaultValue="" 
            rules={{ required: true }}
            render={ ({ field }) => <TextField 
              required fullWidth size="small" label="Maps location" {...field} 
            /> }
        />
        </div>
        <div className='card__post_content__input'>
          <Controller
            name="businessType"
            control={control}
            rules={{
              required: 'select an option'
            }}
            render={({ field }) => <Select required maxMenuHeight={140} placeholder="Business type"
              {...field} 
              options={[
                { value: "restaurant", label: "Restaurant" },
                { value: "pharmacy", label: "Pharmacy" },
                { value: "kirana", label: "Kirana Store" },
                { value: "supermarket", label: "Super Market" }
              ]} 
            />}
          />
        </div>
        <div className='card__post_content__input'>
          <label>Can the business do home delivery ? </label>
        <Controller
            name="isDeliverable" control={control} defaultValue={true} 
            render={ ({ field }) => <Checkbox
            {...field}
            checked={field.value}
            onChange={(e) => field.onChange(e.target.checked)}
          /> }
        />
        </div>
        <div className='card__post_content__input'>
        {isFormSubmitted && <CircularProgress /> }
        </div>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Store added successfully! We will verify the details and get back to you.
        </Alert>
        </Snackbar>
        <input className='card__post_content__btn' type="submit" />
      </div>
      </form>
    </div>
  )
}

export default PageAddStore;
