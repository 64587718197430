import React, { useEffect } from "react";
import ScratchCard from "react-scratchcard-v3";
import { Dialog, DialogContent } from "@mui/material";

const ScratchPopup = (props) => {
  const [scratchedCard, setScratchedCard] = React.useState(false);

  useEffect(() => {
    if (scratchedCard) {
      props.updateScratchCardScratched(props.id);
    }
  }, [scratchedCard]);

  return (
    <div>

      <Dialog
        open={props.open}
        onClose={props.handleScratchCardClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            minWidth: "250px",
            borderRadius: "1rem",
            backgroundColor: 'transparent'
          },
        }}
      >
        <DialogContent
          sx={{
            padding: "0px",
            touchAction: "none",
            borderRadius: "1rem",
          }}
        >
          <ScratchCard
            width={250}
            height={250}
            image={props.img}
            finishPercent={70}
            brushSize={26}
            onComplete={() => setScratchedCard(true)}
          >
            <div
              style={{
                display: "flex",
                width: "98%",
                height: "98%",
                alignItems: "center",
                justifyContent: "center",
                background: "white",
              }}
            >
              <h3>{props.text}</h3>
            </div>
          </ScratchCard>
          <span id="reward" ></span>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ScratchPopup;
