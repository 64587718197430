import React,{useEffect,useState} from 'react';
import ListOfStores from '../components/listOfStores';
import PAD from '../components/pickanddrop/PAD';
import { getLatestOrder } from '../api/delivery'
import Status from '../components/pickanddrop/Status';
import { Button, LinearProgress } from '@mui/material';
import { isLoggedIn } from '../api/account';
import { BOTTOM_NAVIGATION } from '../config/constant';

function PageDelivery (props) {
  const [isPAD , setIsPAD] = useState(false)
  const [latestOrder, setLatestOrder] = useState(null)
  const [showStatusComponent, setShowStatusComponent] = useState(false)
  const [deliveryDisabled, setDeliveryDisabled] = useState(true)
  const [progressBarOpen, setProgressBarOpen ] = useState(true)

  const enableDelivery = () => {
    if(isLoggedIn()) {
      setDeliveryDisabled(false)
    }
  }

  useEffect(() => {
    const user = isLoggedIn()
    const fetchData=async ()=>{
      let order = await getLatestOrder()
      setLatestOrder(order)  
      order && setShowStatusComponent(true)
      setProgressBarOpen(false)
      setIsPAD(true)
      }
    if(user) {
      fetchData();
    }
  },[]) 

  const pickUpButtonClick = () =>{
    setIsPAD(true)
  }

  const showPageDelivery = () => {
    // setIsPAD(false)
    props.updateBottomNavigationTab(BOTTOM_NAVIGATION.HOME)
  }

  const showOrderStatus = () => {
    setShowStatusComponent(true)
  }

  const showPAD = () => {
    setIsPAD(true)
    setShowStatusComponent(false)
  }
  return(
    // deliveryDisabled ?  
    //   <div className= "deliveryPageWrapper">
    //     <div className="profileContentCard" style={{ marginBottom: "1rem" }}>
    //       <div className="profileContentCard__body">
    //           <h4 className="profileContentCard__title"> Delivery is disabled because of high orders. Sorry for the inconvenience </h4>
    //       </div>
    //     </div>
    //     <ListOfStores />
    //     <Button onClick={enableDelivery} style={{ width: '1rem'}} ></Button> 
    //   </div>
    // :
    progressBarOpen ? <LinearProgress /> :
    showStatusComponent ? <Status order={latestOrder} showPAD={showPAD} /> :
    isPAD ?
    <PAD  showPageDelivery={showPageDelivery} /> : ''
    // :  (<div className= "wrapper">
    //     { latestOrder ? 
    //       (<div style={{width:"23rem",height:"10rem",backgroundColor:"white",borderRadius:"20px"}}>
    //           <button onClick={showOrderStatus}
    //             style={{ width: "10rem",color:"white",backgroundColor:"purple",height:"2.5rem",borderRadius:"10rem",marginTop:"3.8rem",marginLeft:"6.5rem"}}>
    //             Show Order Status</button>
    //         </div>)
    //       : 
    //       (<div style={{width:"23rem",height:"10rem",backgroundColor:"white",borderRadius:"20px"}}>
    //           <button onClick={pickUpButtonClick}
    //             style={{ width: "10rem",color:"white",backgroundColor:"purple",height:"2.5rem",borderRadius:"10rem",marginTop:"3.8rem",marginLeft:"6.5rem"}}>
    //             pickAnddrop</button>
    //         </div>)
    //     }
    //     <ListOfStores />
    //     </div>
    //   )
  )
}

export default PageDelivery ;
