import React, { useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ShortTextRoundedIcon from '@mui/icons-material/ShortTextRounded';
import NavigationIcon from '@mui/icons-material/Navigation';
import { pushGA4CustomEvent } from '../Analytics';
import { useRowSelect, useTable } from 'react-table'
import InfoModal from './InfoModel';
 
 function BusTimeTable(props) {

  const [infoModalProps, setInfoModalProps] = useState({open: false})

  function scrollView() {
    const mainRoot = document.getElementById("scrollToCurrentTime");
    mainRoot?.scrollIntoView({ behavior: "smooth" });
  }

  let now = new Date();
  let yesterdayMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  let secondsFromMidnight = (now.getTime() - yesterdayMidnight.getTime()) / 1000;

  const showModal = (busTime, busName, busRoute, busId) => {
     //analytics virtualpageview event

    let pageTitle = "Bus time table click"
    let pageUrl =  "onClickBustimeTable"
    let eventDetails =   `${busName}, ${busTime}, ${busId}, ${busRoute}`
    pushGA4CustomEvent(pageTitle,pageUrl,eventDetails)

    setInfoModalProps({
      title: `${busTime}  |  ${busName}`,
      message: `${busRoute}`,
      open: true,
      closeInfoModal,
      updateBusStatusPage: props.updateBusStatusPage,
      updateBusForumPage:props.updateBusForumPage,
      setForum: props.setForum,
      busId: busId,
      busInfo: `${busName} (${busRoute})`
    })
  }

  const closeInfoModal = () => {
    setInfoModalProps({
      open: false
    })
  }

   const columns = React.useMemo(
     () => [
       {
         Header: 'Time',
         accessor: 'busTime',
       },
       {
        Header: 'Bus',
        accessor: 'busName', // accessor is the "key" in the data
      },
      {
        Header: 'Live',
        accessor: 'routeName',
        Cell: (row) => {
          let scrollToHere = false
          if(row.row.original.rawTime > secondsFromMidnight) {
            scrollToHere = true
          }
          const isLive = props.busLiveList.find((busLive) => {
            return busLive.busId === row.row.original.busId
          });
          const iconColor = isLive ? "limegreen" : "grey"
          return (
            <div style={{ "display": "flex" }} id={ scrollToHere ? "scrollToCurrentTime" : row.row.original.rawTime} >
              <div className="bus_time_details_icon" >
                <NavigationIcon fontSize="small" color="primary" style={{ color: iconColor }}/>
              </div>
              { isLive ? 
              (<div style={{"fontSize" : "0.9rem", color: "black"}}>Live</div>) 
              : (<div style={{"fontSize" : "0.9rem", color: "grey"}}>View</div>)
              }
            </div>
          );
        }
      }
     ],
     []
   )

   const data = props.rows
 
   const {
     getTableBodyProps,
     headerGroups,
     rows,
     prepareRow,
   } = useTable({ columns, data }, useRowSelect)

   React.useEffect(() => {
    setTimeout(() => {
      scrollView()
    }, 2000);
   }, [props]);
 
   return (
    <TableContainer style={{ maxHeight: '25rem' }} component={Paper}>
        <Table aria-label="simple table">
       <TableHead >
         {headerGroups.map(headerGroup => (
           <TableRow {...headerGroup.getHeaderGroupProps()}>
             {headerGroup.headers.map(column => (
               <TableCell {...column.getHeaderProps()} variant='head' >
                 {column.render('Header')}
               </TableCell>
             ))}
            <TableCell />
           </TableRow>
         ))}
       </TableHead>
       <TableBody {...getTableBodyProps()}>
         { rows?.length ? rows.map((row, i) => {
           prepareRow(row)
           return (
              <TableRow {...row.getRowProps({ style: { borderBottom: "1px solid rgba(224, 224, 224, 1)"}})} 
                onClick={() => showModal(row.original.busTime, row.original.busName, row.original.routeName, row.original.busId)} >
               {row.cells.map(cell => {
                 return (
                   <TableCell {...cell.getCellProps({ style: { borderBottom: "none", padding: '0.5rem' }})} size='small' padding='none' variant='body' >
                     {cell.render('Cell')}
                   </TableCell>
                 )
               })
               }
               {/* <TableCell onClick={() => { 
                 props.reviewBusTime(row) }} size='small' padding='normal' variant='body' >
                 <ModeEditIcon size="small" style={{
                    color: "silver"
                }}/>
               </TableCell> */}
             </TableRow>
           )
         }) : 
            (
            <TableRow >
              <TableCell  >
              Coming soon...
              </TableCell>
            </TableRow>
          )
        }
       </TableBody>
    </Table>
    <InfoModal {...infoModalProps} />
    </TableContainer>
   )
 }

 export default BusTimeTable
