
import { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import Home from './pages/Home';
import ButtonAppBar from './components/appBar';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonIcon from '@mui/icons-material/Person';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import { DepartureBoard } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import { getLocalNotificationToken, isLoggedIn, saveAndSendNotificationToBackend } from './api/account';
import { Offline, Online } from "react-detect-offline";
import { getNotificationToken, onMessageListener } from './config/firebase';
import { Alert, Snackbar } from '@mui/material';
import { IS_PROD } from './config/constant';

function App() {
  const [refresh, setRefresh] = useState(false)
  const [bottomNavigationTab, setBottomNavigationTab] = useState(0);
  const [fullScreen, setFullScreen] = useState(false)
  const [notificationToken, setNotificationToken] = useState(false);
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState(false);
  const handleNotificationClose = (event, reason) => {
    setNotification(false);
  };

  const user = isLoggedIn()
  if(!IS_PROD) console.log('user',user)
  if(user) {
    const notificationToken = getLocalNotificationToken()
    if(!notificationToken) {
      getNotificationToken(saveAndSendNotificationToBackend, setNotificationToken);
    }
  }

  const displayFullScreen = (fullScreen) => {
    setFullScreen(fullScreen)
  }

  const updateBottomNavigationTab = (tab) => {
    setBottomNavigationTab(tab)
  }

  if(notificationToken) {
    onMessageListener().then(payload => {
      console.log('onMessageListener payload: ', payload)
      if(payload) {
        setShow(true);
        console.log(payload)
        setNotification({title: payload?.data?.title, body: payload?.data?.body})
      }
    }).catch(err => console.log('failed: ', err));  
  }
  
  const refreshPage = () => {
    setRefresh(!refresh)
    displayFullScreen(false)
  }

  return (
      <div>
      <Snackbar 
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleNotificationClose} open={notification}
        autoHideDuration={10000} 
        message={notification.body} >
      </Snackbar>

      { !fullScreen ? (
      <ButtonAppBar />
      ) : ''}
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home setRefresh={setRefresh} displayFullScreen={displayFullScreen} bottomNavigationTab={bottomNavigationTab}
        updateBottomNavigationTab={updateBottomNavigationTab} isFullScreen={fullScreen} refreshPage={refreshPage} />} />
      </Routes>
      </BrowserRouter>
      <Offline><div className="noInternet">Unable to connect to Internet...</div></Offline>
      { !fullScreen ? ( 
      <div>
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3} >
          <BottomNavigation
          sx={{
            '& .Mui-selected': {
              '& .MuiBottomNavigationAction-label': {
                fontSize: theme => theme.typography.caption,
                transition: 'none',
                fontWeight: 'bold',
                lineHeight: '20px'
              },
              '& .MuiSvgIcon-root, & .MuiBottomNavigationAction-label': {
                color: theme => theme.palette.secondary.main
              },
            },
            '& .MuiBottomNavigationAction-root': {
              minWidth: 0
            }
          }}
              showLabels
              value={bottomNavigationTab}
              onChange={(event, newValue) => {
                setBottomNavigationTab(newValue);
              }}
          >
              <BottomNavigationAction value="HOME" label="Home" icon={<HomeIcon />} />
              <BottomNavigationAction value="BUS" label="Bus" icon={<DepartureBoard />} />
              {/* <BottomNavigationAction value="DELIVERY" label="Delivery" icon={<LocationOnIcon />} /> */}
              <BottomNavigationAction value="USER_PROFILE" label="Account" icon={<AccountCircleIcon />} />
              {/* { user && user["custom:isWizard"] === "1" ? 
              (<BottomNavigationAction value="WIZARD" label="Wizard" icon={<DeliveryDiningIcon />} />) : '' } */}
              { user && user["custom:isAdmin"] === "1" ? 
              (<BottomNavigationAction value="ADMIN" label="Admin" icon={<AdminPanelSettingsIcon />} />) : '' }
          </BottomNavigation>
        </Paper>
      </div>
      ) : ''}
      </div>
  );
}

export default App;
