
import React, { } from 'react'
import HomeLoginPage from '../components/homepageuicomponents/homeLoginPage';
import GoogleLoginPage from '../components/homepageuicomponents/googleLoginPage';
import { useState } from 'react';
import { Box, LinearProgress } from '@mui/material';
const LoginHome = () => {


    const [pageNo, setPageNo] = useState(2);
    const [loader, setLoader] = useState(false)
    const changePage=(x)=>{
        setLoader(true)
        setTimeout(() => {
            setLoader(false)
            setPageNo(x);
        }, 1000);
    }
    if(pageNo===1){

        return ( 
        <>
        { loader ? 
         <Box sx={{ width: '100%' }}>
            <LinearProgress />
         </Box> : '' }
         <HomeLoginPage changePage={changePage} /> 
        </>
        )
    }else if(pageNo===2){

        return ( 
        <>
         <GoogleLoginPage changePage={changePage} /> 
        </>
        )
    }
}

export default LoginHome
