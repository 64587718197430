
import React, { useState, useEffect } from 'react';
import { getGroceries } from '../api/groceries'
import GroceriesTable from './groceriesTable'

function CardGroceries (props) {

    const [isDisplayContent, setIsDisplayContent] = useState(false)
    const [groceriesTable, setGroceriesTable] = useState(null)

    const displayContent = () => {
        console.log('view bus content clicked')
        setIsDisplayContent(!isDisplayContent)
    }

    useEffect(() => {
      async function populateGroceriesTableData(selectedBusStop, selectedBusRoute) {
        const groceriesTable = await getGroceries()
        console.log(groceriesTable)
        if(groceriesTable) setGroceriesTable(groceriesTable)
      }
      if (isDisplayContent) populateGroceriesTableData()
    }, [isDisplayContent])

    if(isDisplayContent) {
        return (
            <div className='card'>
            <div className='card__body'>
              <div className='card__grocery_content' style={{ backgroundImage: "url(undraw_festivities.svg)" }}>
                <div className='card__grocery_table'>
                  { groceriesTable ? <GroceriesTable rows={groceriesTable} /> : '' }
                </div>
                <p align="center">To buy, please check <b>Ask</b> Section</p>
              </div>
            </div>
          </div>
        )
    } else {
        return (
          <div className='card' onClick={displayContent} >
            <div className='card__body'>
              <img className='card__image' alt='' src={props.img} />
              <h2 className='card__title'>{props.title}</h2>
              <p className='card__description'>{props.description}</p>
            </div>
            <button className='card__btn' >View</button>
          </div>
        )
    }
}

export default CardGroceries;
