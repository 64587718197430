import React from "react"
import { useEffect,useState } from "react"
import MessageUi from './MessageUi'
import {getBusForumMessage,deleteForumMessage} from '../../api/bus'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LinearProgress, Typography } from "@mui/material";
import PostMessageBox from "./PostMessageBox";
import { isLoggedIn } from "../../api/account";


export default function Forum({busId ,setBusForumBusId ,busInfo, setShowBusForumPage}) {

   const user = isLoggedIn();
   const [navCityColor, setNavCityColor] = useState("#800080");
   const [ posts , setPosts ] = useState([])
   const [ updateMessage , setUpdateMessage ] = useState(0)
   const [ loader, setLoader ] = useState(false);
   function scrollView() {
    const mainRoot = document.getElementById("scrollToThisMessage");
    mainRoot?.scrollIntoView({ behavior: "smooth" });
   }

    useEffect(() => {
        async function fetchData() {
            setLoader(true)
            const msg = await getBusForumMessage(busId)
            setPosts(msg)
            setTimeout(() => {
                scrollView();
                setLoader(false);
            }, 1000)
        }
        fetchData()
    }, [updateMessage])

    const deleteMessage = async (messageId) => {
        setLoader(true)
        await deleteForumMessage(busId, messageId);
        setTimeout(() => {
            refreshMessages()
            setLoader(false)
        }, 1000)
    }

    const refreshMessages = () => {
        setUpdateMessage(updateMessage + 1)
    }


    return (
        <>
    <div className="forum_bg">
        <div style={{backgroundColor:navCityColor , borderRadius:"10px 10px 0 0", height:"8vh" }} className="navbar-bus">
            <div className="icon-back">
                <div onClick={() => setShowBusForumPage(false)} >
                <ArrowBackIcon width={50} htmlColor="#FFFFFF" />
                </div>
            </div>
            <div className="words">
                <span>{busInfo} | Public Discussion Room </span>
            </div>
        </div>
    
        <div style={{ height:"75vh", overflow:"auto" }}> 
            <div style={{ margin: "0.5rem"}}>
                {
                posts ? posts.filter(post => !post.isDeleted).map((post, index) => (
                    <div id={ posts.length-1 === index ? "scrollToThisMessage" : index }>
                        <MessageUi post={post} deleteMessage={deleteMessage} userId={user.sub} />
                    </div>    
                )) 
                : 
                <div style={{ margin: "1rem"}}>
                    <Typography variant="body2" color="text.secondary">
                        No messages here. Post a public message to start a discussion.
                    </Typography>
                </div>
                }
            </div> 
        </div>
        <div style={{ height:"2vh"}}> 
            { loader ? <LinearProgress /> : '' }
        </div>  
        <PostMessageBox busId={busId} refreshMessages={refreshMessages} setLoader={setLoader} />
    </div>
    </>
    )
}



