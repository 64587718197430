import { IconButton, ThemeProvider } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';

const LocationPin = ({ text, theme }) => (
  <ThemeProvider theme={theme}>
  <div className="pin">
    <IconButton  >
      <PlaceIcon fontSize="large" color="themePurple" className="locationMarkerIcon" />
    </IconButton>
    {/* <p className="pin-text">{text}</p> */}
  </div>
  </ThemeProvider>
)

export default LocationPin
