import * as React from 'react';
import Box from '@mui/material/Box';
import { useEffect,useState } from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import { pushGA4CustomEvent } from '../Analytics';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '21rem',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius: '0.5rem',
};

export default function InfoModal(props) {
  
  const showBusStatus = () => {
    props.updateBusStatusPage(true, props.busId, props.busInfo)
    let pageTitle = "Onclick Show Bus Status"
    let pageUrl = "onClickShowBusStatus"
    pushGA4CustomEvent(pageTitle,pageUrl)
  }

  const showBusForum = () => {
   props.updateBusForumPage(true, props.busId, props.busInfo)
    props.setForum(true);
  }

  return (
    <>
    <div>
      <Modal
        open={props.open}
        onClose={props.closeInfoModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
        <Box sx={style}>
          <div className="busInfoModalClose">
          <IconButton onClick={props.closeInfoModal} >
            <CloseIcon />
          </IconButton>
          </div>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {props.title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {props.message}
          </Typography>
          <div className="busInfoModal" >
            <Button variant="contained"  style={{borderRadius:"10px" ,backgroundColor:"purple" ,fontSize:"13px" ,textTransform: "lowercase" }}
              size="medium" onClick={showBusStatus} endIcon={<DirectionsBusIcon  />} 
            >
              Bus Status
            </Button>
            <Button variant="contained"  style={{borderRadius:"10px" ,backgroundColor:"purple" ,fontSize:"13px" ,textTransform: "lowercase" }}
              size="small" onClick={showBusForum} endIcon={<ForumOutlinedIcon  />} 
              
            >
              Discussion room
            </Button>
           
          </div>
        </Box>
        </div>
      </Modal>
    </div>
    </>
  );
}
