import { CircularProgress, Popper ,Button } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { pushGA4CustomEvent } from '../../Analytics'
import { getVerificationCode, logout ,isLoggedIn, refreshToken, sendOTPForMobile, verifyMobileOTP} from '../../api/account'
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { InputAdornment, TextField, Typography } from '@mui/material';

const SmsOTPPage = ({hideSmsOTPFlow, refreshPage}) => {

  const mobileNumberRef = useRef();

  const [verifyOTPFlow, setVerifyOTPFlow] = useState(false)

  const [mobileNumber, setMobileNumber] = useState(null)
  const [otp, setOtp] = React.useState('')

  const [sendOTPClicked, setSendOTPClicked] = useState(null)
  const [verifyOTPClicked, setVerifyOTPClicked] = useState(null)

  const [invalidOTP, setInvalidOTP] = useState(false)
  const [popper, setPopper] = React.useState(null);
  const [loader, setLoader] = React.useState(null);

  const handleOTPChange = (newValue) => {
    console.log('newValue',newValue.target.value)
    setOtp(newValue.target.value)
  }

  useEffect(() => {       
    const invokeSendOTPForMobile = async () => {
      console.log("you called me")
      let pageTitle = "OTP sent"
      let pageUrl = "otpSent"
      pushGA4CustomEvent(pageTitle,pageUrl)
      await sendOTPForMobile(mobileNumber)
      setSendOTPClicked(false)
    }
    if(mobileNumber && sendOTPClicked) invokeSendOTPForMobile();
  }, [mobileNumber, sendOTPClicked]) 

  useEffect(() => {       
    const invokeVerifyMobileOTP = async () => {
      setLoader(true)

      const verifyResponse = await verifyMobileOTP(mobileNumber, otp)
      setVerifyOTPClicked(false)

      if(verifyResponse) {
        await refreshToken();
        hideSmsOTPFlow()
        refreshPage()
      } else {
        setInvalidOTP(true)
      }
      setTimeout(() => {
        setLoader(false)
        setPopper(true);
      }, 2000);
      setTimeout(() => {
        setPopper(false);
        setOtp('')
      }, 6000);
    }
    if(mobileNumber && verifyOTPClicked) invokeVerifyMobileOTP();
  }, [otp, verifyOTPClicked]) 

  const sendOTP = () => {
    console.log("mobile", mobileNumberRef?.current?.value)
    setVerifyOTPFlow(true)
    setSendOTPClicked(true)
    setMobileNumber(mobileNumberRef?.current?.value)
  }

  const verifyOtp = () => {
    setVerifyOTPClicked(true)
  }


  return (
  <>
    <div className="loginPageBody" style={{height:"100vh", margin:"  auto",   overflow:"hidden",backgroundColor:"white", position:"relative" }}>
      <div onClick={hideSmsOTPFlow} style={{display:"flex", position:"absolute", top:"1rem",width:"90%", left:"20px", justifyContent:"space-between"}}>
        <div style={{cursor:"pointer"}}> <ArrowBackOutlinedIcon /> </div>
      </div>
      <div style={{height:"10vh",flexDirection:"column", backgroundColor:"white", display:"flex", justifyContent:"center", alignItems:"center"}}> 
      </div>      
      <div style={{height:"32vh",flexDirection:"column", backgroundColor:"white", display:"flex", justifyContent:"center", alignItems:"center"}}> 
        <img src="./OTP_0.svg" style={{width:"14rem", height:"14rem"}} alt="" />
      </div>                   

      <div style={{height:"15vh",flexDirection:"column", backgroundColor:"white", display:"flex", justifyContent:"center", alignItems:"center"}}> 
        <Typography variant="h5" gutterBottom>OTP Verification</Typography>
        <div style={{width:"20rem", display:"flex", justifyContent:"center", alignItems:"center", color:"#5d5b5b"}}>
        { verifyOTPFlow ?
         <Typography variant="body2" gutterBottom>
         Enter the OTP sent to <span style={{ color: "black", fontWeight: "bold"}}>+91-{mobileNumber}</span>
         </Typography>
         : 
         <Typography variant="body2" gutterBottom>
         We will send you a <span style={{ color: "black", fontWeight: "bold"}}>One Time Password</span> on this mobile number
         </Typography>
        }
        </div>
      </div> 

      <div style={{height:"10vh",flexDirection:"column", backgroundColor:"white", display:"flex", justifyContent:"center", alignItems:"center"}}> 
        { verifyOTPFlow ? 
          <TextField
          label="Enter OTP"
          id="otp"
          name="otp"
          sx={{ m: 1, width: '15ch' }}
          inputProps={{ maxLength: 6 }}
          onChange={handleOTPChange}
          value={otp}
        />
        :
          <TextField
            label="Enter mobile number"
            id="mobileNumber"
            name="mobileNumber"
            sx={{ m: 1, width: '25ch' }}
            inputRef={mobileNumberRef}
            InputProps={{
              startAdornment: <InputAdornment position="start">+91</InputAdornment>,
            }}
            inputProps={{ maxLength: 10 }}
          />
        }
      </div>

      { verifyOTPFlow ? 
        <div onClick={verifyOtp} style={{height:"10vh",flexDirection:"column", backgroundColor:"white", display:"flex", justifyContent:"center", alignItems:"center"}}> 
          <div className={loader ? 'button-2' : 'button-41' } style={{width:"20rem"}}>VERIFY</div>
        </div>
      : 
        <div onClick={sendOTP} style={{height:"10vh",flexDirection:"column", backgroundColor:"white", display:"flex", justifyContent:"center", alignItems:"center"}}> 
          <div className='button-41' style={{width:"20rem"}}> GET OTP </div>
        </div>
      }

      <div style={{height:"15vh",flexDirection:"column", backgroundColor:"white", display:"flex", justifyContent:"center", alignItems:"center"}}> 
        <div style={{marginTop:"10px", fontWeight:"bolder", cursor:"pointer"}}> 
          {loader && <CircularProgress fontSize="15px" />}
        </div>
        <div style={{paddingLeft:"1rem"}}>
          {popper && 
              <> The entered OTP is incorrect, Please try again.</> }
        </div>
      </div>   

      <div style={{height:"8vh",  backgroundColor:"white", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <div style={{cursor:"pointer",height:"40px",fontSize:"0.8rem", width:"90%", color:"grey", textAlign:"right",verticalAlign:"middle",lineHeight:"30px", borderRadius:"50px", paddingTop:'5px'}}>
              <a href="https://www.youtube.com/watch?v=t3v0-aYc0EQ&list=PLtASNhZjSPhDEMvQ1xbpI1ZW30Qzr8zK9" target="_blank" rel="noreferrer" style={{textDecoration: "none", marginLeft:"1rem", color: "dimgrey" }}>{"Videos"}</a>
              <a href="https://merchant.razorpay.com/policy/KU2Gb0PLZvEerc" target="_blank" rel="noreferrer" style={{textDecoration: "none", marginLeft:"1rem", color: "dimgrey" }}>{"Policies"}</a>
              <a href="https://mattool.in?page=tc" target="_blank" rel="noreferrer" style={{textDecoration: "none", marginLeft:"1rem", color: "dimgrey" }} >{"Terms"}</a>
              <a href="https://wa.me/918089783756?text=Hi Mattool" target="_blank" rel="noreferrer" style={{textDecoration: "none", marginLeft:"1rem", color: "dimgrey" }}>{"Help"}</a>
          </div>
      </div>         
    </div>
  </>
  )
}

export default SmsOTPPage






