import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListOfStores from '../../listOfStores';
import { getStores } from '../../../api/content';
import { Box, LinearProgress } from '@mui/material';
import { isLoggedIn } from '../../../api/account';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export default function MerchantAccordian({selectPickUpLocationFromStoreList}) {
  const [loading, setLoading] = React.useState(false);
  const [stores, setStores] = React.useState(null);
  const [pharmacies, setPharmacies] = React.useState([]);
  const [fishAndMeat, setFishAndMeat] = React.useState([]);
  const [localStores, setLocalStores] = React.useState([]);
  const [homeEntreprenuers, setHomeEntreprenuers] = React.useState([]);

  const user = isLoggedIn()

  React.useEffect(() => {
    async function fetchMerchants() {
      setLoading(true);
      let storesData = await getStores()
      setStores(storesData || [])
      if(storesData) {
        setPharmacies(storesData.filter((d) => d.businessType === 'pharmacy') || []);
        setFishAndMeat(storesData.filter((d) => d.businessType === 'fishAndMeat') || []);
        setLocalStores(storesData.filter((d) => d.businessType === 'store') || []);
        setHomeEntreprenuers(storesData.filter((d) => d.businessType === 'homeEntrepreneur') || []);
      }
      setLoading(false);
    }
    if(!stores) fetchMerchants()
  }, [])

  return (
    <div>
      { loading ? <Box sx={{ width: '100%' }}>
          <LinearProgress />
      </Box> : '' }
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography >Pharmacies</Typography>
        </AccordionSummary>
        <AccordionDetails>
          { pharmacies?.length ? 
            <ListOfStores selectPickUpLocationFromStoreList={selectPickUpLocationFromStoreList} stores={pharmacies} />
            : <Typography variant="subtitle2" > Coming soon... </Typography> 
          }
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography >Fish and Meat</Typography>
        </AccordionSummary>
        <AccordionDetails>
          { fishAndMeat?.length ? 
            <ListOfStores selectPickUpLocationFromStoreList={selectPickUpLocationFromStoreList} stores={fishAndMeat} />
            : <Typography variant="subtitle2" > Coming soon... </Typography> 
          }
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography >Stores</Typography>
        </AccordionSummary>
        <AccordionDetails>
          { localStores?.length ? 
            <ListOfStores selectPickUpLocationFromStoreList={selectPickUpLocationFromStoreList} stores={localStores} />
            : <Typography variant="subtitle2" > Coming soon... </Typography> 
          }
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography >Home Entrepreneurs</Typography>
        </AccordionSummary>
        <AccordionDetails>
        { homeEntreprenuers?.length ? 
            <ListOfStores selectPickUpLocationFromStoreList={selectPickUpLocationFromStoreList} stores={homeEntreprenuers} />
            : <Typography variant="subtitle2" > Coming soon... </Typography> 
          }
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography >Add Your Store Here</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div style = {{backgroundColor:"white", borderRadius:"10px"}}>
            <a  style={{ display: "flex", textAlign: "center", justifyContent: "center", textDecoration: "none",
              color: "green", padding: "0.3rem", borderRadius: "0.5rem", marginBottom: "0.5rem" }} 
            href={"https://wa.me/918089783756?text=" + user?.email + " " + user?.sub + " Send message to start converstation : "}> <WhatsAppIcon style={{ marginRight:"0.5rem" }}/>click to chat with us </a>
        </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
