import React, { useEffect,useState } from 'react'
import HistoryItem from './HistoryItem'
import {getwizardOrderHistory} from '../../api/wizard'

const WizardOrderHistory = () => { 

  const [orderHistory, setOrderHistory] = useState(false)
  useEffect(() => {
    const fetchData = async () => {
      let res = await getwizardOrderHistory();
      setOrderHistory(res?.reverse()) 
    }
    fetchData();
    console.log(orderHistory)
  }, []) 
  return (<>  <div style={{  margin:"15px auto" , width:"300px", height:"72vh", display:"flex", flexDirection:"column", rowGap:"15px",backgroundColor:"white",borderRadius:"15px ", padding:"20px", overflow:"auto" }}>
  {orderHistory && orderHistory.map((o)=>{ return <HistoryItem key={o.orderId} orderId={o.orderId}  date={o.date} pickUpAddress={o.pickUpAddress} dropAddress={o.dropAddress} status={o.status} type={o.type} description={o.description} amount={o.amount} /> 
  })}
    </div>
  </>
  )
}

export default WizardOrderHistory ; 










