import React, { useState } from "react"; 
import fromUnixTime from 'date-fns/fromUnixTime'
import format from "date-fns/format";
const HistoryItem = ({orderId, date, pickUpAddress, dropAddress, status, type, description, amount}) => {
  var d = new Date(date)
  
  const [dateAndTime, setDateAndTime] = useState(false)
 
  const result = format(d, "dd MMM yyyy hh:mm aa")

  if(!dateAndTime){
    setDateAndTime(result)
    }

  return (
    <>
    <div  style={{  borderBottom:"2px solid #bcbcbc",   paddingBottom:"15px",  cursor:"pointer" }}>
      <div style={{   display:"flex",justifyContent:"space-between", }}>
        <div style={{fontWeight:"bold", color:"#2e2e2e", fontSize:"14px", margin:"3px 0px"  }}>   {type}</div>
          <div style={{display:"flex", justifyContent:"center", alignItems:"center", columnGap:"2px"}}>
            <div style={{fontWeight:"bold", fontSize:"12px", color:"#808080"  }}>{ status } </div>
            <img style={{width:"18px", height:"18px"}} src="/tickmarkIcon.svg" alt="" />
          </div>
      </div>
      <div style={{display:"flex",   alignItems:"center", columnGap:"8px", margin:"3px 0px"}}>
        <div style={{fontWeight:"bold" , fontSize:"12px", color:"#808080"}}>{`${pickUpAddress.name || pickUpAddress.address?.substring(0, 20)}...`}</div> <img style={{width:"12px", height:"12px", opacity:"0.5"}} src="/rightArrow.png" alt="" /> 
        <div style={{fontWeight:"bold" , fontSize:"12px", color:"#808080"}}>{`${dropAddress.name || dropAddress.address?.substring(0, 20)}...`}</div>
      </div> 
      <div style={{fontWeight:"bold" , fontSize:"12px", color:"#808080",  paddingBottom:"8px", borderBottom:"2px dotted #bcbcbc", margin:"5px 0px" }}> ₹ {amount}</div>
      <div style={{fontWeight:"bold" , fontSize:"13px", color:"#808080", margin:"5px 0px"}}>{description}</div>
      <div style={{fontWeight:"bold", fontSize:"12px", color:"#9e9e9e",margin:"5px 0px" }}> {dateAndTime}</div>    
  </div>
    </>
  );
};

export default HistoryItem;
