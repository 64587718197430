
import axios from 'axios'
import { SERVICE_API_URL } from '../config/constant'

export const redeemReward = async (rewardId) => {
    const response = await axios.post(SERVICE_API_URL+'/user/redeemReward', {
        rewardId
    });
    return response.data
}

export const getUserProfile = async () => {
    const response = await axios.get(SERVICE_API_URL+'/user/profile');
    return response.data
}

export const updatePushNotificationToken = async (pushNotificationToken) => {
    const response = await axios.put(SERVICE_API_URL+'/user/pushNotificationDeviceId', {
        pushNotificationDeviceId: pushNotificationToken
    });
    return response.data
}

export const getOrderHistory = async () => {
    let orderHistoryResponse = await axios.get(SERVICE_API_URL+ '/user/userOrderHistory');
    return orderHistoryResponse.data;
};
