import React from 'react';
import BlogContentPetStations from '../components/blog/BlogContentPetStations';

const PageBlog = ({content}) => {
      switch(content) {
           case 'petstation': {
             return <BlogContentPetStations />
           }
           default: {
             return (
                  <div style={{ marginBottom: "5rem", fontSize: "larger"}}>
                  <div style={{ background:'white', padding: '0.5rem', marginBottom: '4rem', borderRadius: '0.5rem'}}>
                        <h4><a href="https://mattool.in" >mattool.in</a></h4>
                        <h3 dir="auto">Live bus time</h3><p dir="auto">We keep track of all buses running in Mattool and share this information with commuters. We also have an option of live bus feature through the app so that they know when buses will arrive. The commuters have an option to help fellow commuters to tell them where the bus and earn rewards.</p><p dir="auto">It provides commuters with information about the route, stops and time left for the bus to arrive at the stop and its current location. This information is updated every 10 sec. Live bus time is a way for commuters to know when the next bus will arrive at their stop and plan accordingly.</p>
                        <h3 dir="auto">Local pick up &amp; delivery</h3><p dir="auto">No matter where you are in Mattool, we will get your things delivered to wherever they need to be with the help of Mattool delivery Wizard. We do pickup &amp; drop-off of items as well as deliveries. And if you're living in Mattool then it's an absolute no-brainer! that you use Mattool app.</p>
                  </div>
                  </div>
            )
           }
      }
}

export default PageBlog
