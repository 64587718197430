
import { List, ListItem } from '@mui/material';

import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'; 
import { PAYMENT_TYPE } from '../../config/constant';

export default function PaymentRadioButton({ paymentType, setPaymentType }) {

  const handleChange = (event) => {
    setPaymentType(event.target.value)
  };

  return (
  <div>
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={paymentType}
        onChange={handleChange}
      >
        <List>
          <ListItem style={{display: "grid", gridTemplateColumns: "4fr 1fr", paddingTop: 0, paddingBottom: 0 }}>
            <FormControlLabel value={PAYMENT_TYPE.COD} control={<Radio />} label=" Cash On Delivery" sx={{ 
              '.MuiFormControlLabel-label': {
                paddingLeft: '0.5rem'
              }
             }} />
            { paymentType === PAYMENT_TYPE.COD ? <CheckCircleRoundedIcon style={{color:"green"}}/> : "" }
          </ListItem>
          <ListItem style={{display: "grid", gridTemplateColumns: "4fr 1fr", paddingTop: 0, paddingBottom: 0 }}>
            <FormControlLabel value={PAYMENT_TYPE.ONLINE} control={<Radio />} label=" Online Payment" sx={{ 
              '.MuiFormControlLabel-label': {
                paddingLeft: '0.5rem'
              }
             }}/>
            { paymentType === PAYMENT_TYPE.ONLINE ? <CheckCircleRoundedIcon style={{color:"green"}}/> : "" } 
          </ListItem>
        </List>
      </RadioGroup>
    </FormControl>
  </div>
  );
}