
import { List, ListItem } from '@mui/material';

import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { ORDER_VEHICLE_TYPE } from '../../config/constant';

export default function VehicleRadioButton({ orderVehicleType, setOrderVehicleType, prices, setTotal, setAmount, vehicleAvailability, cancelAppliedCoupon }) {

  const handleChange = (event) => {
    setOrderVehicleType(event.target.value)
    const newTotalPrice = event.target.value === ORDER_VEHICLE_TYPE.THREE_WHEELER ? prices.threeWheeler : prices.twoWheeler
    setTotal(newTotalPrice)
    cancelAppliedCoupon()
  };

  return (
  <div>
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={orderVehicleType}
        onChange={handleChange}
      >
        <List>
          <ListItem style={{display: "grid", gridTemplateColumns: "4fr 1fr 1fr", paddingTop: 0, paddingBottom: 0}}>
            <FormControlLabel value={ORDER_VEHICLE_TYPE.THREE_WHEELER} control={<Radio />} label={ vehicleAvailability.threeWheeler ? "Auto" : "Auto (Unavailable)"} sx={{ 
              '.MuiFormControlLabel-label': {
                paddingLeft: '0.5rem'
              }
             }}  disabled={vehicleAvailability.threeWheeler ? false : true} />
             <div>
              <img src="vehicle_rickshaw.png" alt="rickshaw" style={{width: "50%"}}/>
             </div>
             <div>
             ₹ {prices.threeWheeler}
            </div>
          </ListItem>
          <ListItem style={{display: "grid", gridTemplateColumns: "4fr 1fr 1fr", paddingTop: 0, paddingBottom: 0 }}>
            <FormControlLabel value={ORDER_VEHICLE_TYPE.TWO_WHEELER} control={<Radio />} label={ vehicleAvailability.twoWheeler ? "Bike" : "Bike (Unavailable)"} sx={{ 
              '.MuiFormControlLabel-label': {
                paddingLeft: '0.5rem'
              }
             }} disabled={vehicleAvailability.twoWheeler ? false : true} />
             <div>
              <img src="vehicle_motorbike.png" alt="bike" style={{width: "50%"}}/>
             </div>
             <div style={{ color: vehicleAvailability.twoWheeler ? 'black' : 'dimgrey'}}>
             ₹ {prices.twoWheeler}
             </div>
          </ListItem>
        </List>
      </RadioGroup>
    </FormControl>
  </div>
  );
}