import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { APP_DOMAIN } from '../../config/constant'
import ScratchProfile from '../../components/scratchCard/ScratchProfile';
import { getUserProfile } from "../../api/user";
import { Chip, LinearProgress } from '@mui/material';
import { isLoggedIn } from '../../api/account';

const Rewards = (props) => {

    const [data, setData] = React.useState("");
    const [childScratch, setChildScratch] = React.useState(false);
    const [loggedIn, setLoggedIn] = React.useState(false);
  
    useEffect(() => {
      const user = isLoggedIn()
      const invokeGetUserProfile = async () => {
        const userProfileResponse = await getUserProfile()
        setLoggedIn(true)
        userProfileResponse?.userProfile?.rewards?.reverse()
        setData(userProfileResponse?.userProfile)
      }
      if(user) {
        invokeGetUserProfile()
      } else {
        props.navigateToLoginScreen()
      }
      console.log("render");
    }, [childScratch]);
    
  return (
    !loggedIn ? <LinearProgress /> :
    <div className="userProfileWrapper">
      <div className="profileContentCard">
        <div className="profileContentCard__body">
            <h4 className="profileContentCard__title">Total Points Earned <Chip label={data.balance || 0} /></h4>
        </div>
      </div>
      { data.rewards ? 
          <div style={{
                "marginTop": "1rem",
                "justifyContent": "center",
                "display": "grid"
                }}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              flexDirection: "row",
              columnGap: "15px",
              rowGap:"1rem",
              justifyContent: "center",
            }}
          >
            <>
            {Array.from(data.rewards).map((el) => (
              <ScratchProfile
                key={el.id}
                id={el.id}
                text={el.text}
                img="scratch_card_2.jpg"
                scratched={el.opened}
                render={childScratch}
                reRender={setChildScratch}
              />
            ))
            }
            </>
          </Box>
          </div>
          :   
          <div style={{ "marginTop": "1rem" }}>
            <div className="profileContentCard" style={{ background: "lightgrey"}}>
              <div className="profileContentCard__body" style={{ background: "lightgrey"}}>
              <h5 className="profileContentCard__title" >Earn scratch cards by using Live Bus feature.</h5>
              </div>
            </div>
          </div>
      }
    </div> 
  )
}

export default Rewards