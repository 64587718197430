import React, { useState } from 'react';
import Bus from './cardBus'
import ShowBus from './cardShowBus'
import Contact from './cardContact'
import Post from './cardPost'
import Grocery from './cardGroceries'
import Social from './cardSocial'
import Delivery from './cardDelivery'
import WallPost from './cardWallPost'
import DetectPlatform from './detectDevice/DetectPlatform'

function Card (props) {
    
    const [isDisplayContent, setIsDisplayContent] = useState(false);

    const displayContent = () => {
        setIsDisplayContent(!isDisplayContent)
    }

    switch (props.type) {
      case 'contact' : return (<Contact {...props} />)
      case 'bus' :  return (<Bus {...props} />)
      case 'showBus' :  return (<ShowBus {...props} />)
      case 'social': return (<Social {...props} />)
      case 'delivery': return (<Delivery {...props} />)
      case 'grocery': return (<Grocery {...props} />)
      case 'post' :  return (<Post {...props} />)
      case 'wallPost' :  return (<WallPost {...props} />)
      case 'detectplatform' : return (<DetectPlatform/>)
      default: {
          if(isDisplayContent) {
            return (
                <div className='card'>
                <div className='card__body'>
                  <img className='card__image' alt='' src={props.img} />
                  <div className='card__title'>{props.title}</div>
                  <div className='card__description'>Coming soon...</div>
                </div>
                <button className='card__btn' onClick={displayContent} >Go back</button>
              </div>
            )
        } else {
            return (
                <div className='card'>
                <div className='card__body'>
                  <img className='card__image' alt='' src={props.img} />
                  <div className='card__title'>{props.title}</div>
                  <div className='card__description'>{props.description}</div>
                </div>
                <button className='card__btn' onClick={displayContent} >View</button>
              </div>
            )
        }
      }
    }
}

export default Card
