import React, { useEffect, useRef, useState } from "react";
import { useReward } from "react-rewards";
import Countdown from 'react-countdown';
import BusStatus from "./BusStatus";
import ScratchPopup from "./scratchCard/ScratchPopup";
import { Chip } from "@mui/material";
import { busGPSRewardVerify } from "../api/bus";
import { redeemReward } from "../api/user";
import { pushGA4CustomEvent } from "../Analytics";


export function BusStatusWrapper({ busId, setShowBusStatusPage, busInfo, openLoginAlertBox ,updateBusForumPage}) {
  const [gpsToggleChecked, setGpsToggleChecked] = useState(false)
  const [gpsSessionCompleted, setGpsSessionCompleted] = useState(0)
  const [scratchCardOpen, setScratchCardOpen] = useState(false)
  const [scratchCardProps, setScratchCardProps] = useState({})
  const { reward } = useReward("rewardId", "confetti", {
    zIndex: 2000,
  });

  const handleScratchCardOpen = () => { setScratchCardOpen(true); };
  const handleScratchCardClose = () => { setScratchCardOpen(false); };
  const updateScratchCardScratched = (id) => {
    console.log("id", id)
    redeemReward(id)
    reward();
    let pageTitle = "card scratched"
    let pageUrl = "scratchcardscratched"
    pushGA4CustomEvent(pageTitle,pageUrl)
  }

  useEffect(() => {
    const invokeVerifyReward = async () => {
      //analytics 
      let pageTitle = "gps session complete"
      let pageUrl = "gpsSessionComplete"
      pushGA4CustomEvent(pageTitle,pageUrl)
      console.log("Call Reward Verify API")
      const newReward = await busGPSRewardVerify() //{ reward: {id: '123', text: 'You have earned 5 Points !'}} // 
      if(newReward.reward.id) {
        setScratchCardProps({
          id: newReward.reward.id,
          text: newReward.reward.text,
          img: "scratch_card_2.jpg"
        })
        reward();
        handleScratchCardOpen()
      } else {
        console.log("You are not eligible for reward")
      }
    }
    if(gpsSessionCompleted > 0) invokeVerifyReward()
  }, [gpsSessionCompleted])

  const counterOnComplete = () => {
    setGpsSessionCompleted(gpsSessionCompleted+1)
  };

  const counterRenderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return <span>(waiting...)</span>;
    } else {
      // Render a countdown
      return <span>{minutes}:{seconds}</span>;
    }
  };
  
  return (
    <> 
      <BusStatus setShowBusStatusPage={setShowBusStatusPage} busId={busId} busInfo={busInfo} openLoginAlertBox={openLoginAlertBox} 
        gpsToggleChecked={gpsToggleChecked} setGpsToggleChecked={setGpsToggleChecked} updateBusForumPage={updateBusForumPage}/>

      { gpsToggleChecked ? 
          (<div className="open-gps-counter">
          <Chip label={<Countdown date={Date.now() + 300000} renderer={counterRenderer} 
          key={gpsSessionCompleted} onComplete={counterOnComplete} />} />
        </div>) : ''
      }
      { scratchCardOpen ? 
      <ScratchPopup {...scratchCardProps} open={scratchCardOpen}
      handleScratchCardClose={handleScratchCardClose} updateScratchCardScratched={updateScratchCardScratched} /> 
      : '' }
    </>
  );
}

export default BusStatusWrapper
