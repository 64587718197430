import React, { useState } from 'react';
import { APP_DOMAIN } from '../config/constant'
import Rewards from '../components/userAccount/Rewards';
import History from '../components/userAccount/history';
import { isLoggedIn } from '../api/account';

const Profile = (props) => {
  const [tab, setTab] = useState(1);

  const user = isLoggedIn()

  return (
    <>
    <div style={{display:"flex"  ,   margin:"10px auto" , width:"350px",justifyContent:"space-around"}}>
    <div onClick={()=>setTab(1)} style={{fontSize:"15px", fontWeight:"bold", borderRadius:"20px", padding:"10px 15px", cursor:"pointer", backgroundColor:tab===1?"white":"", color:tab===1?"#800080":"", boxShadow:tab===1?"0px 2px 2px gray":""}}>Rewards</div>
    <div onClick={()=>setTab(2)} style={{fontSize:"15px", fontWeight:"bold", borderRadius:"20px", padding:"10px 15px", cursor:"pointer", backgroundColor:tab===2?"white":"", color:tab===2?"#800080":"", boxShadow:tab===2?"0px 2px 2px gray":""}}>Orders</div>
    <div onClick={()=>setTab(3)} style={{fontSize:"15px", fontWeight:"bold", borderRadius:"20px", padding:"10px 15px", cursor:"pointer", backgroundColor:tab===3?"white":"", color:tab===3?"#800080":"", boxShadow:tab===3?"0px 2px 2px gray":""}}>Profile</div>
    </div>
    {tab===1 && <Rewards navigateToLoginScreen={navigateToLoginScreen}/>}
    {tab===2 && <History/>}
    {tab===3 && 
      <div> 
        <div style={{ margin:"15px auto" , width:"300px", height:"72vh", display:"flex", flexDirection:"column", rowGap:"15px",backgroundColor:"white",borderRadius:"15px ", padding:"20px", overflow:"auto" }}>
          <div style={{display:"flex", alignItems:"center",}}>
            <div style={{fontSize:"15px", fontWeight:"bold"}}>Name</div>
            <div style={{fontSize:"15px", fontWeight:"light", marginLeft: "1rem"}}>{user.name}</div>
          </div>
          <div style={{display:"flex", alignItems:"center",}}>
            <div style={{fontSize:"15px", fontWeight:"bold"}}>Email</div>
            <div style={{fontSize:"15px", fontWeight:"light",  marginLeft: "1rem"}}>{user.email}</div>
          </div>
          <div style={{display:"flex", alignItems:"center",}}>
            <div style={{fontSize:"15px", fontWeight:"bold"}}>Account</div>
            <div style={{fontSize:"15px", fontWeight:"light",  marginLeft: "1rem"}}>Verified</div>
          </div>
        </div>
      </div>}
    </>
  )
}

export const navigateToLoginScreen = (redirectToPage) => {

  const url = "https://mattool-live.auth.ap-south-1.amazoncognito.com/oauth2/authorize?identity_provider=Google&client_id=cibbf1272hbmenfm4pchh6oph&response_type=code&scope=email+openid+phone+profile&redirect_uri="
  + APP_DOMAIN + (redirectToPage ? ("?page=" + redirectToPage) : '')

  // const url = "https://mattool-live.auth.ap-south-1.amazoncognito.com/login?client_id=cibbf1272hbmenfm4pchh6oph&response_type=code&scope=email+openid+phone+profile&redirect_uri="
  // + APP_DOMAIN + (redirectToPage ? ("?page=" + redirectToPage) : '')
  window.open(url, '_self')
}

export default Profile