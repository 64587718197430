
import axios from 'axios'
import { place, SERVICE_API_URL } from '../config/constant'

export const getWizardProfile = async () => {
    const response = await axios.get(SERVICE_API_URL+`/wizard`, { params: {
        place
    }});
    return response.data
}

export const getDeliveryWizardAssignedOrder = async () => {
    const response = await axios.get(SERVICE_API_URL+`/wizard/assignedOrder`, { params: {
        place
    }});
    return response.data
}

export const getDeliveryWizardOneTimeOrder = async (orderId) => {
    const response = await axios.get(SERVICE_API_URL+`/wizard/oneTimeOrder`, { params: {
        place, orderId
    }});
    return response.data
}

export const updateOrder = async (data) => {
    try {
        const response = await axios.post(SERVICE_API_URL+'/wizard/order/update', data)
        return response.data
    } catch (e) {
        console.error(e)
        return null
    }
}

export const updateWizard = async (data) => {
    try {
        const response = await axios.put(SERVICE_API_URL+'/wizard', data)
        return response.data
    } catch (e) {
        console.error(e)
        return null
    }
}

export const getwizardOrderHistory = async () => {
    let wizardHistoryResponse = await axios.get(SERVICE_API_URL + '/wizard/wizardOrders');
    return wizardHistoryResponse.data
}
