import { Alert, Box, Button, createTheme, Fab, IconButton, LinearProgress, Snackbar, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Controller, useForm } from 'react-hook-form';
import { adminUploadRouteGPS, adminUploadBusStopGPS } from '../api/bus'
import DeliveryAdmin from '../components/admin/DeliveryAdmin';
import VerificationAdmin from '../components/admin/VerificationAdmin'

const theme = createTheme({
  palette: {
    themePurple: {
      main: '#800080',
      darker: '#3C005A',
    },
  },
});

const PageAdminUser = (props) => {
  const [disableBusStopButtonTag, setDisableBusStopButtonTag] = useState({ disabled: true });
  const [startGpsUploading, setStartGpsUploading] = useState(false)
  const [intervalId, setIntervalId] = useState(null)
  const [stopUploading, setStopUploading] = useState(false)
  const [busStopInputVisible, setBusStopInputVisible] = useState(false)
  const [route, setRoute] = useState(null)
  const [gpsInfo, setGpsInfo] = useState(null)

  useEffect(()=>{
    navigator.geolocation.getCurrentPosition(
      position => { console.log('page load', position) }, 
      error => { console.log('GPS permission denied')} 
    );
  }, [])

  useEffect(()=>{
    console.log('inside 2nd use Affect  ')
    if(startGpsUploading && !intervalId) {
      const id = setInterval(() => {
        console.log('inside setInterval start ' + startGpsUploading)
        navigator.geolocation.getCurrentPosition(
          position => {uploadGPS(position)},
          error => {console.log('GPS permission denied')} );
      },2000);
      setIntervalId(id)
      setDisableBusStopButtonTag({})
    }

    if(stopUploading) {
      console.log('inside interval clear ' + startGpsUploading)
      clearInterval(intervalId)
      setIntervalId(null)
      setStartGpsUploading(false)
      setGpsInfo(null)
      setDisableBusStopButtonTag({ disabled: true })
      setStopUploading(false)
    }
  
    return () => {clearInterval(intervalId)}
  }, [intervalId, startGpsUploading, stopUploading])


  const { control, handleSubmit } = useForm();
  const { control: busStopControl, handleSubmit: busStopHandleSubmit } = useForm();

  const uploadGPS = (position) => {
    if(position) {
      const gpsInfo = {
        route,
        accuracy: position.coords.accuracy,
        lat: position.coords.latitude,
        long: position.coords.longitude,
        speed: position.coords.speed,
        timestamp: position.timestamp
      }
      setGpsInfo(gpsInfo)
      adminUploadRouteGPS(gpsInfo)
    }
  }
  
  const toggleGpsDataCapture = data => { 
    if(!startGpsUploading) {
      setRoute(data.route)
      setStartGpsUploading(true)
    } else {
      setStopUploading(true)
    }
  }

  const addBusStop = data => {
    console.log(data)
    console.log('call API to store bus stop')
    setBusStopInputVisible(false)
    adminUploadBusStopGPS({ gpsInfo, busStop: data.busStop})
  }

  return (
    <div className="userPageWrapper" style={{ marginBottom: "4rem"}} >
      <div className='card'>
      <div className='card__body'>
      { startGpsUploading ? 
          (<Box sx={{ width: '100%' }} >
            <LinearProgress />
          </Box>) : '' }
        <div className='card__title'>{ startGpsUploading ? 'Uploading...' : 'Upload Route GPS'}</div>
        <div className='card__post_content, card__admin_content' >
        <form onSubmit={handleSubmit(toggleGpsDataCapture)}>
        { startGpsUploading ? '' : 
          (<div className='card__post_content__input'>
            <Controller
              name="route"
              control={control}
              defaultValue=""
              rules={{
                required: true
              }}
              render={({ field }) => <TextField
                required
                fullWidth size="medium"
                label="Enter Route (eg: mattool - pazhayangadi - kannur)" {...field} 
                inputProps={{ maxLength: 50 }}
              />
              }
            />
          </div>) }
          <input className='card__post_content__btn' type="submit" value={startGpsUploading ? 'Stop Uploading GPS' : 'Start Uploading GPS'}/>
          </form>
          { gpsInfo ? (
          <Box sx={{ p: 2, border: '1px dashed grey' }}>
            <div className='card__post_content__description'>{JSON.stringify(gpsInfo)}</div>
          </Box>) : ''}
          <div className='card__post_content__input'>
            <Fab color="secondary" aria-label="add" variant="extended" {...disableBusStopButtonTag} onClick={() => { setBusStopInputVisible(true)}} >
              <AddIcon /> Add Bus Stop
            </Fab>
          </div>
          { busStopInputVisible ?
          (<form onSubmit={busStopHandleSubmit(addBusStop)} className="card__admin_content_busStop_form">
            <div className='card__post_content__input'>
            <Controller
              name="busStop"
              control={busStopControl}
              defaultValue=""
              rules={{
                required: true
              }}
              render={({ field }) => <TextField
                required
                fullWidth size="medium"
                label="Enter Bus Stop" {...field} 
                inputProps={{ maxLength: 25}}
              />
              }
            />
            </div>
            <input className='card__post_content__btn' type="submit" value={'Add Bus stop'}/>
           </form>
          ) : ''}
        </div>
      </div>
      </div>
      <div style={{ marginTop: "0.5rem"}}>
        <DeliveryAdmin />
      </div>
      <div style={{ marginTop: "0.5rem"}}>
        <VerificationAdmin />
      </div>
    </div>

    )
}

export default PageAdminUser
