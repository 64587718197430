import { useEffect, useState } from "react";
import { createRazorPayOrder } from "../../api/delivery";
import PaymentFailure from "./PaymentFailure";
import PaymentSuccess from "./PaymentSuccess";
import Loading from "./Loading";
import { isLoggedIn } from "../../api/account";

// load razor pay
function loadRazarPay() {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

async function displayRazorpay(razorPayOrder, orderPaymentSuccess, orderPaymentFailed, setSuccess, setFailure, user) {
    const razorPaySDK = await loadRazarPay()
    if(!razorPaySDK) console.log('Razor pay not loaded')

    console.log("razorPayOrder", razorPayOrder)

    const options = {
        "key": razorPayOrder.key, // Enter the Key ID generated from the Dashboard
        "amount": razorPayOrder.price?.toString(), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        "currency": "INR",
        "name": "Delivery",
        "description": "Pickup and delivery",
        "image": "https://mattool.in/logo_white_512.png",
        "order_id": razorPayOrder.razorPayOrderId, 
        "remember_customer": true,
        "prefill": {
            "name": user?.name,
            "email": user?.email,
            "contact": user?.phone_number?.substring(3)
        },
        "handler": function (response) {
            console.log('payment response', response)
            setSuccess(true)
            setTimeout(() => {
                orderPaymentSuccess()
            }, 3000)
        },
        "theme": {
            "color": "#800080"
        }
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.on('payment.failed', function (response) {
        console.log('payment response', response)
        // setFailure(true)
        // setTimeout(() => {
        //     orderPaymentFailed()
        // }, 3000)
    });
    paymentObject.open()
}

function RazorPay({ order, orderPaymentSuccess, orderPaymentFailed }) {

    const [pay, setPay] = useState(true)
    const [success, setSuccess] = useState(false)
    const [failure, setFailure] = useState(false)
    const [loading, setLoading] = useState(false)

    const user = isLoggedIn()

    useEffect(() => {
        if (pay) {
            displayRazorpay(order, orderPaymentSuccess, orderPaymentFailed, setSuccess, setFailure, user)
            setPay(false)
        }
    }, [pay])

    return <div>
        { success ? <PaymentSuccess /> : '' }
        { failure ? <PaymentFailure /> : '' }
        { loading ? <Loading /> : '' }
    </div>

}

export default RazorPay
