import React, { useState } from 'react';
import { APP_DOMAIN } from '../config/constant'
import WizardAdmin from '../components/wizard/WizardAdmin';
import WizardOrderHistory from '../components/wizard/wizardOrderHistory';

const PageWizard = (props) => {
  const [tab, setTab] = useState(1);

  return (
    <>
    <div style={{display:"flex"  ,   margin:"10px auto" , width:"350px",justifyContent:"space-around"}}>
    <div onClick={()=>setTab(1)} style={{fontSize:"15px", fontWeight:"bold", borderRadius:"20px", padding:"10px 15px", cursor:"pointer", backgroundColor:tab===1?"white":"", color:tab===1?"#800080":"", boxShadow:tab===1?"0px 2px 2px gray":""}}>New Order</div>
    <div onClick={()=>setTab(2)} style={{fontSize:"15px", fontWeight:"bold", borderRadius:"20px", padding:"10px 15px", cursor:"pointer", backgroundColor:tab===2?"white":"", color:tab===2?"#800080":"", boxShadow:tab===2?"0px 2px 2px gray":""}}>Order History</div>
    </div>
    {tab===1 && <WizardAdmin/>}
    {tab===2 && < WizardOrderHistory/>}
  </>
    
  )
}

export default PageWizard ; 