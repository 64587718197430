import { Alert, Button, createTheme, Fab, IconButton, Snackbar } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { getStores } from '../api/content'
import AddIcon from '@mui/icons-material/Add';
import { isLoggedIn } from '../api/account';
import { navigateToLoginScreen } from '../pages/PageProfile';
import PageAddStore from '../pages/PageAddStore';
import GoogleMap from './map/GoogleMap';
import PlaceIcon from '@mui/icons-material/Place';

const ListOfStores = (props) => {
  const location = {
    address: 'Select your location',
    lat: 11.969230,
    lng: 75.287076,
  }
  const [showMvp, setShowMvp] = useState(false)
  const [open, setOpen] = React.useState(false);
  const [addPage, setAddPage] = React.useState(false)
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const fabStyle = {
    position: 'fixed',
    bottom: 70,
    right: 15,
  };

  const addSocial = () => {
    const user = isLoggedIn()
    if(!user) {
      setOpen(true)
      setTimeout(() => { navigateToLoginScreen('addStore') }, 1800);
    } else {
      setAddPage(true)
    }
  }

  const openMvp = () => {
    const user = isLoggedIn()
    if(user) {
      setShowMvp(true)
    }
  }

  return (
    addPage ?
    <PageAddStore setRefresh={props.setRefresh} doAppBarRefresh={props.loginFlow} /> :
    <div>
    { showMvp ? (<div className="card">
    <GoogleMap location={location} zoomLevel={15} />
    </div> ) : '' }
      <div className="deliveryWrapper">
        {props.stores && props.stores.filter((d) => !d.isDeliverable).map((delivery, i) => 
          <DeliveryCard 
            key={i}
            delivery={delivery}
            selectPickUpLocationFromStoreList={props.selectPickUpLocationFromStoreList}
          />
        )}
      {/* <Button onClick={openMvp} style={{ width: '1rem'}}> 
      </Button> */}
      </div>
      { /* <Fab sx={{ position: "fixed", bottom: 70, right: 15 }} color="secondary" aria-label="add" onClick={addSocial}>
        <AddIcon />
      </Fab> */}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
        You are not logged in. Please log in to add your content.
      </Alert>
      </Snackbar>
    </div>

    )
}
  
function DeliveryCard({delivery, selectPickUpLocationFromStoreList}) {
    let deliveryIcon = 'motorbike.png'
    let deliveryText = 'Call to order'
    let callIcon = delivery.contact ? 'icons-call-outgoing.png' : 'icons-call-disconnected.png'
    let contact = delivery.contact ? 'tel:' + delivery.contact : ''
    let locationIcon = 'icons8-google-maps-48.png'

    return (
        <div className="deliveryCard">
          <div onClick={() => selectPickUpLocationFromStoreList(delivery)} >
            { delivery.image ? <img src={delivery.image} alt="" className="deliveryCard__img" /> : '' }
          </div>
          <div className="deliveryCard__body" onClick={() => selectPickUpLocationFromStoreList(delivery)}>
              <div className="deliveryCard__title">{delivery.accountName}</div>
              <p className="deliveryCard__description">{delivery.description}</p>
          </div>
          <div className="deliveryCard__action_call_right">
            { contact ? 
            <a href={contact} target="_blank" rel="noreferrer">
              <button className="deliveryCard__url" >
              <img src={callIcon} alt="" className="deliveryCard__icon" />
              </button>
            </a> 
            :
            <button className="deliveryCard__url" >
              <img src={callIcon} alt="" className="deliveryCard__icon" />
            </button>
            }
          </div>
        </div>
    );
}

export default ListOfStores;
