import React from 'react'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import TaskDetailsAdvanced from './taskDescription'; 
import FoodBankIcon from '@mui/icons-material/FoodBank';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import BuildIcon from '@mui/icons-material/Build';
import WorkIcon from '@mui/icons-material/Work';
import PendingIcon from '@mui/icons-material/Pending';

const TaskDetails = ({taskName,setTaskName,setTaskDetailsComponent, taskDetails,setTaskDetails, setTaskDetailedAdded }) => {
    
  const [taskDetailsAdvancedComponent, setTaskDetailsAdvancedComponent] = React.useState(false);
  const taskClicked=(task)=>{
    setTaskName(task)
    setTaskDetailsAdvancedComponent(true)
  }

  return (
    <>
    {taskDetailsAdvancedComponent && <TaskDetailsAdvanced taskName={taskName} setTaskName={setTaskName} setTaskDetailsComponent={setTaskDetailsComponent}
      taskDetails={taskDetails} setTaskDetails={setTaskDetails} setTaskDetailedAdded={setTaskDetailedAdded} />}
    {!taskDetailsAdvancedComponent &&  <div style={{margin:" 10px auto",width:"350px", backgroundColor:"white", padding:"13px 15px", borderRadius:"10px"}}>
      
      <div style={{fontSize:"18px", fontWeight:"bold",display:"flex", margin:"0px 0px 20px 0px"}}>
        <div onClick={()=>setTaskDetailsComponent(false)} style={{cursor:"pointer"}}> <ArrowBackOutlinedIcon/> </div>
        <div style={{marginLeft:"15px"}}>Task Details</div>
      </div>
      {/* <div style={{fontSize:"13px",  color:"#f0f0f0", backgroundColor:"#293146", padding:"20px ", letterSpacing:"0.5px" }}>Delivery  illegal item is prohibited by law </div> */}
      <div>
    <div style={{margin:"20px 0px 10px 0px" ,fontWeight:"bolder",}}>Select Package type</div>
    <div style={{display:"flex", flexDirection:"column", border:"2px solid #9e9e9e", borderRadius:"5px", padding:"0px 15px"}}>
      <div onClick={()=>taskClicked('Food')} style={{display:"flex", alignItems:"center", borderBottom:"2px solid #c7c7c7"}}>
        <div style={{}}><FoodBankIcon/></div>
        <div style={{cursor:"pointer",padding:"18px 0px", fontSize:"14px", fontWeight:"bold", color:"#383838",marginLeft:"10px", marginTop:"-5px"}}>Food</div> 
      </div>
      <div onClick={()=>taskClicked('Medicines')} style={{display:"flex", alignItems:"center", borderBottom:"2px solid #c7c7c7"}}>
        <div style={{}}><MedicalServicesIcon/></div>
        <div  style={{cursor:"pointer",padding:"18px 0px", fontSize:"14px", fontWeight:"bold", color:"#383838",marginLeft:"10px", marginTop:"-5px"}}>Medicines</div>
      </div>
      <div onClick={()=>taskClicked('Documents')} style={{display:"flex", alignItems:"center", borderBottom:"2px solid #c7c7c7"}}>
        <div style={{}}><MenuBookIcon/></div>
        <div  style={{cursor:"pointer",padding:"18px 0px", fontSize:"14px", fontWeight:"bold", color:"#383838",marginLeft:"10px", marginTop:"-5px"}}>Documents</div>
      </div>
      <div onClick={()=>taskClicked('Electronic Items')} style={{display:"flex", alignItems:"center", borderBottom:"2px solid #c7c7c7"}}>
        <div style={{}}><LightbulbIcon/></div>
        <div  style={{cursor:"pointer",padding:"18px 0px", fontSize:"14px", fontWeight:"bold", color:"#383838",marginLeft:"10px", marginTop:"-5px"}}>Electronic Items</div>
      </div>
      <div onClick={()=>taskClicked('Clothes')} style={{display:"flex", alignItems:"center", borderBottom:"2px solid #c7c7c7"}}>
        <div style={{}}><CheckroomIcon/></div>
        <div style={{cursor:"pointer",padding:"18px 0px", fontSize:"14px", fontWeight:"bold", color:"#383838",marginLeft:"10px", marginTop:"-5px"}}>Clothes</div>
      </div>
      <div onClick={()=>taskClicked('Business deliveries')} style={{display:"flex", alignItems:"center", borderBottom:"2px solid #c7c7c7"}}>
        <div style={{}}><WorkIcon/></div>
        <div  style={{cursor:"pointer",padding:"18px 0px", fontSize:"14px", fontWeight:"bold", color:"#383838",marginLeft:"10px", marginTop:"-5px"}}>Business deliveries </div>
      </div>
      <div onClick={()=>taskClicked('Others')} style={{display:"flex", alignItems:"center", }}>
        <div style={{}}><PendingIcon/></div>
        <div  style={{cursor:"pointer",padding:"18px 0px", fontSize:"14px", fontWeight:"bold", color:"#383838",marginLeft:"10px", marginTop:"-5px"}}>Others</div>
      </div>
     
    </div>
  </div>

</div>}
    </>
  )
}

export default TaskDetails