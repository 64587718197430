import React from 'react'
import { navigateToLoginScreen } from '../../pages/PageProfile'

const GoogleLoginPage = () => {

  const login = () => { navigateToLoginScreen() } 

  return (
    <>
    <div className="loginPageBody" style={{height:"100vh", margin:"  auto",   overflow:"hidden",backgroundColor:"white", position:"relative" }}>
          <div style={{display:"flex", position:"absolute", top:"30px",width:"80%", left:"20px", justifyContent:"space-between"}}>

            <div style={{display:"flex", alignContent:"center", alignItems:"center", columnGap:"5px"}}>
                    <div style={{height:"8px", width:"8px", backgroundColor:"#700070",  borderRadius:"100%",  }}></div>
                    <div style={{height:"6px", width:"6px", backgroundColor:"#e6e6e6",  borderRadius:"100%",  }}></div>
            </div>

          </div>
            <div style={{height:"70vh", position:"",zIndex:"5", backgroundColor:"#b3b3b3",borderRadius:"100%/25px  25px 25px  25px ", borderTopLeftRadius:"50px", borderTopRightRadius:"50px"}} >
            <div style={{height:"70vh",display:"flex", justifyContent:"center", alignItems:"center", position:"",zIndex:"10", backgroundColor:"blue",borderRadius:"50%/25px  25px 25px  25px ", borderTopLeftRadius:"0px", borderTopRightRadius:"0px",backgroundImage: "linear-gradient( to left top,#b348b3, purple 50%)"}}>
                <div style={{display:"flex", flexDirection:"column", rowGap:"200px", marginTop:"10vh"}}>

                <img src="./logo_purple_512.jpg" alt="" style={{ width: 50, height: 50 , marginLeft:"45px" ,borderRadius:50 , marginTop:"100px"}}/>
                <div style={{cursor:"pointer",color:"#f0f0f0",fontSize:"20px", fontWeight:"bolder"}}><img src="./logo_text_white_transparent.png" alt="" style={{ width: 130, height: 10 ,marginBottom:"10px"}}/></div>
                </div>
             
            </div>

            </div>
            <div style={{height:"22vh",  backgroundColor:"white", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <div onClick={login} style={{height:"40px",cursor:"pointer", display:   "flex",alignItems:"center", fontSize:"15px",padding:"5px 20px", width:"90%", color:"black",fontWeight:"bolder", borderRadius:"50px",textAlign:"center",verticalAlign:"middle",lineHeight:"30px",border:"1px solid black", margin: "1rem" }}>
                <img src="./googleIcon.svg" style={{width:"25px", height:"25px"}} alt="" />
                <div style={{marginLeft:"70px", userSelect:"none", color:"#424242"}}>
                {"Sign in with Google"}
                </div>
                </div>
            </div>
            <div style={{height:"8vh",  backgroundColor:"white", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <div style={{cursor:"pointer",height:"40px",fontSize:"0.8rem", width:"90%", color:"grey", textAlign:"right",verticalAlign:"middle",lineHeight:"30px", borderRadius:"50px", paddingTop:'5px'}}>
                   <a href="https://www.youtube.com/watch?v=t3v0-aYc0EQ&list=PLtASNhZjSPhDEMvQ1xbpI1ZW30Qzr8zK9" target="_blank" rel="noreferrer" style={{textDecoration: "none", marginLeft:"1rem", color: "dimgrey" }}>{"Videos"}</a>
                   <a href="https://merchant.razorpay.com/policy/KU2Gb0PLZvEerc" target="_blank" rel="noreferrer" style={{textDecoration: "none", marginLeft:"1rem", color: "dimgrey" }}>{"Policies"}</a>
                   <a href="https://mattool.in?page=tc" target="_blank" rel="noreferrer" style={{textDecoration: "none", marginLeft:"1rem", color: "dimgrey" }} >{"Terms"}</a>
                   <a href="https://wa.me/918089783756?text=Hi Mattool" target="_blank" rel="noreferrer" style={{textDecoration: "none", marginLeft:"1rem", color: "dimgrey" }}>{"Help"}</a>
                </div>
            </div>
        </div>
        </>
  )
}

export default GoogleLoginPage