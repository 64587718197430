import React from 'react'
import './Cards.css'
import './Skelton.css'
export function SkeltonLoading(){
    const name = "ssssssssssss"
    const delay = "sssssssssssss"
    const name2 = "ssssssssssssss"
    const time = "ssssssssssss"
    const arrivedTime = "ssss"
    return(
        <div>
       
      {/************************************* */}
      <div className="nav-menu skelton-nav">
      <ul className="active-ul ul-override-arrived skelton">
        <li><a className='active-li bus-enabled'>
        <div className="name-bus2 name-bus2-skelton name-bus2-shadow">
        <div className="name-bus-h2">
        <h4 className="name2 skelton-text">{name2}</h4>
        </div>
        <div className="name-bus-h2">
        <span className="name on-time2 skelton-text">{time}</span>
        </div>
        </div>
        <div className="name-bus-skelton">
        <div className="name-bus-h">
        <h4 className="name skelton-text">{name}</h4>
        
        </div>
        <div className="name-bus-h">
        <span className="name on-time skelton-text">{delay}</span>
        </div>
        </div>
       
       
        </a>
        

        </li>
    </ul>
    <ul className="active-ul ul-override-arrived skelton">


      
    <li><a className='active-li bus-enabled'>
     
        <div className="name-bus2 name-bus2-skelton name-bus2-shadow">
        <div className="name-bus-h2">
        <h4 className="name2 skelton-text">{name2}</h4>
        </div>
        <div className="name-bus-h2">
        <span className="name on-time2 skelton-text">{time}</span>
        </div>
        </div>
        <div className="name-bus-skelton">
        <div className="name-bus-h">
        <h4 className="name skelton-text">{name}</h4>
        
        </div>
        <div className="name-bus-h">
        <span className="name on-time skelton-text">{delay}</span>
        </div>
        </div>
       
       
        </a>
        

        </li>
    </ul>
    <ul className="active-ul ul-override-arrived skelton">


      
    <li><a className='active-li bus-enabled'>
     
        <div className="name-bus2 name-bus2-skelton name-bus2-shadow">
        <div className="name-bus-h2">
        <h4 className="name2 skelton-text">{name2}</h4>
        </div>
        <div className="name-bus-h2">
        <span className="name on-time2 skelton-text">{time}</span>
        </div>
        </div>
        <div className="name-bus-skelton">
        <div className="name-bus-h">
        <h4 className="name skelton-text">{name}</h4>
        
        </div>
        <div className="name-bus-h">
        <span className="name on-time skelton-text">{delay}</span>
        </div>
        </div>
       
       
        </a>
        

        </li>
    </ul>
    <ul className="active-ul ul-override-arrived skelton">


      
    <li><a className='active-li bus-enabled'>
     
        <div className="name-bus2 name-bus2-skelton name-bus2-shadow">
        <div className="name-bus-h2">
        <h4 className="name2 skelton-text">{name2}</h4>
        </div>
        <div className="name-bus-h2">
        <span className="name on-time2 skelton-text">{time}</span>
        </div>
        </div>
        <div className="name-bus-skelton">
        <div className="name-bus-h">
        <h4 className="name skelton-text">{name}</h4>
        
        </div>
        <div className="name-bus-h">
        <span className="name on-time skelton-text">{delay}</span>
        </div>
        </div>
       
       
        </a>
        

        </li>
    </ul>
    <ul className="active-ul ul-override-arrived skelton">


      
    <li><a className='active-li bus-enabled'>
     
        <div className="name-bus2 name-bus2-skelton name-bus2-shadow">
        <div className="name-bus-h2">
        <h4 className="name2 skelton-text">{name2}</h4>
        </div>
        <div className="name-bus-h2">
        <span className="name on-time2 skelton-text">{time}</span>
        </div>
        </div>
        <div className="name-bus-skelton">
        <div className="name-bus-h">
        <h4 className="name skelton-text">{name}</h4>
        
        </div>
        <div className="name-bus-h">
        <span className="name on-time skelton-text">{delay}</span>
        </div>
        </div>
       
       
        </a>
        

        </li>
    </ul>
    </div>
    </div>
    )
}