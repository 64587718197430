import { useEffect, useState } from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import { Box, LinearProgress, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { verifyUser } from '../../api/account';

export default function VerificationAdmin() {
  const [loading, setLoading] = useState(false);
  const [reponseMessage, setResponseMessage] = useState("");

  const { control, handleSubmit } = useForm();

  const invokeVerifyUser = async (event) => {
    setLoading(true);
    const payload = { 
        "code" : event.code,
        "mobile" : event.mobile
    }
    const responseSuccess = await verifyUser(payload);
    if(responseSuccess) {
      setLoading(false);
      setResponseMessage("User Verification status : Success")
      setTimeout(() => setResponseMessage(""), 5000)
    } else {
      setResponseMessage("User Verification status : Failed")
      setTimeout(() => setResponseMessage(""), 5000)
    }
  };

  return (
    <div className='card'>
      { loading ? <Box sx={{ width: '100%' }}>
          <LinearProgress />
      </Box> : '' }
      {<div className='card__body' style={{ padding: "0.5rem" }}>
      <form onSubmit={handleSubmit(invokeVerifyUser)}>
        <FormControl component="fieldset" variant="standard">
        <FormLabel component="legend">User</FormLabel>
        { reponseMessage && <div style={{ color: "blue" }}>{reponseMessage}</div> }
        <FormGroup>
            <div style={{marginTop:"1rem", marginBottom: "0.5rem"}}>
            <Controller
              name="code"
              control={control}
              defaultValue=""
              rules={{
                required: true
              }}
              render={({ field }) => <TextField
                required
                fullWidth size="medium"
                label="Enter code" {...field} 
                inputProps={{ maxLength: 50 }}
              />
              }
            />
            </div>
            <div style={{marginTop:"1rem", marginBottom: "0.5rem"}}>
            <Controller
              name="mobile"
              control={control}
              defaultValue="+91"
              rules={{
                required: true
              }}
              render={({ field }) => <TextField
                required
                fullWidth size="medium"
                label="Enter mobile with country code, remove spaces" {...field} 
                inputProps={{ maxLength: 14 }}
              />
              }
            />
            </div>
        </FormGroup>
        <input className='card__post_content__btn' type="submit" value={'Verify User'}/>
        </FormControl>
        </form>
     </div> }
    </div>
  );
}