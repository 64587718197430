import React from 'react'
import GoogleMapReact from 'google-map-react'
import LocationPin from './LocationPin'
import { G_API_KEY } from '../../config/constant'

export default function GoogleMap({ location, zoomLevel, theme }) {
    return (<div className="map">
      <h2 className="map-h2">Pick up and delivery in Mattool</h2>
  
      <div className="google-map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: G_API_KEY }}
          defaultCenter={location}
          defaultZoom={zoomLevel}
        >
          <LocationPin
            lat={location.lat}
            lng={location.lng}
            text={location.address}
            theme={theme}
          />
        </GoogleMapReact>
      </div>
    </div>
  )
}