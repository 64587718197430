import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ThemeProvider } from '@mui/material/styles';
import { pushGA4CustomEvent } from '../Analytics';

export default function BusDirectionToggleButton(props) {
  const handleChange = (event, newDirection) => {
    props.handleBusDirectionChange(newDirection);
    //analytics
    let pageTitle = "Bus route toggle click"
    let pageUrl =  "busRouteToggle"
    let eventDetails =  `direction ${newDirection}` 
    pushGA4CustomEvent(pageTitle,pageUrl,eventDetails);
  };

  return (
    <ThemeProvider theme={props.theme}>
    <ToggleButtonGroup
      value={props.selectedBusDirection}
      exclusive
      onChange={handleChange}
      size="medium"
      color={"themePurple"}
    >
      {props.routeDirections.map((direction) => {       
           return (
            <ToggleButton  value={direction}  style={{ padding: "4px" }} >
              <div className="busRouteDirectionLabel">{direction}</div>
            </ToggleButton>
           ) 
        })}
    </ToggleButtonGroup>
    </ThemeProvider>
  );
}
