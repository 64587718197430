import React,{useEffect,useState} from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { isLoggedIn } from '../../api/account';
import { pushGA4CustomEvent } from '../../Analytics';
import { Alert } from '@mui/material';

function BoatAzhikkal (props) {

  const [boatTimings, setBoatTimings] = useState(null)
  const [fromStopList, setFromStopList] = useState(null)
  const [toStopList, setToStopList] = useState(null)
  const [fromStop, setFromStop] = useState(null)
  const [toStop, setToStop] = useState(null)

  const user = isLoggedIn();

  useEffect(() => {
    const fetchData = async () => {
      // let boatTimingsResponse = await getBoatTimings()
      let boatTimingsResponse = {
        stops: [ 'MATTOOL', 'AZHIKKAL', 'PARASSINIKADAVU' ],
        time: {
          'azhikkal-mattool': ['06:30 am','07:00 am','07:30 am','08:00 am','08:30 am','09:00 am','09:30 am','10:00 am','10:30 am','Refuel break','11:30 am','12:00 pm','12:30 pm','01:00 pm','01:30 pm','02:00 pm','02:30 pm','03:00 pm','03:30 pm','04:00 pm','04:30 pm','05:00 pm','05:30 pm','06:00 pm','06:30 pm','07:00 pm'],
          'mattool-azhikkal': ['06:45 am','07:15 am','07:45 am','08:15 am','08:45 am','09:15 am','09:45 am','10:15 am','10:45 am','Refuel break','11:45 am','12:15 pm','12:45 pm','01:15 pm','01:45 pm','02:15 pm','02:45 pm','03:15 pm','03:45 pm','04:15 pm','04:45 pm','05:15 pm','05:45 pm','06:15 pm','06:45 pm','07:15 pm' ],
          'parassinikadavu-mattool': [ '09:30 AM  (1h 30min)'],
          'mattool-parassinikadavu': [ '11:45 AM  (1h 30min)'],
          'parassinikadavu-azhikkal': [ '09:30 AM  '],
          'azhikkal-parassinikadavu': [ '12:00 PM  '],
        }
      }
      setFromStopList(boatTimingsResponse.stops)
      if(fromStop) {
        const toStopList = boatTimingsResponse.stops.filter((stop) => stop !== fromStop)
        setToStopList(toStopList)
      }
      if(fromStop && toStop) {
        const boatTimingsList = boatTimingsResponse.time[`${fromStop.toLowerCase()}-${toStop.toLowerCase()}`]
        setBoatTimings(boatTimingsList)  
        pushGA4CustomEvent("boat timings", `boat_timings_${fromStop}_${toStop}`)
      }
    }
    fetchData();
  },[fromStop, toStop])

  const handleFromStopChange = (event, newFromStop) => {
    setFromStop(newFromStop);
  };
  const handleToStopChange = (event, newToStop) => {
    setToStop(newToStop);
  };

  // inline css
  return(
    <div>
      <div style={{height:"8vh",flexDirection:"column", backgroundColor:"white", display:"flex", paddingLeft: "1rem", marginBottom:"0.5rem"}}>
        <div style={{height:"3vh"}}>From</div>
        <div style={{height:"5vh", justifyContent:"center" }}>
          <ToggleButtonGroup color="primary" size="small"  value={fromStop} exclusive onChange={handleFromStopChange} aria-label="Platform">
            {
              fromStopList && fromStopList.map((stop) => (
                <ToggleButton value={stop}>{stop}</ToggleButton>
              ))
            }
          </ToggleButtonGroup>
        </div>
      </div>
      <div style={{height:"8vh",flexDirection:"column", backgroundColor:"white", display:"flex" , paddingLeft: "1rem"}}>
        { toStopList && <div>
          <div style={{height:"3vh"}} >To</div>
          <div style={{height:"5vh", justifyContent:"center" }}>
            <ToggleButtonGroup color="primary" size="small"  value={toStop} exclusive onChange={handleToStopChange} aria-label="Platform">
              {
                toStopList && toStopList.map((stop) => (
                  <ToggleButton value={stop}>{stop}</ToggleButton>
                ))
              }
            </ToggleButtonGroup>
          </div>
        </div>
        }
      </div>

      { boatTimings && <>
        <div style={{height:"30vh",flexDirection:"column", backgroundColor:"white", display:"flex", 
          justifyContent:"center", alignItems:"center", borderStyle: 'ridge', margin: "1rem"}}>
          <List disablePadding={true} sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', overflowY: "auto" }}  >
            {boatTimings && boatTimings?.map((value) => (
              <ListItem disablePadding={true} key={value} sx={{paddingLeft: "1rem" }}>
                <ListItemText primary={`${value}`} />
              </ListItem>
            ))}
          </List>
        </div>
        <div style={{height:"5vh",flexDirection:"column", backgroundColor:"white", display:"flex", 
        justifyContent:"center", alignItems:"center", margin: "1rem"}}>
          <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
            <a  style={{ display: "flex", textAlign: "center", justifyContent: "center", textDecoration: "none",
              color: "green",  borderStyle: "double", padding: "0.5rem", borderRadius: "0.5rem", marginBottom: "0.5rem" }} 
            href= {"https://wa.me/918089783756?text=" + " SEND MESSAGE TO START CONVERSATION "+" "+"[ " + user.email + " ]" }> <WhatsAppIcon style={{ marginRight:"0.5rem" }}/>In case of any issue. Click to chat </a>
          </div>
        </div>
      </>
      }
      <Alert severity="info">അഴീക്കൽ ബോട്ട് ജെട്ടി (അഴീക്കോട്) കാലപഴക്കം കാരണം പുനർനിർമ്മാണം നടത്തുന്നതിൻ്റെ ഭാഗമായി ബോട്ട് താൽക്കാലികമായി കുറച്ച് മാസത്തേക്ക് അഴീക്കോട് ആലാളം മഖാം ബോട്ട് ജെട്ടിയിലേക്കായിരിക്കും സർവ്വീസ് നടത്തുക.  അതിനാൽ കണ്ണൂരിൽ നിന്നും കടവ് വഴി മാട്ടൂലേക്ക് യാത്ര ചെയ്യുന്നവർക്ക് അഴീക്കോട് ബോട്ട് പാലം ബസ്സ് സ്റ്റോപ്പിൽ ബസ്സ് ഇറങ്ങി ആലാളം മഖാം റോഡിലുടെ നടന്ന് ആലാളം മഖാം ബോട്ട് ജെട്ടിയിൽ എത്താവുന്നതാണ്.</Alert>
    </div>
  )

}

export default BoatAzhikkal ;
