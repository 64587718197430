import React, { useEffect,useState } from 'react'
import HistoryItem from './historyItem'
import {getOrderHistory} from './../../api/user' 
import { LinearProgress } from '@material-ui/core'

const History = () => { 

  const [orderHistory, setOrderHistory] = useState(false)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      let res = await getOrderHistory();
      if(res && res.length > 0){
        setOrderHistory(res?.reverse()) 
      }
      setLoading(false)
    }
    fetchData();
    console.log(orderHistory)
  }, []) 
  return (<>  
    { 
      loading ? <LinearProgress /> :
      <div style={{  margin:"15px auto" , width:"300px", height:"72vh", display:"flex", flexDirection:"column", rowGap:"15px",backgroundColor:"white",borderRadius:"15px ", padding:"20px", overflow:"auto" }}>
      {!orderHistory && <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100%"}}>You have not placed any orders</div> }
      {orderHistory && orderHistory.map((o)=>{ return <HistoryItem key={o.orderId} orderId={o.orderId}  date={o.date} pickUpAddress={o.pickUpAddress} dropAddress={o.dropAddress} status={o.status} type={o.type} description={o.description} amount={o.amount} /> 
      })}
      </div>
    }
    </>
  )
}

export default History