
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { Divider } from '@mui/material';
import * as React from 'react';

export default function DiscountSection({ setShowCouponPage, discountApplied, cancelAppliedCoupon, total, discount, amount }) {
  if (discountApplied) {
    return (
    <div>
      <div style={{display: "grid", gridTemplateColumns: "1fr 12fr 1fr", alignItems: "center", 
        margin: "0.6rem 0.5rem", padding: "0.5rem 0.5rem 0.5rem 0.5rem"}}>
          <LocalOfferOutlinedIcon />
          <div style={{paddingLeft: "0.5rem", fontSize: "1rem", fontFamily: "sans-serif", fontWeight: "500" }}>Offer Applied</div>
          <div onClick={() => { cancelAppliedCoupon() }} style={{paddingLeft: "0.5rem", fontSize: "0.7rem", fontFamily: "sans-serif", fontWeight: "bold",color: "purple" }}>Remove</div>
      </div>
      <div style={{display: "grid", gridTemplateColumns: "3fr 1fr", alignItems: "center", 
        margin: "0.6rem 0.5rem", padding: "0.5rem 0.5em 0.5rem 0rem"}}>
          <div style={{paddingLeft: "0.5rem", fontSize: "1rem", fontFamily: "sans-serif", fontWeight: "500" }}>Total</div>
          <div style={{paddingLeft: "0.5rem", fontSize: "1rem", fontFamily: "sans-serif", fontWeight: "400", 
              color: "black", display: "flex", justifyContent: "flex-end" }}>₹{total}.00</div>
      </div>
      <div style={{display: "grid", gridTemplateColumns: "3fr 1fr", alignItems: "center", 
        margin: "0.6rem 0.5rem", padding: "0.5rem 0.5rem 0.5rem 0rem"}}>
          <div style={{paddingLeft: "0.5rem", fontSize: "1rem", fontFamily: "sans-serif", fontWeight: "500" }}>Discount</div>
          <div style={{paddingLeft: "0.5rem", fontSize: "1rem", fontFamily: "sans-serif", fontWeight: "400", 
              color: "green", display: "flex", justifyContent: "flex-end" }}>-₹{discount}.00</div>
      </div>
      <Divider />
      <div style={{display: "grid", gridTemplateColumns: "3fr 1fr", alignItems: "center", 
        margin: "0.6rem 0.5rem", padding: "0.5rem 0.5rem 0.5rem 0rem"}}>
          <div style={{paddingLeft: "0.5rem", fontSize: "1rem", fontFamily: "sans-serif", fontWeight: "bold" }}>To Pay</div>
          <div style={{paddingLeft: "0.5rem", fontSize: "1rem", fontFamily: "sans-serif", fontWeight: "400", 
              color: "black", display: "flex", justifyContent: "flex-end" }}> ₹{amount}.00</div>
      </div>
    </div>
    )
  } else {
    return (
      <div onClick={() => { setShowCouponPage(true) }} style={{display: "grid", gridTemplateColumns: "1fr 12fr 1fr", 
        alignItems: "center", margin: "0.6rem 0.5rem", padding: "0.5rem 0.5rem 0.5rem 0.5rem"}}>
          <LocalOfferOutlinedIcon />
          <div style={{paddingLeft: "0.5rem", fontSize: "1rem", fontFamily: "sans-serif", fontWeight: "500" }}>Apply Coupon</div>
          <ArrowRightIcon />
      </div>
    );
  }
}
