
import React from 'react';

function CardContact (props) {
    return (
      <div className='card'>
        <div className='card__body'>
          <img className='card__image' alt='' src={props.img} />
          <h2 className='card__title'>{props.title}</h2>
          <p className='card__description' style={{ padding: "0 1rem 1rem 1rem" }}>{props.description}</p>
        </div>
      </div>
    )
}

export default CardContact;
