
export const place = 'mattool'
export const SERVICE_API_URL = process.env.NODE_ENV === 'production' ?
'https://bne2kfnyt8.execute-api.ap-south-1.amazonaws.com/dev' : 'http://localhost:3001'
export const APP_DOMAIN = process.env.NODE_ENV === 'production' ?
'https://mattool.in' : 'http://localhost:3000'
export const BOTTOM_NAVIGATION = {
    HOME: "HOME",
    BUS: "BUS",
    BOAT: "BOAT",
    OFFER_ZONE: "OFFER_ZONE",
    DELIVERY: "DELIVERY",
    USER_PROFILE: "USER_PROFILE",
    WIZARD: "WIZARD",
    ADMIN: "ADMIN",
}

export const ORDER_STATUS = {
    ORDER_PENDING: "ORDER_PENDING",
    ORDER_WIZARD_SWITCH: "ORDER_WIZARD_SWITCH",
    ORDER_CONFIRMED: "ORDER_CONFIRMED",
    ORDER_PICKED_UP: "ORDER_PICKED_UP",
    ORDER_ON_THE_WAY: "ORDER_ON_THE_WAY",
    ORDER_DELIVERED: "ORDER_DELIVERED",
    ORDER_CANCELLED: "ORDER_CANCELLED"
  }

export const PAYMENT_TYPE = {
  COD: "COD",
  ONLINE: "ONLINE"
}

export const ORDER_VEHICLE_TYPE = {
  TWO_WHEELER: "TWO_WHEELER",
  THREE_WHEELER: "THREE_WHEELER"
}

export const G_API_KEY = 'test'

export const IS_PROD = process.env.NODE_ENV === 'production' ? true : false
