
import axios from 'axios'
import { place, SERVICE_API_URL } from '../config/constant'

export const getGroceries = async () => {
    const response = await axios.get(SERVICE_API_URL+'/groceries', { params: {
        place
    }});
    return response.data
}
