import React, { useEffect, useState } from 'react'
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { Button, FormControlLabel, FormGroup, LinearProgress } from '@mui/material';
import { getWizardProfile, getDeliveryWizardAssignedOrder, updateOrder, updateWizard, getDeliveryWizardOneTimeOrder } from '../../api/wizard'
import { ORDER_STATUS } from '../../config/constant';
import Switch from '@mui/material/Switch';
import { fontSize } from '@mui/system';

const WizardAdmin = ({userOrderId, oneTimeOrder}) => {

    const totalOrder = 0
    const totalEarnings = 0
    const [order, setOrder] =  useState(null)
    const [oneTimeOrderInvalid , setOneTimeOrderInvalid] = useState(false)

    const [wizardActiveToggle, setWizardActiveToggle] = useState(false)
    const [disabledAccepteOrderBtn , setDisabledAccepteOrderBtn] = useState(true)
    const [disabledPickedUpOrderBtn , setDisabledPickedUpOrderBtn] = useState(true)
    const [disabledDeliveredOrderBtn , setDisabledDeliveredOrderBtn] = useState(true)
    const [disabledCancelledOrderBtn , setDisabledCancelledOrderBtn] = useState(true)

    const [progressBarOpen, setProgressBarOpen] = React.useState(false);

    useEffect(() => {
      async function fetchAssignedOrder() {
          handleProgressBarOpen()
          if(oneTimeOrder) {
            const orderData = await getDeliveryWizardOneTimeOrder(userOrderId)
            if(orderData) {
              setOrder(orderData)
            } else {
              setOneTimeOrderInvalid(true)
            }
          } else {
            const orderData = await getDeliveryWizardAssignedOrder()
            setOrder(orderData)
            const profile = await getWizardProfile()
            if(profile?.status === 'ACTIVE') setWizardActiveToggle(true)
          }

          handleProgressBarClose()
      }
      fetchAssignedOrder()
    }, [])

    useEffect(() => {
      if([ORDER_STATUS.ORDER_PENDING, ORDER_STATUS.ORDER_WIZARD_SWITCH].includes(order?.status[order.status.length - 1].status)) {
        setDisabledAccepteOrderBtn(false)
        setDisabledCancelledOrderBtn(false)
      }
      if(order?.status[order.status.length - 1].status === ORDER_STATUS.ORDER_CONFIRMED)
        setDisabledPickedUpOrderBtn(false)
      if(order?.status[order.status.length - 1].status === ORDER_STATUS.ORDER_ON_THE_WAY)
        setDisabledDeliveredOrderBtn(false)
    }, [order])

    const acceptOrders = async () => {
      handleProgressBarOpen()
      const updatedOrder = await updateOrder({
        orderId: order.orderId,
        status: ORDER_STATUS.ORDER_CONFIRMED
      })
      handleProgressBarClose()
      setOrder(updatedOrder)
      setDisabledAccepteOrderBtn(true)
      setDisabledCancelledOrderBtn(true)
    }

    const pickedOrders = async () => {
      handleProgressBarOpen()
      await updateOrder({
        orderId:order.orderId,
        status: ORDER_STATUS.ORDER_PICKED_UP
      })
      const updatedOrder = await updateOrder({
        orderId:order.orderId,
        status: ORDER_STATUS.ORDER_ON_THE_WAY
      })
      handleProgressBarClose()
      setOrder(updatedOrder)
      setDisabledPickedUpOrderBtn(true)
    }

    const orderDelivered = async () => {
      handleProgressBarOpen()
      const updatedOrder = await updateOrder({
        orderId:order.orderId,
        status: ORDER_STATUS.ORDER_DELIVERED
      })
      handleProgressBarClose()
      setOrder(updatedOrder)
      setDisabledDeliveredOrderBtn(true)
    }

    const orderCancelled = async () => {
      handleProgressBarOpen()
      const updatedOrder = await updateOrder({
        orderId:order.orderId,
        status: ORDER_STATUS.ORDER_WIZARD_SWITCH
      })
      handleProgressBarClose()
      setOrder(updatedOrder)
      setDisabledCancelledOrderBtn(true)
      setOrder(null)
      setWizardActiveToggle(false)
    }

    const handleWizardActiveToggleChange = async () => {
      handleProgressBarOpen()
      setWizardActiveToggle(!wizardActiveToggle)
      await updateWizard({
        isActive: !wizardActiveToggle
      })
      handleProgressBarClose()
    }

    const handleProgressBarClose = () => {
      setProgressBarOpen(false);
    };
    const handleProgressBarOpen = () => {
      setProgressBarOpen(true);
    };

  return (
    <div className='wrapper'>
      <div className='card'>
      {progressBarOpen ? <LinearProgress color="inherit" /> : '' }
        <div className='card__body' >
          { !order && !oneTimeOrder ? 
            <div style={{justifyContent: 'right', display: 'flex', background: 'lightsteelblue', padding: '0.5rem'}} >
              <FormGroup>
                <FormControlLabel 
                  control={<Switch size="large" color="success"
                    checked={wizardActiveToggle} onChange={handleWizardActiveToggleChange} 
                  />} 
                  label="Find new orders" labelPlacement="start"/>
              </FormGroup>
            </div>
          : '' }
          <div style={{'display': 'flex','alignItems':'center','justifyContent': 'center','flexDirection':'column'}}>
          </div>
          { order ?             
            <div>
              <div>
                <div  style={{marginTop:'1rem', marginLeft: '1rem', fontSize:"10px" ,fontWeight:"lighter",color:"gray"}}>ORDER ID: {order.orderId}</div>
                <div style={{marginTop:'1rem', marginLeft: '1rem'}}>PICK UP ADDRESS:
                </div>   
                <div className='card' style={{backgroundColor:'lightgrey',borderRadius:'10px',margin:'0.5rem', "wordWrap": "break-word", padding: "0.5rem"}} >
                  <div style={{ display: "grid", gridTemplateColumns: "5fr 1fr"}}>
                    <div>
                      {order.pickUpAddress?.name} <br/>
                      {order.pickUpAddress?.address} <br/>
                      Area [{order.pickUpAddress?.areaId}] <br/>
                    </div>
                    <div>
                      <a href={"tel:" + order.pickUpAddress?.phone} target="_blank" rel="noreferrer">
                        <button className="deliveryCard__url" >
                        <img src="icons-call-outgoing.png" alt="" className="deliveryCard__icon" />
                        </button>
                      </a>  
                    </div>
                  </div>
                </div>
                <div style={{marginLeft: '1rem'}}>DROP ADDRESS :
                </div> 
                <div className='card'  style={{backgroundColor:'lightgrey',borderRadius:'10px',margin:'0.5rem', "wordWrap": "break-word", padding: "0.5rem"}} >
                  <div style={{ display: "grid", gridTemplateColumns: "5fr 1fr"}}>
                    <div>
                      {order.dropAddress?.name} <br/>
                      {order.dropAddress?.address} <br/>
                      Area [{order.dropAddress?.areaId}] <br/>
                    </div>
                    <div>
                      <a href={"tel:" + order.dropAddress?.phone} target="_blank" rel="noreferrer">
                        <button className="deliveryCard__url" >
                        <img src="icons-call-outgoing.png" alt="" className="deliveryCard__icon" />
                        </button>
                      </a>  
                    </div>
                  </div>
                </div>
                <div style={{marginLeft: '1rem'}}>Task Details</div>
                <div className='card'  style={{backgroundColor:'lightgrey',height:'50px',borderRadius:'10px',margin:'0.5rem', "wordWrap": "break-word", padding: "0.5rem"}} >
                {order.item} <br />
                {order.itemDetails}
                </div>
              </div>
              {/* { order?.paymentType === 'COD' && <>
                <div style={{backgroundColor:"white", borderRadius:"10px",margin:"10px" , padding:"10px" }} >
                    <div style={{ display: "flex", columnGap: "1rem" }}>
                      <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                        <span style={{ color: "grey", marginRight: "0.5rem" }}>Cash on Delivery</span>  ₹ {order?.amount}
                      </div>
                    </div>
                </div>      
              </>} */}
              <div>
                <div>
                  {progressBarOpen ? <LinearProgress color="inherit" /> : '' }
                  <Button 
                    variant='contained' 
                    style={{borderRadius : '25px' , fontSize : '10px',width:'200px',margin:'10px',marginLeft:'70px'}}
                    color='success'
                    disabled={disabledAccepteOrderBtn}
                    onClick={() => acceptOrders()}
                  >I accept this order</Button>
                  <Button 
                      variant='contained' 
                      style={{borderRadius : '25px' , fontSize : '10px',width:'200px',margin:'10px',marginLeft:'70px'}}
                      color='success'
                      disabled={disabledPickedUpOrderBtn}
                      onClick={() => pickedOrders()}
                  >I have picked up this order</Button>
                  <Button 
                      variant='contained' 
                      style={{borderRadius : '25px' , fontSize : '10px',width:'200px',margin:'10px',marginLeft:'70px'}}
                      color='success'
                      disabled={disabledDeliveredOrderBtn}
                      onClick={() => orderDelivered()}
                  >Order delivered</Button>
                  { !disabledCancelledOrderBtn ? <Button 
                      variant='contained' 
                      style={{borderRadius : '25px' , fontSize : '10px',width:'200px',margin:'10px',marginLeft:'70px'}}
                      color='error'
                      disabled={disabledCancelledOrderBtn}
                      onClick={() => orderCancelled()}
                    >Ignore the order</Button> 
                  : '' }
                </div>
              </div>
            </div>
          : 
            <div style={{  position: "relative", display: "flex", justifyContent: "center", padding: "1rem" }}>
                <div style={{  position: "relative", display: "flex", justifyContent: "center", padding: "1rem" }}>
                 { oneTimeOrder ? (oneTimeOrderInvalid ? 'Invalid link' : 'loading...') : 'Chill out! There are no Orders assigned to you.' }
                </div>
            </div>
          }
        </div>
      </div>
    </div> 
  )
}

export default WizardAdmin
