import React from 'react'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import {useRef} from 'react';
import { TextField } from '@mui/material';
import { pushGA4CustomEvent } from '../../../Analytics';


  
const TaskDetailsAdvanced = ({taskName,setTaskDetailsComponent,setTaskName, taskDetails, setTaskDetails, setTaskDetailedAdded}) => { 
  
  const handleChangeTaskName = (event) => {
    setTaskName(event.target.value);
  };

  const handleChangeTaskDetails = (event) => {
    setTaskDetails(event.target.value);
  };
  
  const onClickContinue = () => {
    setTaskDetailsComponent(false);
    let pageTitle = "Task added"
    let pageUrl = "taskadded"
    pushGA4CustomEvent(pageTitle,pageUrl)
    setTaskDetailedAdded(true);
  }

  return (
    <>
    <div style={{margin:"10px auto 40px auto",width:"350px", backgroundColor:"white", padding:"20px 15px", borderRadius:"10px"}}>
     {/* <div style={{fontSize:"13px",  color:"#f0f0f0", backgroundColor:"#293146", padding:"20px ", letterSpacing:"0.5px" }}>Delivery of alchohol or any illegal item is prohibited by law </div> */}
     <div style={{margin:"20px 0px 10px 0px" ,fontWeight:"lighter",color:"gray"}}>Package type</div>
      <Box sx={{ minWidth: 120 }}> 
      <FormControl fullWidth>
        <Select  
        renderValue={(value) => value ? value : <em>Nothing Selected</em>}
          value={taskName}
          onChange={handleChangeTaskName}
        >
          <MenuItem style={{display:"block", padding:"10px", fontSize:"15px"}} value={"Food"}>Food</MenuItem>
          <MenuItem style={{display:"block", padding:"10px", fontSize:"15px"}} value={"Medicines"}>Medicines</MenuItem>
          <MenuItem style={{display:"block", padding:"10px", fontSize:"15px"}} value={"Documents"}>Documents</MenuItem>
          <MenuItem style={{display:"block", padding:"10px", fontSize:"15px"}} value={"Electronic Items"}>Electronic Items</MenuItem>
          <MenuItem style={{display:"block", padding:"10px", fontSize:"15px"}} value={"Clothes"}>Clothes</MenuItem>
          <MenuItem style={{display:"block", padding:"10px", fontSize:"15px"}} value={"Business deliveries"}>Business deliveries</MenuItem>
          <MenuItem style={{display:"block", padding:"10px", fontSize:"15px"}} value={"Others"}>Others</MenuItem>
        </Select>
      </FormControl>
      </Box>
     <div style={{margin:"20px 0px 10px 0px" ,fontWeight:"bolder",}}>Add Task Details</div>
        <TextField
            fullWidth
            label="Eg: Contains hot food, receipt no.1234"
            multiline
            minRows={4}
            variant="outlined"
            name="taskDetails"
            id="taskDetails"
            value={taskDetails}
            onChange={handleChangeTaskDetails}
          />
    
    <div style={{fontSize:"14px", fontWeight:"lighter", color:"gray",borderRadius:"5px",padding:"18px",marginTop:"20px", backgroundColor:"#ffffff", color:"gray"}}>
        For orders involving payment, please pay to the store or seller directly
    </div>

    <div onClick={()=>onClickContinue()} style={{cursor:"pointer",backgroundColor:"#12AD2B",width:"320px", padding:"15px", textAlign:"center", color:"white", fontSize:"15px", borderRadius:"10px",font:"i" , position: "fixed", bottom:"90px"}}>
      CONTINUE
    </div>
    </div>
    </>
  )
}

export default TaskDetailsAdvanced