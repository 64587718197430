import React,{useEffect, useState} from 'react';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { BOTTOM_NAVIGATION } from '../config/constant';
import OfferImageList from '../components/offerZone/OfferImagesList';

function PageOfferZone (props) {

  const goBackHome = () => {
    props.updateBottomNavigationTab(BOTTOM_NAVIGATION.HOME)
  }

  // inline css
  return(
    <div  style={{ margin:"0.5rem",   overflow:"hidden",backgroundColor:"white", position:"relative", marginBottom: "4rem"}}>
      <div style={{display:"flex", position:"absolute", top:"1rem",width:"90%", left:"20px", justifyContent:"space-between"}}>
        <div onClick={goBackHome} style={{cursor:"pointer"}}> <ArrowBackOutlinedIcon /> </div>
      </div>
      <div style={{ backgroundColor:"white", display:"flex", justifyContent:"center", margin: "0.5rem", borderRadius: "0.5rem", paddingTop:"2rem", columnGap: "1rem"}}> 
        <div style={{ backgroundColor:"white", display:"flex", justifyContent:"center", alignItems:"center", margin: "0.5rem", borderRadius: "0.5rem", fontSize: "large", fontWeight: "bold"}} >
          Offer Zone
        </div>
        <div style={{ backgroundColor:"white", display:"flex", justifyContent:"center", alignItems:"center"}}> 
         <img src="./discount.png" style={{width:"4rem", height:"4rem"}} alt="" />
        </div>    
      </div> 
      <div style={{ backgroundColor:"white", display:"flex", justifyContent:"center", margin: "0.5rem", borderRadius: "0.5rem", columnGap: "1rem"}}> 
        <OfferImageList />  
      </div> 
    </div>
  )
}

export default PageOfferZone ;
