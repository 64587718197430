import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { useTable } from 'react-table'
 
 function GroceriesTable(props) {
 
   const columns = React.useMemo(
     () => [
       {
         Header: 'Item',
         accessor: 'item', // accessor is the "key" in the data
       },
       {
         Header: 'Price',
         accessor: 'price',
       },
     ],
     []
   )

   const data = props.rows
 
   const {
     getTableBodyProps,
     headerGroups,
     rows,
     prepareRow,
   } = useTable({ columns, data })
 
   return (
    <TableContainer style={{ maxHeight: 320 }} component={Paper}>
        <Table size="medium" aria-label="a dense table">
       <TableHead >
         {headerGroups.map(headerGroup => (
           <TableRow {...headerGroup.getHeaderGroupProps()}>
             {headerGroup.headers.map(column => (
               <TableCell style={{ "fontWeight": "bold" }}{...column.getHeaderProps()} >
                 {column.render('Header')}
               </TableCell>
             ))}
           </TableRow>
         ))}
       </TableHead>
       <TableBody {...getTableBodyProps()}>
         { rows?.length ? rows.map(row => {
           prepareRow(row)
           return (
             <TableRow size="small" {...row.getRowProps()}>
               {row.cells.map(cell => {
                 return (
                   <TableCell {...cell.getCellProps()} >
                     {cell.render('Cell')}
                   </TableCell>
                 )
               })}
             </TableRow>
           )
         }) : 
            (
            <TableRow >
                  <TableCell  >
                  Coming soon...
                  </TableCell>
            </TableRow>
          )
        }
       </TableBody>
    </Table>
    </TableContainer>
   )
 }

 export default GroceriesTable
