import React, {useState, useEffect} from "react";
import './Cards.css'
export function PastCard({name2, time, delay, name, isDelay, background}){
  const [width, setWidth] = useState(window.innerWidth)
  const [backgroundPast, setBackgroundPast] = useState(background);
  const [colorCityName, setColorCityName] = useState("#585858");
  const [timeColor, setTimeColor] = useState("#cf455a");
  const [isDelayState, setIsDelayState] = useState(isDelay);
  window.addEventListener('resize', () => {
    setWidth(window.innerWidth)
  })
  useEffect(() => {
    if (isDelayState == true) {
      setTimeColor("#E03434");
    } else {
      setTimeColor("#eef1f6");
    }
  }, [isDelayState]);
    return(
        <ul className={'wait-ul'}>
        <li>
          <a className={"wait-li"} >
          <div style={{ background: backgroundPast }} className="name-bus2">
            <div className="name-bus-h2">
            <span style={{ color: colorCityName, paddingRight:'8px' }} className="name on-time2">
              {time.split(" ")[0]}
            </span>
            <span  style={{ color: colorCityName }} className="name on-time2">
              {time.split(" ")[1]}
            </span>
            </div>
          </div>
          <div className="name-bus">
          <div className="name-bus-h">
          <h4 style={{ color: colorCityName }} className="name">{name}</h4>
          </div>
          <div className="name-bus-h">
          <span style={{ color: timeColor }}  className="name on-time">
          {isDelay ? `${delay}` : "On Time"}
          </span>
         
          </div>
          </div>
          </a></li>
      </ul>
    )
}