import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Chip, Stack, ThemeProvider } from '@mui/material';
import { pushGA4CustomEvent } from '../Analytics';

export default function BusRouteFilterToggleButton(props) {
  const [filter, setFilter] = React.useState('All');
  const handleChange = (event, newFilter) => {
    setFilter(newFilter)
    props.handleBusRouteFilterChange(newFilter);
  };

  const selectFilter = (newFilter) => {
    setFilter(newFilter)
    props.handleBusRouteFilterChange(newFilter);
    let pageTitle = "filter route click"
    let pageUrl =  "busRoutefilter"
    let eventDetails =  `Route filter ${newFilter}` 
    pushGA4CustomEvent(pageTitle,pageUrl,eventDetails)
  };

  return (
    <ThemeProvider theme={props.theme}>
    {/* <ToggleButtonGroup
      value={filter}
      exclusive
      onChange={handleChange}
      size="small"
      color={"themePurple"}
    >
      {
        props.filters.map((filter, i) => {
          return (
            <ToggleButton value={filter} key={i} >
            <div className="busRouteFilterLabel">{filter}</div>
          </ToggleButton>
          )
        })
      }
    </ToggleButtonGroup> */}
    <div >
    {
        props.filters.map((filter, i) => {
          return (
              <div key={i} style={{ marginRight:'0.1rem', marginBottom:'0.2rem', display: 'inline-block' }}>
                <Chip variant="outlined" label={filter} onClick={() => selectFilter(filter)}  />
              </div>
          )
        })
      }
    </div>
    </ThemeProvider>
  );
}
