import React from "react"
import { useState } from "react"
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@mui/icons-material/Send';
import {postBusForumMessage} from '../../api/bus'
import { isLoggedIn } from "../../api/account";


export default function PostMessageBox({ busId, refreshMessages, setLoader }) {

   const user = isLoggedIn()
   const [message, setMessage] = useState([])

   const onUserChangedText = (event) => {
    setMessage(event.target.value);
  };

  const onClickSendIcon = async () => {
    setLoader(true)
    await postBusForumMessage(busId, message, user.name)
    setMessage([])
    setTimeout(refreshMessages, 2000);
  };

  return (
    <>
        <div style={{ height:"10vh"}}>
            <Grid className="forum_text_field">
                <Grid className="forum_text_field__area"  xs={12}>
                <TextField multiline rows={2} id="outlined-basic-email" label="Post a public message." 
                        value={message} onChange={onUserChangedText} fullWidth  />
                <Grid onClick={onClickSendIcon}>
                    <Fab color="primary" aria-label="add" style={{ margin: "0.5rem" }}><SendIcon  style={{width:"20px"}}/></Fab>
                </Grid>
                </Grid>       
            </Grid>   
        </div>   
    </>
   )
}
