
import React from 'react'

const Loading = ({}) => { 
  return (
    <div>
      <div  style={{
            position: 'absolute', 
            left: '50%', 
            top: '50%',
            transform: 'translate(-50%, -50%)'
        }} >
      <img style={{ borderRadius: '2rem' }} src="icon_animated_loading.gif" alt="logo" />
      </div>
    </div>
  )
}

export default Loading