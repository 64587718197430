import { useEffect, useState } from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import { getOrderVehicleAvailability, updateOrderVehicleAvailability } from '../../api/delivery';
import { Box, LinearProgress, TextField } from '@mui/material';

export default function DeliveryAdmin() {
  const [operations, setOperations] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let responseData = await getOrderVehicleAvailability();
      setOperations(responseData.operations);
      setLoading(false);
    }
    const updateOperations = async () => {
      setLoading(true);
      const responseData = await updateOrderVehicleAvailability(operations);
      if(responseData.success) {
        setLoading(false);
      }
      setUpdated(false)
    }
    if (!operations) fetchData();
    if (updated) updateOperations();
  },[operations, updated]) 

  const handleChange = (event) => {
    setOperations({
      ...operations,
      [event.target.name]: event.target.checked,
    });
   setUpdated(true)
  };
  const handlePausedTimeStringChange = (event) => {
    setOperations({
      ...operations,
      pausedTimeString: event.target.value,
    });
  }

  return (
    <div className='card'>
      { loading ? <Box sx={{ width: '100%' }}>
          <LinearProgress />
      </Box> : '' }
      { operations && <div className='card__body' style={{ padding: "0.5rem" }}>
        <FormControl component="fieldset" variant="standard">
        <FormLabel component="legend">Delivery Operations</FormLabel>
        <FormGroup>
            <div style={{marginTop:"1rem", marginBottom: "0.5rem"}}>
              <TextField
                id="pausedTimeString"
                label="Paused Time String"
                value={operations.pausedTimeString}
                onChange={handlePausedTimeStringChange}
              />
            </div>
            <FormControlLabel
              control={
                  <Switch checked={operations.paused} onChange={handleChange} name="paused" />
              }
              label="Paused"
            />
            <FormControlLabel
              control={
                  <Switch checked={operations.maintenanceMode} onChange={handleChange} name="maintenanceMode" />
              }
              label="Maintenance Mode"
            />
        </FormGroup>
        <FormHelperText>Be careful</FormHelperText>
        </FormControl>
     </div> }
    </div>
  );
}