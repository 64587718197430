import React, { useState, useEffect } from "react";
import { sendBusLiveGeo } from "../../api/bus";

export const useGeoLocation = (busId, gpsToggleChecked, invalidGpsAlertOpen) => {
  const [inv, setInv] = useState(false);
  const onSuccess = async (location) => {
    const liveGeoResponse = await sendBusLiveGeo(busId, location.coords.latitude, location.coords.longitude);
    if (!liveGeoResponse.isValidGps) {
      invalidGpsAlertOpen()
    }
  };

  const onError = (error) => {
    console.log(error)
  };

  useEffect(() => {
    if (!("geolocation" in navigator)) {
      onError({
        code: 400,
        message: "Geolocation not supported",
      });
    }
  }, []);
  useEffect(() => {
    if (!("geolocation" in navigator)) {
      onError({
        code: 400,
        message: "Geolocation not supported",
      });
      console.log("not available");
    }
    if (gpsToggleChecked === true) {
      setInv(true);

      console.log("geo location turned on");
      navigator.geolocation.getCurrentPosition(onSuccess, onError);
    } else if (gpsToggleChecked === false) {
      console.log("changed to false");
      setInv(false);
      console.log("geo location turned off");
    }
  }, [gpsToggleChecked]);

  useEffect(() => {
    let interval;
    navigator.geolocation.getCurrentPosition(
      position => {
        if (inv == true) {
          interval = setInterval(() => {
            navigator.geolocation.getCurrentPosition(onSuccess, onError);
            console.log("checked after 10 sec");
          }, 10000);
        } else {
          clearInterval(interval);
          setInv(false);
        }
        // this function is called only if the user grant permission so here you can handle the granted state
      },
      error => {
        return
        // this function is called if the user denied permission or some other errors occurs
      }
    );
    return () => clearInterval(interval);
  }, [inv]);
};
