import React from 'react'

const HomeLoginPage = ({changePage}) => {
  return (
   <>
       <div className="loginPageBody" style={{height:"100vh", margin:"auto",   overflow:"hidden",backgroundColor:"white", position:"sticky"}}>
          <div style={{display:"flex", position:"absolute", top:"30px",width:"80%", left:"20px", justifyContent:"space-between"}}>

            <div style={{display:"flex", alignContent:"center", alignItems:"center", columnGap:"5px"}}>
                    <div style={{height:"8px", width:"8px", backgroundColor:"#850085",  borderRadius:"100%",  }}></div>
                    <div style={{height:"6px", width:"6px", backgroundColor:"#e6e6e6",  borderRadius:"100%",  }}></div>
                    <div style={{height:"6px", width:"6px", backgroundColor:"#e6e6e6",  borderRadius:"100%",  }}></div>
            </div>
          </div>
            <div style={{height:"70vh", position:"",zIndex:"5", backgroundColor:"#b3b3b3",borderRadius:"100%/25px  25px 25px  25px ", borderTopLeftRadius:"50px", borderTopRightRadius:"50px"}} >
            <div style={{height:"70vh",display:"flex", justifyContent:"center", alignItems:"center", position:"",zIndex:"10", backgroundColor:"blue",borderRadius:"50%/25px  25px 25px  25px ", borderTopLeftRadius:"0px", borderTopRightRadius:"0px",backgroundImage: "linear-gradient( to bottom right,#b348b3, #700070 50%)"}}>
                <div style={{display:"flex", flexDirection:"column", rowGap:"200px", marginTop:"10vh"}}>

                <img src="./logo_purple_512.jpg" alt="" style={{ width: 50, height: 50 , marginLeft:"45px" ,borderRadius:50 , marginTop:"10vh"}}/>
                <div style={{color:"#f0f0f0",fontSize:"20px", fontWeight:"bolder"}}><img src="./logo_text_white_transparent.png" alt="" style={{ width: 130, height: 10 ,marginBottom:"10px"}}/></div>
                </div>
            
            </div>

            </div>
            <div style={{height:"22vh",  backgroundColor:"white", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <div onClick={()=>changePage(2)} style={{cursor:"pointer",height:"40px",fontSize:"15px", width:"90%", color:"white", backgroundColor:"purple",  textAlign:"center",verticalAlign:"middle",lineHeight:"30px", borderRadius:"50px", paddingTop:'5px'}}>{"Let's Go"}</div>
            </div>
            <div style={{height:"8vh",  backgroundColor:"white", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <div onClick={()=>changePage(2)} style={{cursor:"pointer",height:"40px",fontSize:"0.8rem", width:"90%", color:"grey", textAlign:"right",verticalAlign:"middle",lineHeight:"30px", borderRadius:"50px", paddingTop:'5px'}}>
                  <a href="https://merchant.razorpay.com/policy/KU2Gb0PLZvEerc" target="_blank" rel="noreferrer" style={{textDecoration: "none", color: "dimgrey" }}>{"Policies"}</a>
                  <a href="https://mattool.in?page=tc" target="_blank" rel="noreferrer" style={{textDecoration: "none", marginLeft:"1rem", color: "dimgrey" }} >{"Terms"}</a>
                </div>
            </div>
        </div>
    
        </>
  )
}

export default HomeLoginPage