import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    position: 'relative',
  },
  discount: {
    background: '#ff6f00',
    color: '#ffffff',
    padding: '8px 16px',
    borderRadius: '16px',
    position: 'absolute',
    top: '16px',
    left: '16px',
  },
  discountText: {
    fontWeight: 'bold',
  },
  content: {
    padding: '16px',
    background: 'linear-gradient(135deg, #fadaef, #ffffff)'
  },
  couponContent: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    alignItems: "center",
    margin: '0.5rem 0'
  },
  description: {
    paddingTop: '16px',
  }
});

export default function CouponCard({ applyCoupon }) {
  const classes = useStyles();
  const coupon = {
    code: "MYMATTOOL10",
    discountPercentage: 10,
    maxDiscount: 100,
  }

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={() => { applyCoupon(coupon) }}>
        <CardContent className={classes.content}>
          <div className={classes.couponContent}> 
          <Typography component="h3" style={{ fontWeight: 'bold' }}>
            MYMATTOOL10
          </Typography>
          <div style={{ color: 'purple', fontSize: '1rem', fontWeight: 'bold', display: 'flex', justifyContent: 'flex-end' }}>
            APPLY
          </div>
          </div>
          <Divider />
          <Typography variant="body2" component="p" className={classes.description}>
            Get flat 10% off for your order. Maximum discount of Rs. 100 per order
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
