import React,{useEffect, useState} from 'react';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { BOTTOM_NAVIGATION } from '../config/constant';
import BoatAzhikkal from '../components/boat/BoatAzhikkal';
import BoatThekkumbad from '../components/boat/BoatThekkumbad';

function PageBoat (props) {

  const [tab, setTab] = useState(1);

  const goBackHome = () => {
    props.updateBottomNavigationTab(BOTTOM_NAVIGATION.HOME)
  }

  // inline css
  return(
    <div  style={{height:"90vh", margin:"0.5rem",   overflow:"hidden",backgroundColor:"white", position:"relative"}}>
      <div style={{display:"flex", position:"absolute", top:"1rem",width:"90%", left:"20px", justifyContent:"space-between"}}>
        <div onClick={goBackHome} style={{cursor:"pointer"}}> <ArrowBackOutlinedIcon /> </div>
      </div>
      <div style={{height:"10vh", backgroundColor:"white", display:"flex", justifyContent:"center", margin: "0.5rem", borderRadius: "0.5rem", paddingTop:"2rem", columnGap: "1rem"}}> 
        <div style={{ backgroundColor:"white", display:"flex", justifyContent:"center", alignItems:"center", margin: "0.5rem", borderRadius: "0.5rem", fontSize: "large"}} >
          Boat Timings
        </div>
        <div style={{ backgroundColor:"white", display:"flex", justifyContent:"center", alignItems:"center"}}> 
         <img src="./ship.png" style={{width:"6rem", height:"6rem"}} alt="" />
        </div>    
      </div> 
      <div style={{ display:"flex",  margin:"10px auto" , justifyContent:"space-around", marginBottom: "1rem"}}>
        <div onClick={()=>setTab(1)} style={{fontSize:"15px", fontWeight:"bold", borderRadius:"20px", padding:"10px 15px", cursor:"pointer", backgroundColor:tab===1?"rgb(237 244 251)":"", 
                color:tab===1?"black":"grey", boxShadow:"0px 2px 2px gray"}}>Azhikkal Ferry Service</div>
        {/* <div onClick={()=>setTab(2)} style={{fontSize:"15px", fontWeight:"bold", borderRadius:"20px", padding:"10px 15px", cursor:"pointer", backgroundColor:tab===2?"rgb(237 244 251)":"",
                color:tab===2?"black":"grey", boxShadow:"0px 2px 2px gray"}}>Thekkumbad</div> */}
      </div>   
      <div style={{height:"50vh",flexDirection:"column", backgroundColor:"white", display:"flex"}}>
        {tab===1 &&  <BoatAzhikkal />}
        {/* {tab===2 &&  <BoatThekkumbad />} */}
      </div>
    </div>
  )
}

export default PageBoat ;
