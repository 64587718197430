import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { isLoggedIn, logout } from '../api/account';
import { navigateToLoginScreen } from '../pages/PageProfile'

export default function ButtonAppBar({setShowNavUser}) {
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const user = isLoggedIn()

  const logoutAction = () => {
   logout()
   window.open(window.location.origin, '_self')
  }

  const goHome = () => { window.open(window.location.origin, '_self') }
  const goToAddStore = () => { window.open(window.location.origin+'?page=addStore', '_self')}
  const goToAddSocialContent = () => { window.open(window.location.origin+'?page=addSocialContent', '_self')}
  const login = () => { navigateToLoginScreen() }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ bgcolor: "purple" }}>
        <Toolbar >
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
          <IconButton onClick={goHome} sx={{ p: 0 }}>
            <Avatar alt={'mattool.in'} src="/logo_purple_512.jpg" />
          </IconButton>
          <Typography variant="overline" component="div" sx={{ flexGrow: 1 }} align='center'>
             <img src="logo_text_white_transparent.png" alt="mattool"  style={{ maxWidth: "7rem" }} />
             <span style={{ "fontSize": "0.3rem" }}>  Beta</span>
          </Typography>
  
          { user ? 
          // <Button color="inherit" onClick={logoutAction} >Logout</Button>
            (<Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={user.name} src="/" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
            <MenuItem key='home' onClick={goHome}>
              <Typography textAlign="center">Home</Typography>
            </MenuItem>
            {/* <MenuItem key='goToAddStore' onClick={goToAddStore}>
              <Typography textAlign="center">Add your Business</Typography>
            </MenuItem> */}
            {/* <MenuItem key='goToAddSocialContent' onClick={goToAddSocialContent}>
              <Typography textAlign="center">Add Social Content</Typography>
            </MenuItem> */}
            <MenuItem key='logout' onClick={logoutAction}>
              <Typography textAlign="center">logout</Typography>
            </MenuItem>
            </Menu>
          </Box>)
            : <Button color="inherit" onClick={login} >Login</Button>
          }
        </Toolbar>
      </AppBar>
    </Box>
  );
}
