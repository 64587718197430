import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import OfferImageModal from './OfferImageModal';

export default function OfferImageList() {

    const [open, setOpen] = React.useState(false);
    const [offer, setOffer] = React.useState(null);

    const showOfferImageModal = (item) => {
        let pageTitle = "OfferOpened"
        let pageUrl = "OfferOpened"
        //pushGA4CustomEvent(pageTitle,pageUrl)

        setOffer(item)
        setOpen(true);
    }

    const closeOfferImageModal = () => {
        setOpen(false);
    }

  return (<>
    <ImageList cols={1}>
      {itemData.map((item) => (
        <ImageListItem key={item.img} onClick={() => showOfferImageModal(item)}>
          <img
            src={`${item.img}?w=512&fit=crop&auto=format`}
            srcSet={`${item.img}?w=512&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
            style={{ width: "325px", height: "100px", borderRadius: "0.5rem" }}
          />
          <ImageListItemBar
            title={item.title}
            subtitle={<span>by: {item.author}</span>}
            position="below"
          />
        </ImageListItem>
      ))}
    </ImageList>
    { offer && <OfferImageModal open={open} closeInfoModal={closeOfferImageModal} offer={offer}  /> } 
  </>
  );
}

const itemData = [
  {
    img: '/discount.png',
    title: 'Breakfast',
    message: 'I love breakfast.',
    author: '@bkristastucchio',
  },
  {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
    author: '@rollelflex_graphy726',
  },
  {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Camera',
    author: '@helloimnik',
  },
  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
    author: '@nolanissac',
  },
  {
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Hats',
    author: '@hjrc33',
  },
  {
    img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey',
    author: '@arwinneil',
  },
  {
    img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    title: 'Basketball',
    author: '@tjdragotta',
  },
  {
    img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    title: 'Fern',
    author: '@katie_wasserman',
  },
  {
    img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
    title: 'Mushrooms',
    author: '@silverdalex',
  },
  {
    img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
    title: 'Tomato basil',
    author: '@shelleypauls',
  },
  {
    img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
    title: 'Sea star',
    author: '@peterlaster',
  },
  {
    img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
    title: 'Bike',
    author: '@southside_customs',
  },
];
