import React from 'react';
 
function FloatingCoin() {
  return (
  <div className="coin">
    <div className="front jump">
      <div className="star"></div>
      <span className="currency">M</span>
      <div className="shapes">
        <div className="shape_l"></div>
        <div className="shape_r"></div>
        <span className="top">.</span>
        <span className="bottom">mattool.in</span>
      </div>
    </div>
    <div className="shadow"></div>
  </div>
  )
}

export default FloatingCoin
