import * as React from 'react';
import { useState,useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { isAndroid,isIOS} from 'react-device-detect';
import DetectPlatform from './DetectPlatform';

export default function ScrollDialog() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [device , setDevice] = React.useState('')
  useEffect(() => {
    if(isIOS){
      setDevice('How to install App for ios')
    }else if(isAndroid){
      setDevice('How to install App for android')
    }
  },[])
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Button onClick={handleClickOpen('paper')}>{device}</Button>
      {/* <Button onClick={handleClickOpen('body')}>scroll=body</Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <div style={{ display: "flex"}}>
        <DialogTitle id="scroll-dialog-title" style={{}}>How to install</DialogTitle>
        <Button onClick={handleClose} style={{position:"relative" , left:"3rem" ,fontSize:"10px",textTransform:"lowercase",}} id="clsBtn">close</Button>
        </div>
        <div style={{ width:"33.7vh"}}>
          <div
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          ><DetectPlatform/>
          </div>
        </div>
       
      </Dialog>
    </div>
  );
}
